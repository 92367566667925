import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import storage from 'utils/storage';

import { actions } from '../../store';

import Component, {
  IAuthPasswordRestoreSentProps,
  IAuthPasswordRestoreSentPropsExternal,
  IAuthPasswordRestoreSentActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  _: any,
  props: TODO_ANY
): IAuthPasswordRestoreSentProps & IAuthPasswordRestoreSentPropsExternal => ({
  email: storage.get('restore-password-email'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: TODO_ANY): IAuthPasswordRestoreSentActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
