import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IUserState } from '../../store';

import Component, {
  IUserPersonalDataChangeProps,
  IUserPersonalDataChangePropsExternal,
  IUserPersonalDataChangeActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IUserState },
  props: { history: TODO_ANY }
): IUserPersonalDataChangeProps & IUserPersonalDataChangePropsExternal => ({
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IUserPersonalDataChangeActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
