import { getTransformedApiDate } from '@rfb/common';
import {
  Button,
  ButtonReset,
  Checkbox,
  Condition,
  DatePickerRange,
  ITable,
  MessageList,
  Table as UIKitTable,
} from '@rfb/ui-kit';
import fp from 'lodash/fp';
import { IOperationDataState } from 'modules/operation-data/store';
import React, { useEffect } from 'react';
import styles from './assets/styles/index.module.css';

export interface IOperationDataTableProps {
  filter: IOperationDataState['filter'];
  filterTemp: IOperationDataState['filterTemp'];
  isFilteringByPeriod: boolean;
  headers: ITable['headers'];
  rows: ITable['rows'];
  page: number;
  pageCount: number;
  requestCount: number;
  apiErrorList: string[];
  isLoading: boolean;
}

export interface IOperationDataTableActions {
  actions: {
    set: Function;
    getData: Function;
    setFilter: Function;
    setFilterTemp: Function;
    applyTempFilter: Function;
    resetFilterByOmitList: Function;
    resetFilterDates: Function;
    refreshStatus: Function;
  };
}

export interface IOperationDataTablePropsExternal {
  dealerSystem: string;
}

function getWorkingDayAgo(today: Date): Date {
  let subtrahend;
  switch (today.getDay()) {
    case 1:
      subtrahend = 3;
      break;
    default:
      subtrahend = 1;
  }
  return new Date(Date.now() - 1000 * 3600 * 24 * subtrahend);
}

const OperationDataTable = (props: IOperationDataTableProps & IOperationDataTableActions) => {
  function toggleCheckPeriodMode() {
    props.actions.set({ isFilteringByPeriod: !props.isFilteringByPeriod });
  }

  const onDateInputChange = ([dateStart, dateEnd]: string[]) => {
    props.actions.setFilterTemp({ dateStart, dateEnd });
  };

  const onApplyTempFilterClick = () => {
    props.actions.applyTempFilter();
  };

  useEffect(() => {
    if ((props.isFilteringByPeriod && props.filter.dateStart) || !props.isFilteringByPeriod) {
      props.actions.getData({
        ...props.filter,
        operation_type_code: 'REQR',
      });
    }
  }, [props.filter]);

  useEffect(() => {
    props.actions.refreshStatus();
  }, []);

  function resetTempFilter() {
    const today = new Date(Date.now());
    onDateInputChange([
      getTransformedApiDate(getWorkingDayAgo(today).toISOString()),
      getTransformedApiDate(today.toISOString()),
    ]);
  }

  useEffect(() => {
    // TODO Проверить на лишний render (https://www.youtube.com/watch?v=bGzanfKVFeU)
    if (props.isFilteringByPeriod) {
      if (props.filterTemp.dateStart || props.filterTemp.dateEnd) {
        onDateInputChange([props.filterTemp.dateStart, props.filterTemp.dateEnd]);
      } else {
        resetTempFilter();
      }
      onApplyTempFilterClick();
    } else {
      props.actions.resetFilterDates();
    }
  }, [props.isFilteringByPeriod]);

  const onFilterClear = () => {
    const omitList: (keyof IOperationDataState['filter'])[] = [];
    props.actions.resetFilterByOmitList(omitList);
    props.actions.set({ isFilteringByPeriod: false });
    resetTempFilter();
  };

  const onSortingChange = (sorting: ITable['sorting']): void =>
    props.actions.setFilter({ sorting });

  return (
    <div className={styles.main}>
      <Condition value={!fp.isEmpty(props.apiErrorList)}>
        <MessageList type="error" messages={props.apiErrorList} />
      </Condition>
      <div>
        <div
          style={{
            marginTop: '40px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '30px',
          }}
        >
          <Checkbox isChecked={props.isFilteringByPeriod} onChange={toggleCheckPeriodMode} />
          <div style={{ marginLeft: '10px', marginRight: '30px', fontSize: '18px' }}>За период</div>
          {/* </div> */}
          <Condition value={props.isFilteringByPeriod}>
            <DatePickerRange
              wrapperClassName={styles.datePickerRange}
              start={props.filterTemp.dateStart}
              end={props.filterTemp.dateEnd}
              withShift
              onChange={(value: string[]) => {
                if (!!value[0] && !!value[1]) {
                  // К сожалению, пока костыль, поскольку по непонятным причинам onChange срабатывает, когда его не просят - просто при рендеринге
                  onDateInputChange(value);
                }
              }}
            />
            <Button
              wrapperClassName={styles.buttonShow}
              text="Показать"
              onClick={onApplyTempFilterClick}
            />
          </Condition>
        </div>
      </div>
      <div className={styles.blockD}>
        <ButtonReset
          wrapperClassName={styles.reset}
          title="СБРОСИТЬ ФИЛЬТРЫ"
          isActive={props.filter.isFilterActive}
          onClear={onFilterClear}
        />
      </div>

      <div className={styles.table}>
        <UIKitTable
          headers={props.headers}
          rows={props.rows}
          sorting={props.filter.sorting}
          page={props.page}
          pageCount={props.pageCount}
          isLoading={props.isLoading}
          onSortingChange={onSortingChange}
          onPageChange={(page: number) => props.actions.setFilter({ page })}
        />
      </div>
      <Condition value={!!props.requestCount}>
        <div style={{ marginTop: '40px', fontSize: '22px' }}>
          Для подписания перейдите на форму заявки, нажав на номер заявки.
        </div>
      </Condition>
    </div>
  );
};

export default OperationDataTable;
