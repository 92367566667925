// TODO Устранить дублирование с tranches
export enum ClientLineTypes {
  KL = 'KL',
  FL1 = 'FL1',
  FL2 = 'FL2',
  CC = 'CC',
  DC = 'DC',
}

export const clientLineTypeTranslations: { [key: string]: string } = {
  [ClientLineTypes.KL]: 'К/Л',
  [ClientLineTypes.FL1]: 'Ф/Л',
  [ClientLineTypes.FL2]: 'Ф/Л',
  [ClientLineTypes.CC]: 'Б/Н-К/К',
  [ClientLineTypes.DC]: 'Б/Н', // Пока не используется
};

export const clientLineTypeTranslationsFull: { [key: string]: string } = {
  [ClientLineTypes.KL]: 'Кредитная линия',
  [ClientLineTypes.FL1]: 'Факторинговая линия',
  [ClientLineTypes.FL2]: 'Факторинговая линия',
  [ClientLineTypes.CC]: 'Коммерческий кредит',
  [ClientLineTypes.DC]: 'Договор консигнации',
};

export const clientLineCommissionTypeTranslations: { [key: string]: string } = {
  '': 'Комиссия отсутствует',
  1: 'Комиссия за выдачу',
  2: 'Комиссия за поддержание лимита',
  3: 'Комиссия за отсрочку платежа',
};
