import React from 'react';

import { History } from '@rfb/ui-kit';

import AppTemplate from 'templates/AppTemplate';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmCode from 'modules/confirmation/components/ConfirmCode';
import confirmTypes from 'modules/confirmation/configs/confirmTypes';
import { actions, selectors } from 'modules/user/store';
import { DTOCodeWordChangeRequest } from 'dto/security';

const UserCodeWordChangeConfirmPage = (props: TODO_ANY) => {
  const codeWord = useSelector((state) => selectors.selectFormValueByField(state)('codeWord'));
  const dispatch = useDispatch();

  const renderLeftSidebar = (): JSX.Element => (
    <div className={styles.sidebarLeft}>
      <History type="back" to={routingConfig.profileCodeWordChange.path} />
    </div>
  );

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <ConfirmCode
        header="Изменить кодовое слово"
        changePersonalDataFlag={false}
        confirmTypeCode={confirmTypes.IBD_CONFIRM_CHANGE}
        onSuccess={() => {
          const data: DTOCodeWordChangeRequest = {
            key_word: codeWord,
          };
          dispatch(
            actions.changeCodeWord({
              data,
              history: props.history,
            })
          );
        }}
        onCancel={() => props.history?.push(routingConfig.profileCodeWordChange.path)}
      />
    </div>
  );

  return <AppTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default UserCodeWordChangeConfirmPage;
