import { connect } from 'react-redux';

import { IDTORFInfoAccountResponse } from '../../../../../../dto/rf-info';

import { name, selectors } from '../../../../store';

import Component, { IAccountsRestrictionsProps } from './component';

const mapStateToProps = (state: {
  [name]: IDTORFInfoAccountResponse;
}): IAccountsRestrictionsProps => ({
  value: selectors.selectCurrentAccountData(state).rko_debt,
  count: selectors.selectCurrentArrestsData(state).arrests.length,
  krtCount: selectors.selectCurrentArrestsData(state).krt_count,
  id: selectors.selectCurrentId(state),
});

export default connect(mapStateToProps)(Component);
