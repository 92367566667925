export const profileView_GET = {
  id: 288,
  email: 'olga.volkova@rosbank.ru',
  first_name: 'Ирина',
  middle_name: 'Валерьевна',
  last_name: 'Ребрина',
  phone_number: '79608798466',
  key_word: 'ЗАМЕСТИТЕЛЬ',
  created_at: '2022-03-15 14:23:58',
  updated_at: '2022-03-15 14:27:31',
  dealers: [
    {
      diasoft_id: '10000253051',
      authority_begin_date: '2021-05-31',
      authority_end_date: '2026-05-17',
      agreement_at: null,
      validated_at: null,
      name: null,
      inn: null,
      kpp: null,
    },
  ],
  block_reason: 0,
  active: true,
  validate_phone_date: '2022-03-15 14:23:58',
  password_unsafe_at: '2022-06-13 14:27:30',
  read_only: false,
};
