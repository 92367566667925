import React, { useState, useEffect } from 'react';
// @ts-ignore
import { IMaskInput } from 'react-imask';
import { MaskedRange } from 'imask';
import fp from 'lodash/fp';
import { getTransformedDateObject, getTransformedDate, getTransformedApiDate } from '@rfb/common';
import styles from './assets/styles/index.module.css';
var maskConfig = {
    d1: {
        mask: MaskedRange,
        from: 0,
        to: 3,
    },
    d2: {
        mask: MaskedRange,
        from: 0,
        to: 9,
    },
    m1: {
        mask: MaskedRange,
        from: 0,
        to: 1,
    },
    m2: {
        mask: MaskedRange,
        from: 0,
        to: 9,
    },
    Y: {
        mask: MaskedRange,
        from: 1900,
        to: 2099,
    },
};
var hasPlaceholder = function (date) { return fp.includes('_', date); };
var DateInput = function (props) {
    var _a = useState(props.value), date = _a[0], setDate = _a[1];
    useEffect(function () {
        setDate(props.value);
    }, [props.value]);
    var onChange = function (value) {
        setDate(value);
        if (hasPlaceholder(value)) {
            return props.onEmpty ? props.onEmpty(value) : undefined;
        }
        var _a = fp.pipe(fp.split('.'), fp.map(fp.toNumber))(value), day = _a[0], month = _a[1], year = _a[2];
        var date = new Date(year, month - 1, day);
        var formattedDate = fp.pipe(fp.toString, getTransformedApiDate)(date);
        props.onChange(formattedDate);
    };
    var value = hasPlaceholder(date)
        ? date
        : fp.pipe(getTransformedDateObject, fp.toString, getTransformedDate)(props.value);
    return (React.createElement("span", { className: styles.main },
        React.createElement(IMaskInput, { key: "dp-" + props.name + "-" + props.keyUnique, 
            //@ts-ignore
            className: styles.input, value: value, mask: Date, pattern: 'd1d2{.}`m1m2{.}`Y', blocks: maskConfig, lazy: false, onAccept: function (value) {
                if (value === props.value)
                    return;
                onChange(value);
            } })));
};
export default DateInput;
