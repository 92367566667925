import { reducer as accountsReducer, name as accountsName } from 'modules/accounts/store';
import { reducer as appReducer, name as appName } from 'modules/app/store';
import { reducer as authReducer, name as authName } from 'modules/auth/store';
import { reducer as clientLineReducer, name as clientLineName } from 'modules/client-line/store';
import { reducer as informationReducer, name as informationName } from 'modules/information/store';
import { reducer as trancheReducer, name as trancheName } from 'modules/tranche/store';
import {
  reducer as operationDataReducer,
  name as operationDataName,
} from 'modules/operation-data/store';
import { reducer as userReducer, name as userName } from 'modules/user/store';
import { reducer as confirmReducer, name as confirmName } from 'modules/confirmation/store';
import {
  reducer as financialBlockReducer,
  name as financialBlockName,
} from 'modules/financial-block/store';
import {
  name as notificationVisualizationName,
  reducer as notificationVisualizationReducer,
} from 'modules/notifications/store';

export default {
  [accountsName]: accountsReducer,
  [appName]: appReducer,
  [authName]: authReducer,
  [clientLineName]: clientLineReducer,
  [informationName]: informationReducer,
  [trancheName]: trancheReducer,
  [operationDataName]: operationDataReducer,
  [userName]: userReducer,
  [confirmName]: confirmReducer,
  [financialBlockName]: financialBlockReducer,
  [notificationVisualizationName]: notificationVisualizationReducer,
};
