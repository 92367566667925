import { connect } from 'react-redux';

import { getTransformedAmount } from '@rfb/common';

import { IAccountsState, name, selectors } from '../../../../store';

import Component, {
  IDetailedRestrictionHeader,
  IDetailedRestrictionHeaderPropsExternal,
} from './component';

const mapStateToProps = (
  state: { [name]: IAccountsState },
  props: IDetailedRestrictionHeaderPropsExternal
): IDetailedRestrictionHeader & IDetailedRestrictionHeaderPropsExternal => {
  const { krt_count, krt_qty } = selectors.selectCurrentArrestsData(state);
  return {
    count: krt_count,
    sum: getTransformedAmount(krt_qty),
    id: props.id,
    noArrests: !selectors.selectCurrentArrestsData(state).arrests.length,
  };
};

export default connect(mapStateToProps)(Component);
