import React, { useEffect } from 'react';
import { configureValidator, runRulesChain } from 'helpers/validation';
import { subjectRules, messageRules, phoneRules } from '../../configs/validation';
import { IDTOFeedbackCreateRequest } from 'dto/feedback';
import routingConfig from '../../../../configs/routing';
import { FeedbackForm } from './FeedBackForm/component';
import storage from '../../../../utils/storage';
import { dealerSystemsKey } from '../../../auth/store';
import { convertPathUsingParams, DealerSystems } from '@rfb/common';
import SystemChoice from '../common/SystemChoice';

export interface IInformationFeedbackProps {
  options: { value: string; label: string }[];
  subject: string;
  optionErrorList: string[];
  email: string;
  phone: string;
  phoneErrorList: string[];
  contractNumber: string;
  message: string;
  messageErrorList: string[];
  apiErrorList: string[];
  dealerName: string;
  isFeedbackSending: boolean;
}

export interface IInformationFeedbackPropsExternal {
  // TODO Use a history interface
  notificationDealerSystem?: DealerSystems;
  history: {
    push: (path: string) => void;
  };
}

export interface IInformationFeedbackActions {
  actions: {
    setForm: Function;
    setError: Function;
    sendFeedback: Function;
    resetForm: Function;
  };
}

const Feedback = (
  props: IInformationFeedbackProps & IInformationFeedbackPropsExternal & IInformationFeedbackActions
) => {
  useEffect(() => {
    return () => props.actions.resetForm();
  }, []);

  const validators = {
    subject: configureValidator({
      name: 'subject',
      rule: subjectRules,
      setError: props.actions.setError,
    }),
    message: configureValidator({
      name: 'message',
      rule: messageRules,
      setError: props.actions.setError,
    }),
    phone: configureValidator({
      name: 'phone',
      rule: phoneRules,
      setError: props.actions.setError,
    }),
  };

  const onSubjectChange = (subject: string): void => {
    validators.subject(subject).finally((): void => props.actions.setForm({ subject }));
  };
  const onPhoneChange = (phone: string): void => {
    validators.phone(phone).finally((): void => props.actions.setForm({ phone }));
  };
  const onContractNumberChange = (contractNumber: string): void => {
    props.actions.setForm({ contractNumber });
  };
  const onMessageChange = (message: string): void => {
    validators.message(message).finally((): void => props.actions.setForm({ message }));
  };
  const onRbFormSubmit = (): void => {
    onFormSubmit('rb');
  };
  const onRfFormSubmit = (): void => {
    onFormSubmit('rf');
  };
  const onFormSubmit = (system: string): void => {
    const data: IDTOFeedbackCreateRequest = {
      subject: props.subject,
      phone_number: props.phone,
      contract_number: props.contractNumber,
      message: props.message,
      dealer_name: props.dealerName,
      system,
    };
    const rules = [
      validators.subject(data.subject),
      validators.message(data.message),
      validators.phone(data.phone_number),
    ];
    runRulesChain(rules).then(() =>
      props.actions.sendFeedback({
        data,
        history: props.history,
        notificationDealerSystem: props.notificationDealerSystem,
      })
    );
  };

  const rbRender = (
    <FeedbackForm
      options={props.options}
      subject={props.subject}
      optionErrorList={props.optionErrorList}
      email={props.email}
      phone={props.phone}
      phoneErrorList={props.phoneErrorList}
      contractNumber={props.contractNumber}
      message={props.message}
      messageErrorList={props.messageErrorList}
      apiErrorList={props.apiErrorList}
      dealerName={props.dealerName}
      isFeedbackSending={props.isFeedbackSending}
      onSubjectChange={onSubjectChange}
      onPhoneChange={onPhoneChange}
      onContractNumberChange={onContractNumberChange}
      onMessageChange={onMessageChange}
      onFormSubmit={onRbFormSubmit}
    />
  );

  const rfRender = (
    <FeedbackForm
      options={props.options}
      subject={props.subject}
      optionErrorList={props.optionErrorList}
      email={props.email}
      phone={props.phone}
      phoneErrorList={props.phoneErrorList}
      contractNumber={props.contractNumber}
      message={props.message}
      messageErrorList={props.messageErrorList}
      apiErrorList={props.apiErrorList}
      dealerName={props.dealerName}
      isFeedbackSending={props.isFeedbackSending}
      onSubjectChange={onSubjectChange}
      onPhoneChange={onPhoneChange}
      onContractNumberChange={onContractNumberChange}
      onMessageChange={onMessageChange}
      onFormSubmit={onRfFormSubmit}
    />
  );

  const oneSystemPathForNotification: string = convertPathUsingParams(
    routingConfig.feedbackFromNotifications.path,
    {
      dealerSystem:
        props.notificationDealerSystem === DealerSystems.RUSFINANCE
          ? DealerSystems.RUSFINANCE
          : DealerSystems.ROSBANK_AVTO,
    }
  );

  return (
    <div>
      <SystemChoice
        dealerSystemConfig={storage.get(dealerSystemsKey).data}
        dealerSystemActive={props.notificationDealerSystem}
        twoSystemCaption="Обратная связь"
        oneSystemCaptionTemplate="Свяжитесь c"
        rbRenderContent={rbRender}
        rfRenderContent={rfRender}
        oneSystemPath={
          props.notificationDealerSystem
            ? oneSystemPathForNotification
            : routingConfig.feedback.path
        }
        twoSystemPath={routingConfig.twoSystemFeedback.path}
        rbButtonName={'Вопросы в РОСБАНК АВТО'}
        rfButtonName={'Вопросы в РУСФИНАНС'}
      />
    </div>
  );
};

export default Feedback;
