import React from 'react';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { TNotification } from '../../../../dto/notification';
import Clip from '../../components/Notifications/assets/images/clip';

export const getTransformedAttachments = (
  notUse: TODO_ANY,
  row: TNotification | undefined
): JSX.Element => {
  return row?.attachments?.length ? <Clip /> : <div />;
};
