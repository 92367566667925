import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { actions as appActions, selectors as appSelectors } from 'modules/app/store';

import Component, { IAppUserProps, IAppUserActions } from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (state: TODO_ANY): IAppUserProps => ({
  state,
  apiErrorCode: appSelectors.selectApiErrorCode(state),
  isLoading: appSelectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: any): IAppUserActions => ({
  appActions: bindActionCreators(appActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
