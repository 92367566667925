import fp from 'lodash/fp';
import validator from 'utils/validator';

export const requestCodeRules = validator.string().required('Выберите значение из списка');
export const requestModelRules = validator.string().required('Выберите значение из списка');

export const brandRules = validator.string().required('Выберите значение из списка');

export const requestVinRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test(
    'not-cyrillic',
    'Недопустимый формат данных',
    (value) => !/[IQOiqoа-яёА-ЯЁ]+/.test(value || '')
  )
  .test(
    'vin-warning',
    'Поле в режиме «VIN автомобиля», для ввода номера кузова выберите соответствующее значение поля – «Номер кузова»',
    (value) => !/^0{3,}/.test(value || '')
  )
  .length(17, 'Длина VIN номера должна составлять 17 символов');

export const carBodyNumberRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test(
    'first-zero',
    'Номер кузова вводится без нулей вначале',
    (value) => !/^0/.test(value || '')
  );

export const originCountryRules = validator.string();

export const priceWithVATRules = (currentLimitAmount: number) => {
  const isLimitExceeding = currentLimitAmount <= 0;
  if (currentLimitAmount === undefined) return validator.string(); // TODO Убрать костыль (игнорирование случаев undefined)
  return (
    validator
      .string()
      .required('Поле обязательно для заполнения')
      .test('money', 'Значение должно быть суммой в рублях', (value) =>
        /^[0-9]+(\.[0-9]{1,2})?$/.test(value || '')
      )
      // .test('money', `Превышена сумма доступного лимита`, (value) => currentLimitAmount - Number(value) >= 0);
      .test('money', `Превышена сумма доступного лимита`, (value) => currentLimitAmount > 0)
  );
};

export const vatAmountRules = validator.string();

export const accountNumberRules = validator.string().required('Поле обязательно для заполнения');

const isCorrectDateFormat = (value: string | null | undefined) =>
  value === '__.__.____' || /^\d\d\d\d-\d\d-\d\d$/.test(value || '');

const isDateNotGreaterThanToday = (value: string | null | undefined) => {
  if (value === null || value === undefined) {
    return false;
  }
  const inputDate = new Date(value);
  inputDate.setHours(0, 0, 0, 0); // Устанавливаем время в начало дня
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Устанавливаем время в начало дня для корректного сравнения
  if (isNaN(inputDate.getTime())) {
    return false;
  }

  return inputDate <= today;
};

export const accountNumberDateRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test('invalid-date-format-rule', 'Некорректная дата', isCorrectDateFormat)
  .test('invalid-date-rule', 'Дата счёта не должна быть больше текущей', isDateNotGreaterThanToday);

export const supplierDocDateRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test('invalid-date-format-rule', 'Некорректная дата', isCorrectDateFormat)
  .test('invalid-date-rule', 'Дата счёта не должна быть больше текущей', isDateNotGreaterThanToday);

export const deliveryLocationRules = validator
  .string()
  .required('Если адреса места доставки нет в списке, свяжитесь с ООО РУСФИНАНС');

export const recieverShortNameRules = validator.string().required('Выберите значение из списка');

export const paymentPurposeRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test('textSize', 'Количество символов в тексте не должно превышать 210', (value) =>
    /^.{0,210}$/.test(value || '')
  );

function getCarPassportAge(strDate: string) {
  const today = new Date();
  const vpDate = new Date(strDate);
  let age = today.getFullYear() - vpDate.getFullYear();
  const m = today.getMonth() - vpDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < vpDate.getDate())) {
    age--;
  }
  return age;
}

export const requestCarPassportDateRules = (maxAge: number) => {
  const isCorrectDate = (value: string | null | undefined) => getCarPassportAge(value!) < maxAge;
  return validator
    .string()
    .required('Поле обязательно для заполнения')
    .test('invalid-date-format-rule', 'Некорректная дата', isCorrectDateFormat)
    .test(
      'invalid-date-rule',
      `Возраст автомобиля должен быть не старше ${maxAge} лет`,
      isCorrectDate
    );
};

export const requestEngineDisplacementRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test('engine-power', 'Значение должно быть целым числом числом', (value) =>
    /^[0-9]+?$/.test(value || '')
  )
  .test(
    'engine-displacement-is-number',
    'Введено неверное количество символов. Пример для заполнения: 800',
    fp.gte(fp.__, 100)
  );

export const requestEnginePowerRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test('engine-power', 'Значение должно быть целым числом числом', (value) =>
    /^[0-9]+?$/.test(value || '')
  )
  .test(
    'engine-power',
    'Значение должно быть числом не меньше 50 и не больше 999. Пример для заполнения: 150',
    fp.gte(fp.__, 50)
  )
  .test(
    'engine-power',
    'Значение должно быть числом не меньше 50 и не больше 999. Пример для заполнения: 150',
    fp.lte(fp.__, 999)
  );

export const requestElectricalEnginePowerRules = validator
  .string()
  .required('Поле обязательно для заполнения')
  .test('electrical-engine-power', 'Значение должно быть целым числом числом', (value) =>
    /^[0-9]+?$/.test(value || '')
  );

export const requestMileageRules = (maxRun: number) =>
  validator
    .string()
    .required('Поле обязательно для заполнения')
    .test('engine-power', 'Значение должно быть целым числом числом', (value) =>
      /^[0-9]+?$/.test(value || '')
    )
    .test(
      'engine-power',
      `Значение должно быть числом, не превышающим ${maxRun}`,
      (value) => Number(value) <= maxRun
    );

export const requestAmountRules = (maxAmount: number) => {
  const result = validator
    .string()
    .required('Поле обязательно для заполнения')
    .test('money', 'Значение должно быть суммой в рублях', (value) =>
      /^[0-9]+(\.[0-9]{1,2})?$/.test(value || '')
    )
    .test('money', `Сумма не должна превышать ${maxAmount}`, (value) => Number(value) <= maxAmount);
  return result;
};

export const requestAttachmentPdfRules = validator
  .mixed()
  .required('Вложение документа обязательно')
  .test('fileType', 'Прилагаемый файл должен иметь формат PDF', (value) =>
    'application/pdf'.includes(value?.type)
  );

export const requestPdfRules = validator
  .mixed()
  .required('Вложите счёт на оплату за автомобиль')
  .test('fileType', 'Прилагаемый файл должен иметь формат PDF', (value) =>
    'application/pdf'.includes(value?.type)
  );

export const repoAttachmentsRules = validator
  .mixed()
  .required('???')
  .test('fileType', 'Прилагаемый файл должен иметь формат PDF', (value) =>
    'application/pdf'.includes(value?.type)
  );
