import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { name, actions, selectors, IInformationState } from '../../store';

import Component, {
  IInformationReminderProps,
  IInformationReminderPropsExternal,
  IInformationReminderActions,
} from './component';

const mapStateToProps = (
  state: { [name]: IInformationState },
  props: { history: TODO_ANY }
): IInformationReminderProps & IInformationReminderPropsExternal => ({
  rb_text: selectors.selectRbReminderText(state),
  rf_text: selectors.selectRfReminderText(state),
  history: props.history,
});


const mapDispatchToProps = (dispatch: any): IInformationReminderActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
