import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import AddIcon from '../../icons/Add';
import BlockIcon from '../../icons/Block';
import PrintIcon from '../../icons/Print';
import RemoveIcon from '../../icons/Remove';
import SaveIcon from '../../icons/Save';
import UnblockIcon from '../../icons/Unblock';
import styles from './assets/styles/index.module.css';
import Logout from '../../icons/Logout';
import DocFile from '../../icons/DocFile';
var icons = {
    add: AddIcon,
    block: BlockIcon,
    print: PrintIcon,
    remove: RemoveIcon,
    save: SaveIcon,
    unblock: UnblockIcon,
    exit: Logout,
    doc: DocFile,
};
export var ButtonWithIcon = function (props) {
    var _a, _b;
    var iconClassNames = cn((_a = {},
        _a[styles.icon] = true,
        _a[styles.iconActive] = props.isActive,
        _a));
    var titleClassNames = cn((_b = {},
        _b[styles.title] = true,
        _b[styles.titleDisabled] = !props.isActive,
        _b));
    var onClick = function () {
        if (props.isActive)
            props.onClick();
    };
    var Icon = fp.get(props.type, icons);
    return (React.createElement("div", { className: cn(props.wrapperClassName), role: "button" },
        React.createElement("div", { className: styles.main, onClick: onClick },
            React.createElement("span", { className: iconClassNames },
                React.createElement(Icon, { className: props.iconClassName })),
            React.createElement("span", { className: titleClassNames }, props.text))));
};
export default ButtonWithIcon;
