import { bindActionCreators } from '@reduxjs/toolkit';
import { ITable } from '@rfb/ui-kit';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import { store } from 'root-store';
import { IOperationDataState, actions, name, selectors } from '../../../store';
import Component, { IOperationDataTableActions, IOperationDataTableProps } from './component';

import styles from './assets/styles/index.module.css';

import { getTransformedDataAndTime } from '@rfb/common/lib/utils/format/date/dateAndTime';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { IDTOOperationDataResponse } from 'dto/operation-data';
import { getTransformedAmount } from '../../../../../helpers/transform/amount';
import { operationDataStatusValue } from '../../../configs/status';
import { operationDataTypeValue } from '../../../configs/type';
import {
  getStatusId,
  getStatusValue,
  getTransformedAttachments,
  getTransformedErrors,
  getTransformedStatusToCellComponent,
  getTypeCode,
  getTypeValue,
} from '../../../helpers/transform';

type TOperationDataTableHeader = ITable['headers'][number] & {
  value: keyof IDTOOperationDataResponse['records'][number] | 'load_operation_error';
  isExtra?: true;
};

type TOperationDataTableColumn = {
  header: TOperationDataTableHeader;
  transform?: (value: any, row?: IOperationDataState['data'][number]) => any;
};

const mapStateToProps = (state: { [name]: IOperationDataState }): IOperationDataTableProps => {
  const dataFilter: IOperationDataState['dataFilter'] = selectors.selectDataFilter(state);
  const filter: IOperationDataState['filter'] = selectors.selectFilter(state);
  const data: IOperationDataState['data'] = selectors.selectOperationData(state);
  const columns: TOperationDataTableColumn[] = [
    {
      header: {
        value: 'attachments',
        title: '',
        type: 'attachment',
        isFixed: true,
        columnClassName: styles.attachmentsColumnData,
      },
      transform: getTransformedAttachments,
    },
    {
      header: {
        value: 'operation_create_date',
        title: 'ДАТА И ВРЕМЯ ОПЕРАЦИИ',
        type: 'sorting',
        sortingType: 'number',
        isFixed: true,
        columnClassName: styles.columnDateAndTime,
      },
      transform: (date: string | null) => (date ? getTransformedDataAndTime(date) : ' '),
    },
    {
      header: {
        value: 'operation_type_code',
        title: 'ВИД ОПЕРАЦИИ',
        type: 'select',
        filterList: fp.map((typeCode) => getTypeValue(typeCode), dataFilter?.operation_type_code),
        filterValue: operationDataTypeValue[filter.operation_type_code],
        isFixed: true,
        columnClassName: styles.columnData,
        onFilterChange: (operationTypeValue: TODO_ANY) =>
          store.dispatch(
            actions.setFilter({ operation_type_code: getTypeCode(operationTypeValue) })
          ),
      },
      transform: getTypeValue,
    },
    {
      header: {
        value: 'request_number',
        title: 'НОМЕР ОПЕРАЦИИ',
        isFixed: true,
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.request_number,
        columnClassName: styles.requestNumberColumnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (request_number) =>
          fp.some(Boolean, [fp.gte(fp.size(request_number), 3), fp.isEmpty(request_number)])
            ? store.dispatch(actions.setFilter({ request_number }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'operation_status_id',
        title: 'СТАТУС',
        type: 'select',
        filterList: fp.map((statusId) => getStatusValue(statusId), dataFilter.operation_status_id),
        filterValue: operationDataStatusValue[filter.operation_status_id],
        isFixed: true,
        columnClassName: styles.columnData,
        onFilterChange: (statusValue: TODO_ANY) =>
          store.dispatch(actions.setFilter({ operation_status_id: getStatusId(statusValue) })),
      },
      transform: getTransformedStatusToCellComponent,
    },
    {
      header: {
        value: 'operation_amount',
        title: 'ОБЩАЯ СУММА РУБ.',
        isFixed: true,
        columnClassName: styles.columnData,
      },
      transform: (operaion_amount: number | null) =>
        operaion_amount ? getTransformedAmount(operaion_amount) : ' ',
    },
    {
      header: {
        value: 'vin',
        title: 'VIN',
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.vin,
        isFixed: true,
        columnClassName: styles.columnVin,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (vin: string | string[]) =>
          fp.some(Boolean, [fp.gte(fp.size(vin), 3), fp.isEmpty(vin)])
            ? store.dispatch(actions.setFilter({ vin }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'brand',
        title: 'МАРКА',
        type: 'select',
        filterList: dataFilter.brand.map(function (element) {
          return element.brand_name;
        }),
        filterValue: dataFilter.brand.find((brand) => brand.brand_id.toString() == filter.brand_id)
          ?.brand_name,
        isFixed: false,
        columnClassName: styles.columnData,
        onFilterChange: (brandValue) =>
          store.dispatch(
            actions.setFilter({
              brand_id: dataFilter.brand.find((brand) => brand.brand_name === brandValue)?.brand_id,
            })
          ),
      },
    },
    {
      header: {
        value: 'model',
        title: 'МОДЕЛЬ',
        type: 'select',
        filterList: dataFilter.model.map(function (element) {
          return element.model_name;
        }),
        filterValue: dataFilter.model.find((model) => model.model_id.toString() == filter.model_id)
          ?.model_name,
        isFixed: false,
        columnClassName: styles.columnData,
        onFilterChange: (modelValue) =>
          store.dispatch(
            actions.setFilter({
              model_id: dataFilter.model.find((model) => model.model_name === modelValue)?.model_id,
            })
          ),
      },
    },
    {
      header: {
        value: 'load_operation_error',
        title: 'КОММЕНТАРИЙ',
        isFixed: false,
        columnClassName: styles.columnData,
      },
      transform: getTransformedErrors,
    },
    {
      header: {
        value: 'user_name',
        title: 'ПОЛЬЗОВАТЕЛЬ',
        type: 'input',
        filterValue: filter.user_name,
        isFixed: false,
        columnClassName: styles.columnData,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (user_name: string | string[]) =>
          fp.some(Boolean, [fp.gte(fp.size(user_name), 3), fp.isEmpty(user_name)])
            ? store.dispatch(actions.setFilter({ user_name }))
            : fp.noop,
      },
    },
  ];

  const rowDataList = fp.pipe(
    fp.map((row: IOperationDataState['data'][number]) => {
      return fp.map((column: TOperationDataTableColumn) => {
        return fp.isFunction(column.transform)
          ? column.transform(fp.path(column.header.value, row), row)
          : fp.path(column.header.value, row);
      }, columns);
    }),
    fp.map((data) => ({ data }))
  )(data);

  const rowConfigList = fp.pipe(fp.map((config) => ({ config })))(data);

  return {
    filter,
    headers: fp.map(fp.path('header'), columns),
    rows: fp.pipe(fp.zip(rowConfigList), fp.map(fp.mergeAll))(rowDataList),
    page: filter.page,
    pageCount: selectors.selectPageCount(state),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    isLoading: selectors.selectIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch: any): IOperationDataTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
