import React from 'react';

import { History } from '@rfb/ui-kit';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

import UploadFormRepo from 'modules/financial-block/components/UploadFormRepo';
import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';

const UploadRepo = (props: TODO_ANY) => {
  const backUrl = `/financing-tranches/list/rf/${props.match.params.type}`;

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <h1 style={{ fontSize: '30px', fontWeight: '500', marginBottom: '30px', marginLeft: '30px' }}>
        Добавить вложение/документ
      </h1>
      <History wrapperClassName={styles.wrapperHistory} type="back" to={backUrl} title={'Назад'} />
      <UploadFormRepo history={props.history} backUrl={backUrl} />
    </div>
  );

  const renderLeftSidebar = (): JSX.Element => (
    <div>
      <History type="back" to={backUrl} />
    </div>
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default UploadRepo;
