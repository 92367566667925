import React from 'react';

import { toRuDate } from '@rfb/common/utils/format/date';

import { pluralize } from '@rfb/common/lib/utils/format/plural';
import styles from './assets/styles/index.module.css';

export interface IDetailedRestrictionHeader {
  count: number;
  sum: string;
  noArrests: boolean;
}

export interface IDetailedRestrictionHeaderPropsExternal {
  id: string;
}
const Header = (props: IDetailedRestrictionHeader & IDetailedRestrictionHeaderPropsExternal) => {
const year = new Date().getFullYear();

  return (
    <div className={styles.header}>
      <h1 className={styles.title}>Ограничения по расчетному счёту {props.id}</h1>
      {props.noArrests && (
        <div className={styles.description}>
          На{' '}
          <span className={styles.date}>
            {toRuDate()} {year} года
          </span>{' '}
          блокировки по расчетному счету отсутствуют.
          <br />
        </div>
      )}
      {!props.count ? (
        <div className={styles.description}>
          На{' '}
          <span className={styles.date}>
            {toRuDate()} {year} года
          </span>{' '}
          года картотека расчетных документов по расчетному счету отсутствует.
        </div>
      ) : (
        <div className={styles.description}>
          На{' '}
          <span className={styles.date}>
            {toRuDate()} {year} года
          </span>{' '}
          картотека расчётных документов по расчетному счёту{' '}
          <span className={styles.account}>{props.id}</span>
          <br />
          составляет {props.count} {pluralize(props.count, ['документ', 'документа', 'документов'])}{' '}
          на общую сумму {props.sum}
        </div>
      )}
    </div>
  );
};

export default Header;
