import React from 'react';
import cn from 'classnames';
import CloseIcon from '../../icons/Close';
import styles from './assets/styles/index.module.css';
export var ButtonReset = function (props) {
    var _a, _b;
    var iconClassNames = cn((_a = {},
        _a[styles.icon] = true,
        _a[styles.iconActive] = props.isActive,
        _a));
    var titleClassNames = cn((_b = {},
        _b[styles.title] = true,
        _b[styles.titleActive] = props.isActive,
        _b));
    var onClear = function () {
        if (props.isActive)
            props.onClear();
    };
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: styles.main, onClick: onClear },
            React.createElement(CloseIcon, { className: iconClassNames }),
            React.createElement("span", { className: titleClassNames }, props.title))));
};
ButtonReset.defaultProps = {
    title: 'Reset',
};
export default ButtonReset;
