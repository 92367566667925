import { put, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';

import { convertApiErrorCodesToMessages, convertPathUsingParams } from '@rfb/common';
import apiEndpoints from 'configs/api/endpoints';

import { Directions } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import notificationTranslationConfig from 'configs/notification/translations';
import {
  IDTOOperationDataGetResponseHeaders,
  OperationDataTypeCode,
  RepoOperationDataViewDTO,
  TAttachment,
  TRepoOperationDataViewResponse,
} from 'dto/operation-data';
import fp from 'lodash/fp';
import history from 'utils/history';
import notification from 'utils/notification';
import routingConfig from '../../../configs/routing';
import new_api from '../../../utils/new-api';
import { IOperationDataState, actions } from './slice';

function* getData(action: TODO_ANY) {
  const filter: IOperationDataState['filter'] = action.payload;
  const isRepo = filter.operation_type_code === OperationDataTypeCode.REQR;
  try {
    yield put(actions.set({ data: [], pageCount: 0, isLoading: true }));
    yield put(actions.setError({ api: [] }));

    if (!isRepo) {
      // Временный технологический вызов старого api
      let url: string = apiEndpoints.operationData.oldGet + `?page=${filter.page}`;
      url += filter.sorting?.value
        ? `&sort=${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
            filter.sorting?.value
          }`
        : '';
      url += filter.dateStart ? `&start_date=${filter.dateStart}` : '';
      url += filter.dateEnd ? `&end_date=${filter.dateEnd}` : '';
      url += filter.request_number ? `&request_number=${filter.request_number}` : '';
      url += filter.operation_type_code ? `&operation_type_code=${filter.operation_type_code}` : '';
      url += filter.operation_status_id ? `&operation_status_id=${filter.operation_status_id}` : '';
      url += filter.user_name ? `&user_name=${filter.user_name}` : '';
      url += filter.vin ? `&vin=${filter.vin}` : '';
      url += filter.brand_id ? `&brand_id=${filter.brand_id}` : '';
      url += filter.model_id ? `&model_id=${filter.model_id}` : '';
      url += `&per-page=${filter.perPage}`;
      yield api.get(url);
    }

    let url: string = apiEndpoints.operationData.get + `?page=${filter.page}`;
    url += filter.sorting?.value
      ? `&sort=${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
          filter.sorting?.value
        }`
      : '';
    url += filter.dateStart ? `&start_date=${filter.dateStart}` : '';
    url += filter.dateEnd ? `&end_date=${filter.dateEnd}` : '';
    url += filter.request_number ? `&request_number=${filter.request_number}` : '';
    url += filter.operation_type_code ? `&operation_type_code=${filter.operation_type_code}` : '';
    url += filter.operation_status_name
      ? `&operation_status_name=${filter.operation_status_name}`
      : '';
    url += filter.user_name ? `&user_name=${filter.user_name}` : '';
    url += filter.vin ? `&vin=${filter.vin}` : '';
    url += filter.brand_id ? `&brand_id=${filter.brand_id}` : '';
    url += filter.model_id ? `&model_id=${filter.model_id}` : '';
    url += `&per-page=${filter.perPage}`;

    const result = yield new_api.get(url);
    yield put(actions.getDataSuccessful({ data: result.data.data, headers: result.headers }));
  } catch (error) {
    console.error(`//sagas/operation-data/getData ERROR: error = ${JSON.stringify(error)}`);
    yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getDataSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      data: action.payload.data.records,
      dataFilter: action.payload.data.filters,
      pageCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
    })
  );
}

function* getOperationDataById(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const url: string = convertPathUsingParams(apiEndpoints.operationData.getById, {
      id: action.payload.operationDataId,
    });

    const response = yield new_api.get(url);
    const result = response.data;
    if (result.status === 'OK') {
      const currentRepoOperationData = result.data;
      yield put(actions.set({ currentRepoOperationData }));
      if (action.payload.onSuccess) {
        action.payload.onSuccess();
      }
    } else {
      const message = `Ошибка получения записи Журнала операций: ${JSON.stringify(result.status)}`;
      onError(message);
    }
  } catch (error) {
    onError(`//sagas/getOperationDataById ERROR: ${JSON.stringify(error)}`);
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* requestRevoke(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    const url: string = convertPathUsingParams(apiEndpoints.operationData.revokeRequest, {
      id: action.payload.id,
    });
    yield api.post(url, {});
    yield put(actions.requestRevokeSuccessful({}));
  } catch (error) {
    yield put(actions.requestRevokeFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* requestRevokeSuccessful() {
  notification.info(notificationTranslationConfig.requestRevokeSuccessful);
  // TODO Заменить времянку (имитация refresh)
  history.push(routingConfig.help.path);
  history.push(routingConfig.operationData.path);
}

function* requestRevokeFailure(error: TODO_ANY) {
  notification.info(error.payload.message);
}

function* uploadProtocolDownload(action: TODO_ANY) {
  try {
    const endpoint = apiEndpoints.operationData.errorDownload;
    const url: string = convertPathUsingParams(endpoint, { id: action.payload.id });

    const config = {
      headers: { Accept: 'text/plain' },
      responseType: 'arraybuffer',
    };

    const response = yield api.get(url, config);
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'uploadProtocol_' + action.payload.id + '.txt');
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(actions.setError({ api: [error.message] }));
  }
}

// TODO Устранить дублирование с notification
const extractFilenameFromContentDisposition = (disposition: string) => {
  let filename = '';
  if (disposition && disposition.startsWith('attachment')) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

// TODO Устранить дублирование с notification
function* documentumFileDownload(action: TODO_ANY) {
  yield put(actions.setError({ api: [] }));
  const endpoint = apiEndpoints.operationData.documentumFileDownload;
  const url: string = convertPathUsingParams(endpoint, { id: action.payload.id });
  const config = {
    responseType: 'blob',
  };
  try {
    const response = yield new_api.get(url, config);
    const contentDisposition = response.headers['content-disposition'];
    const filename = extractFilenameFromContentDisposition(contentDisposition);
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = action.payload.filename ?? filename;
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    yield put(
      actions.setError({ api: ['Документ недоступен. Попробуйте повторить попытку позднее'] })
    );
  }
}

function* refreshStatus(action: TODO_ANY) {
  try {
    // yield put(actions.set({ isLoading: true }));
    yield new_api.post(apiEndpoints.operationData.refreshStatus, {});
  } catch (error) {
    yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  } finally {
    // yield put(actions.set({ isLoading: false }));
  }
}

// РЕПО
function* getRepoRequestDataWithOperationDataId(action: TODO_ANY) {
  const filter: IOperationDataState['filter'] = action.payload;
  try {
    yield put(actions.set({ data: [], pageCount: 0, isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const { operationDataId } = action.payload;
    let url: string = apiEndpoints.financialBlock.getRepoRequestData + '?';
    url += `operation_data_id=${operationDataId}`;
    url += filter.vin ? `&vin=${filter.vin}` : '';
    // url += filter.brand_id ? `&brand_id=${filter.brand_id}` : '';
    // url += filter.model_id ? `&model_id=${filter.model_id}` : '';
    url += filter.operation_status_id ? `&operation_status_id=${filter.operation_status_id}` : '';
    // url += filter.refuse_reason_code ? `&refuse_reason_code=${filter.refuse_reason_code}` : '';
    url += filter.page ? `&page=${filter.page}` : '';
    url += filter.sorting?.value
      ? `&sort=${fp.isEqual(Directions.DESC, filter.sorting?.direction) ? '-' : ''}${
          filter.sorting?.value
        }`
      : '';
    url += filter.perPage ? `&per-page=${filter.perPage}` : '';
    const response: {
      data: TRepoOperationDataViewResponse;
      headers: IDTOOperationDataGetResponseHeaders;
    } = yield new_api.get(url);
    yield put(
      actions.getRepoRequestDataWithOperationDataIdSuccessful({
        result: response.data,
        headers: response.headers,
      })
    );
  } catch (error) {
    console.error(`//sagas/getRepoRequestDataWithOperationDataId ERROR: ${JSON.stringify(error)}`);
    yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* getRepoRequestDataWithOperationDataIdSuccessful(action: TODO_ANY) {
  const operationDataView: RepoOperationDataViewDTO = action.payload.result.data;
  yield put(
    actions.set({
      operationDataView,
      pageCount: fp.toNumber(action.payload.headers['x-pagination-page-count']),
    })
  );
}

// TODO Устранить дублирование с financial-block
function* generateFileForDownload(action: TODO_ANY) {
  try {
    const url = `${convertPathUsingParams(apiEndpoints.financialBlock.postGenerateFileForDownload, {
      operationDataId: action.payload.operationDataId,
    })}?file_type_code=${action.payload.fileTypeCode}`;
    const response: { data: any } = yield new_api.post(url, { responseType: 'blob' });
    if (response.data.status === 'OK') {
      yield put(
        actions.generateFileForDownloadSuccessful({
          data: response.data.data,
          operationDataId: action.payload.operationDataId,
          fileTypeCode: action.payload.fileTypeCode,
          onSuccess: action.payload.onSuccess,
        })
      );
    } else {
      yield put(actions.generateFileForDownloadFailure({}));
    }
  } catch (error) {
    console.error(
      `//sagas/operation-data/generateFileForDownload ERROR: error = ${JSON.stringify(error)}`
    );
    yield put(actions.generateFileForDownloadFailure({}));
  }
}

function* generateFileForDownloadSuccessful(action: TODO_ANY) {
  const onSuccess = action.payload.onSuccess;
  const downloadUrl = `${convertPathUsingParams(
    apiEndpoints.financialBlock.getGeneratedFileDownload,
    {
      operationDataId: action.payload.operationDataId,
      operationAttachmentId: action.payload.data.operation_attachment_id,
    }
  )}`;
  if (onSuccess) yield onSuccess(downloadUrl, action.payload.data.attachment_file_name);
}

function* generateFileForDownloadFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
}

function* signAndSave(action: TODO_ANY) {
  try {
    yield put(actions.set({ isLoading: true }));
    yield put(actions.setError({ api: [] }));
    const url: string = `${convertPathUsingParams(apiEndpoints.operationData.postSignAndSave, {
      id: action.payload.operationDataId,
    })}`;
    notification.info('Подписание начато');
    const response = yield new_api.post(url, {});
    if (response.status === 200) {
      notification.info('Подписание завершено');
      action.payload.onSuccess && action.payload.onSuccess();
    } else {
      const message = 'При подписании произошла ошибка';
      notification.err(`${message}. Обращайтесь в поддержку`);
      onError(`${message}. Статус: ${response.status}`);
    }
  } catch (error) {
    console.error(`//sagas/signAndSave ERROR = ${JSON.stringify(error)}`);
    yield put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

// TODO Устранить дублирование с financial-block
function* getRepoAttachments(action: TODO_ANY) {
  try {
    const operationDataId = action.payload.operationDataId;
    const url: string = convertPathUsingParams(apiEndpoints.financialBlock.getRepoAttachments, {
      operationDataId,
    });
    const response = yield new_api.get(url);
    const result = response.data;
    if (result.status === 'OK') {
      yield put(actions.getRepoAttachmentsSuccessful({ result: result.data, operationDataId }));
    } else {
      yield put(
        actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] })
      );
    }
  } catch (error) {
    console.error(
      `//sagas/operation-data/getRepoAttachments ERROR: error = ${JSON.stringify(error)}`
    );
    yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
  }
}

function* getRepoAttachmentsSuccessful(action: TODO_ANY) {
  const reqRepoAttachments: TAttachment[] = action.payload.result.filter(
    (item: TAttachment) => item.attachment_type_code === 'REQ'
  );
  if (reqRepoAttachments.length === 1) {
    yield put(actions.set({ requestAttachment: reqRepoAttachments[0] }));
    // } else {
    //   notification.info(
    //     `Некорректное число файлов заявок (код 'REQ'): ${JSON.stringify(reqRepoAttachments.length)}`
    //   );
  }
}

export default function* () {
  yield takeEvery(actions.getData, getData);
  yield takeEvery(actions.getDataSuccessful, getDataSuccessful);

  yield takeEvery(actions.requestRevoke, requestRevoke);
  yield takeEvery(actions.requestRevokeSuccessful, requestRevokeSuccessful);
  yield takeEvery(actions.requestRevokeFailure, requestRevokeFailure);

  yield takeEvery(actions.uploadProtocolDownload, uploadProtocolDownload);
  yield takeEvery(actions.documentumFileDownload, documentumFileDownload);

  yield takeEvery(actions.refreshStatus, refreshStatus);

  yield takeEvery(
    actions.getRepoRequestDataWithOperationDataId,
    getRepoRequestDataWithOperationDataId
  );
  yield takeEvery(
    actions.getRepoRequestDataWithOperationDataIdSuccessful,
    getRepoRequestDataWithOperationDataIdSuccessful
  );

  yield takeEvery(actions.generateFileForDownload, generateFileForDownload);
  yield takeEvery(actions.generateFileForDownloadSuccessful, generateFileForDownloadSuccessful);
  yield takeEvery(actions.generateFileForDownloadFailure, generateFileForDownloadFailure);

  yield takeEvery(actions.signAndSave, signAndSave);

  yield takeEvery(actions.getRepoAttachments, getRepoAttachments);
  yield takeEvery(actions.getRepoAttachmentsSuccessful, getRepoAttachmentsSuccessful);

  yield takeEvery(actions.getOperationDataById, getOperationDataById);
}

function log(message: string) {
  console.log(message);
}
function onError(message: string) {
  console.log(message);
  //notification.err(message);
  put(actions.setError({ api: ['Сервер временно недоступен. Повторите попытку позднее'] }));
  //  notification.info(message);
}
