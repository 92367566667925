import fp from 'lodash/fp';

import { IAlertConfig } from '@rfb/common';

import storage from 'utils/storage';

import { selectors as appSelectors } from 'modules/app/store';
import { selectors as userSelectors } from 'modules/user/store';

import ReqAwaitingSigning, { awaitingSigningStorageKey } from 'alerts/app-awaiting-signing';
import AuthorityExpireAlert, { authorityExpireStorageKey } from 'alerts/authority-expire';
import { store } from 'root-store';

const authorityExpireDays: number = 10;

const config: IAlertConfig = {
  authorityExpire: {
    component: AuthorityExpireAlert,
    check: fp.pipe(
      fp.over([appSelectors.selectIsAppInit, userSelectors.selectAuthorityExpiredRemainDays]),
      ([isAppInit, days]: [boolean, number]): boolean[] => [
        isAppInit,
        fp.lt(days, authorityExpireDays - 1),
        !storage.get(authorityExpireStorageKey),
      ],
      fp.every(fp.isEqual(true))
    ),
  },
  reqAwaitingSigning: {
    component: ReqAwaitingSigning,
    check: () => !storage.get(awaitingSigningStorageKey) && fp.gt(appSelectors.selectRequestCount(store.getState()), 0) 
  },
};

export default config;
