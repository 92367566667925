var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Remove = function (props) { return (React.createElement("svg", __assign({}, props, { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M4.75 6.25L23.25 6.25", stroke: "currentColor", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M7 8.5V23.25H21V8.5", stroke: "currentColor", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M11.5 10.5H12V19H11.5V10.5Z", fill: "currentColor", stroke: "currentColor" }),
    React.createElement("rect", { x: "15.875", y: "10.375", width: "0.75", height: "8.75", fill: "currentColor", stroke: "currentColor", strokeWidth: "0.75" }),
    React.createElement("path", { d: "M12 5.75V4.75H16V5.75", stroke: "currentColor", strokeWidth: "1.5" }))); };
export default Remove;
