import { ButtonReset, Condition, ITable, MessageList, Table as UIKitTable } from '@rfb/ui-kit';
import fp from 'lodash/fp';
import React, { useEffect } from 'react';
import { IOperationDataState } from '../../../store';
import styles from './assets/styles/index.module.css';

export interface IOperationDataTableProps {
  filter: IOperationDataState['filter'];
  headers: ITable['headers'];
  rows: ITable['rows'];
  page: number;
  pageCount: number;
  apiErrorList: string[];
  isLoading: boolean;
}

export interface IOperationDataTableActions {
  actions: {
    getData: Function;
    resetData: Function;
    setFilter: Function;
    resetFilterByOmitList: Function;
    refreshStatus: Function;
  };
}

const OperationDataTableRb = (props: IOperationDataTableProps & IOperationDataTableActions) => {
  useEffect(() => {
    props.actions.getData({
      ...props.filter,
    });
    return () => {
      props.actions.resetData();
  };
  }, [props.filter]);

  useEffect(() => {
    props.actions.refreshStatus();
  }, []);

  const onFilterClear = () => {
    const omitList: (keyof IOperationDataState['filter'])[] = [];
    props.actions.resetFilterByOmitList(omitList);
  };

  const onSortingChange = (sorting: ITable['sorting']): void =>
    props.actions.setFilter({ sorting });

  return (
    <div className={styles.main}>
      <Condition value={!fp.isEmpty(props.apiErrorList)}>
        <MessageList type="error" messages={props.apiErrorList} />
      </Condition>

      <div className={styles.blockD}>
        <ButtonReset
          wrapperClassName={styles.reset}
          title="СБРОСИТЬ ФИЛЬТРЫ"
          isActive={props.filter.isFilterActive}
          onClear={onFilterClear}
        />
      </div>

      <div className={styles.table}>
        <UIKitTable
          headers={props.headers}
          rows={props.rows}
          sorting={props.filter.sorting}
          page={props.page}
          pageCount={props.pageCount}
          isLoading={props.isLoading}
          onSortingChange={onSortingChange}
          onPageChange={(page: number) => props.actions.setFilter({ page })}
        />
      </div>
    </div>
  );
};

export default OperationDataTableRb;
