import React from 'react';
export var Logo = function () { return (React.createElement("svg", { width: "246", height: "46", viewBox: "0 0 246 46", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M160.833 3H161.975V43H160.833V3Z", fill: "#00234D" }),
    React.createElement("path", { d: "M84.0348 3.03995L65.3445 5.48252V11.024L84.0348 9.35754V3.03995Z", fill: "#E40038" }),
    React.createElement("path", { d: "M26.9311 23.2197C26.9311 26.5811 27.9298 27.7511 31.1428 27.7511C34.3558 27.7511 35.3431 26.6097 35.3431 23.2197C35.3431 19.8298 34.373 18.6884 31.1428 18.6884C27.9127 18.6884 26.9311 19.8412 26.9311 23.2197ZM21.2869 23.1684C21.2869 17.0049 24.7111 14.1913 31.1428 14.1913C37.5746 14.1913 41.0216 17.0049 41.0216 23.1684C41.0216 29.3319 37.5974 32.2481 31.1428 32.2481C24.6883 32.2481 21.2869 29.3319 21.2869 23.1684V23.1684Z", fill: "#00234D" }),
    React.createElement("path", { d: "M80.4965 15.7379L65.3445 5.46539V11.0011L70.0699 14.197L71.0001 14.7677C69.8301 14.7108 68.6702 15.0092 67.673 15.6238C65.3388 17.0334 64.129 19.5102 64.129 23.1741C64.129 29.3376 67.5531 32.2538 73.9906 32.2538C80.428 32.2538 83.8921 29.3319 83.8921 23.1683C83.8921 19.6015 82.7108 17.1646 80.4965 15.7379ZM73.9906 27.7225C70.7776 27.7225 69.7731 26.5811 69.7731 23.1912C69.7731 19.8012 70.7776 18.6599 73.9906 18.6599C77.2036 18.6599 78.1852 19.8012 78.1852 23.1912C78.1852 26.5811 77.2207 27.751 73.9906 27.751V27.7225Z", fill: "#00234D" }),
    React.createElement("path", { d: "M11.5623 14.1571C9.24237 14.201 7.03354 15.1588 5.41589 16.8222V14.5395H0V37.7211H5.38165V29.486C7.00487 31.1494 9.22122 32.1016 11.5452 32.134C16.6415 32.134 19.4379 29.3661 19.4379 23.1398C19.4379 16.9136 16.6415 14.1571 11.5623 14.1571ZM9.63333 27.6426C6.42032 27.6426 5.41589 26.5012 5.41589 23.1113C5.41589 19.7214 6.42032 18.58 9.63333 18.58C12.8463 18.58 13.8279 19.7214 13.8279 23.1113C13.8279 26.5012 12.8635 27.6426 9.63333 27.6426Z", fill: "#00234D" }),
    React.createElement("path", { d: "M120.89 14.5794V20.8628H113.751V14.5794H108.335V31.5861H113.751V25.2172H120.89V31.5861H126.306V14.5794H120.89Z", fill: "#00234D" }),
    React.createElement("path", { d: "M85.7297 23.1398C85.7297 29.3661 88.5318 32.134 93.6224 32.134C95.9467 32.1028 98.1635 31.1504 99.786 29.486V31.5918H105.168V14.5566H99.7517V16.8394C98.1352 15.1653 95.9207 14.2007 93.5939 14.1571C88.5318 14.1571 85.7297 16.925 85.7297 23.1398ZM91.3397 23.1398C91.3397 19.7613 92.3041 18.6085 95.5343 18.6085C98.7644 18.6085 99.7517 19.7499 99.7517 23.1398C99.7517 26.5298 98.7473 27.6712 95.5343 27.6712C92.3213 27.6712 91.3397 26.4727 91.3397 23.1113V23.1398Z", fill: "#00234D" }),
    React.createElement("path", { d: "M56.7328 25.3028C56.3162 27.0776 55.1691 27.7511 52.7379 27.7511C49.5249 27.7511 48.5262 26.6097 48.5262 23.2197C48.5262 19.8298 49.5249 18.6884 52.7379 18.6884C55.1862 18.6884 56.3333 19.3504 56.7328 21.1367L62.3827 20.7087C61.538 16.2573 58.2394 14.1913 52.7265 14.1913C46.2834 14.1913 42.8706 17.0049 42.8706 23.1684C42.8706 29.3319 46.2662 32.2481 52.7265 32.2481C58.228 32.2481 61.4981 30.1366 62.3655 25.7251L56.7328 25.3028Z", fill: "#00234D" }),
    React.createElement("path", { d: "M149.225 31.5861L140.653 22.6547L148.769 14.5794H142.069L134.798 21.9642V14.5794H129.479V31.5861H134.798V25.6395V24.1671V23.7562L142.263 31.5861H149.225Z", fill: "#00234D" }),
    React.createElement("path", { d: "M195.058 14.5965V31.6317H204.155C208.007 31.6317 210.398 29.7256 210.398 26.6496C210.417 25.8042 210.17 24.9742 209.692 24.2768C209.214 23.5795 208.528 23.05 207.733 22.7631C208.366 22.4062 208.89 21.8833 209.248 21.2508C209.606 20.6183 209.785 19.9 209.765 19.1735C209.765 16.3485 207.573 14.6079 204.058 14.6079L195.058 14.5965ZM204.018 21.6332H197.854V17.1532H204.086C205.952 17.1532 207.025 17.9408 207.025 19.3047C207.025 21.228 205.387 21.6332 204.018 21.6332ZM204.189 29.0522H197.854V24.1899H204.223C206.358 24.1899 207.533 25.0402 207.533 26.5811C207.533 28.2361 206.409 29.075 204.189 29.075V29.0522Z", fill: "#00234D" }),
    React.createElement("path", { d: "M210.957 17.1875H218.097V31.6317H220.893V17.1875H228.027V14.5965H210.957V17.1875Z", fill: "#00234D" }),
    React.createElement("path", { d: "M236.918 14.237C231.286 14.237 228.307 17.2902 228.307 23.0713C228.307 28.8525 231.286 31.9913 236.918 31.9913C242.551 31.9913 245.524 28.9095 245.524 23.0713C245.524 17.2331 242.545 14.237 236.918 14.237ZM242.625 23.1056C242.625 27.7339 241.136 29.3832 236.918 29.3832C232.701 29.3832 231.251 27.7453 231.251 23.1056C231.251 18.4658 232.786 16.8279 236.918 16.8279C241.05 16.8279 242.602 18.5343 242.602 23.1056H242.625Z", fill: "#00234D" }),
    React.createElement("path", { d: "M187.051 16.6966C185.487 15.1659 183.395 14.2954 181.207 14.2655C175.94 14.2655 173.155 17.3358 173.155 23.1512C173.155 28.9666 175.94 32.0198 181.207 32.0198C182.296 32.0131 183.374 31.7908 184.377 31.3657C185.38 30.9406 186.289 30.3211 187.051 29.543V31.7059H189.83V14.6022H187.051V16.6966ZM186.982 23.6762C186.982 24.8747 186.982 26.0161 186.982 26.758C186.979 27.0101 186.914 27.2576 186.793 27.4785C186.671 27.6995 186.497 27.8872 186.286 28.0249C185.627 28.4508 184.913 28.7848 184.163 29.0179C183.326 29.2681 182.457 29.3969 181.584 29.4003C179.438 29.4003 178.114 28.9437 177.286 27.9165C176.459 26.8892 176.099 25.3655 176.099 23.1512C176.099 20.7828 176.499 19.2305 177.361 18.266C178.222 17.3016 179.541 16.885 181.584 16.885C182.462 16.8868 183.335 17.0175 184.175 17.273C184.884 17.5218 185.568 17.837 186.218 18.2147C186.453 18.3485 186.648 18.543 186.783 18.7779C186.917 19.0128 186.986 19.2794 186.982 19.5501C186.977 20.5545 186.982 22.1468 186.982 23.6477V23.6762Z", fill: "#00234D" }))); };
export default Logo;
