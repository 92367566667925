import { createSlice } from '@reduxjs/toolkit';
import { Directions } from '@rfb/common';
import {
  IDTOOperationDataResponse,
  OperationDataTypeCode,
  OperationStatusId,
  RepoOperationDataViewDTO,
  TRepoAttachment,
  TRepoOperationData,
} from 'dto/operation-data';
import { getStateFromParams } from 'helpers/store';
import fp from 'lodash/fp';

export interface IOperationDataState {
  data: IDTOOperationDataResponse['records']; // TODO переименовать в repoRecords, как в клиенте
  operationDataView: RepoOperationDataViewDTO | null;
  dataFilter: IDTOOperationDataResponse['filters'];
  selectedList: number[];
  filter: {
    dateStart: string;
    dateEnd: string;
    request_number: string;
    operation_type_code: OperationDataTypeCode | '';
    operation_status_id: OperationStatusId | '';
    operation_status_name: string;
    user_name: string;
    vin: string;
    brand_id: string;
    model_id: string;
    sorting?: { value: string; direction: Directions };
    pageCount: number;
    page: number;
    perPage: number;
    isFilterActive: boolean;
    lastResetTimestamp: number;
  };
  filterTemp: {
    dateStart: string;
    dateEnd: string;
  };
  isFilteringByPeriod: boolean;
  pageCount: number;
  errors: { [key: string]: string[] };
  isLoading: boolean;
  isSuccessSaved: boolean;
  requestAttachment: TRepoAttachment | null;
  currentOperationDataRecord: IDTOOperationDataResponse['records'][number] | null; // TODO теперь убрать?
  currentRepoOperationData: TRepoOperationData | null;
}

const initialState: IOperationDataState = {
  data: [],
  operationDataView: null,
  dataFilter: {
    operation_type_code: [],
    operation_status_id: [],
    operation_status_name: [],
    brand: [],
    model: [],
  },
  selectedList: [],
  filter: {
    dateStart: '',
    dateEnd: '',
    request_number: '',
    operation_type_code: '',
    operation_status_id: '',
    operation_status_name: '',
    user_name: '',
    vin: '',
    brand_id: '',
    model_id: '',
    sorting: { value: '', direction: Directions.ASC },
    pageCount: 0,
    page: 1,
    perPage: 20,
    isFilterActive: false,
    lastResetTimestamp: 0,
  },
  pageCount: 0,
  errors: {},
  isLoading: false,
  isSuccessSaved: false,
  requestAttachment: null,
  currentOperationDataRecord: null,
  currentRepoOperationData: null,
  filterTemp: {
    dateStart: '',
    dateEnd: '',
  },
  isFilteringByPeriod: false,
};

const operationDataSlice = createSlice({
  name: 'operation-data',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setFilter: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action.payload, isFilterActive: true },
    }),

    setError: (state, action) => ({
      ...state,
      errors: { ...state.errors, ...action.payload },
    }),
    reset: () => ({ ...initialState }),
    resetData: (state) => ({ ...state, data: initialState.data }),
    //resetFilter: (state) => ({ ...state, ...initialState.filter }),
    resetFilterByOmitList: (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        ...fp.omit<IOperationDataState['filter']>(action.payload, initialState.filter),
        lastResetTimestamp: Date.now(),
      },
    }),

    resetFilter: (state) => ({
      ...state,
      filter: { ...initialState.filter, lastResetTimestamp: Date.now() },
    }),
    setFilterTemp: (state, action) => ({
      ...state,
      filterTemp: { ...state.filterTemp, ...action.payload },
    }),
    applyTempFilter: (state) => ({
      ...state,
      filter: { ...state.filter, ...state.filterTemp, isFilterActive: true },
    }),

    resetFilterDates: (state) => ({
      ...state,
      filter: {
        ...state.filter,
        dateStart: initialState.filter.dateStart,
        dateEnd: initialState.filter.dateEnd,
      },
    }),

    resetErrors: (state) => ({ ...state, errors: { ...initialState.errors } }),

    getData: getStateFromParams,
    getDataSuccessful: getStateFromParams,
    getDataFailure: getStateFromParams,

    requestRevoke: getStateFromParams,
    requestRevokeSuccessful: getStateFromParams,
    requestRevokeFailure: getStateFromParams,

    uploadProtocolDownload: getStateFromParams,
    documentumFileDownload: getStateFromParams,

    getRepoRequestDataWithOperationDataId: getStateFromParams,
    getRepoRequestDataWithOperationDataIdSuccessful: getStateFromParams,

    refreshStatus: getStateFromParams,

    generateFileForDownload: getStateFromParams,
    generateFileForDownloadSuccessful: getStateFromParams,
    generateFileForDownloadFailure: getStateFromParams,

    signAndSave: getStateFromParams,

    getRepoAttachments: getStateFromParams,
    getRepoAttachmentsSuccessful: getStateFromParams,

    getOperationDataById: getStateFromParams,
  },
});

export const { name, actions, reducer } = operationDataSlice;
