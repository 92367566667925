import React, { useEffect } from 'react';

import { Routing } from '@rfb/ui-kit';
import { Roles, getAvailableRouteList, getAlert } from '@rfb/common';

import history from 'utils/history';

import alertConfig from 'configs/alert';
import routingConfig from 'configs/routing';

import AppLoader from '../AppLoader';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

export interface IAppUserProps {
  state: TODO_ANY;
  apiErrorCode: number;
  isLoading: boolean;
}

export interface IAppUserActions {
  appActions: {
    init: Function,
  };
}

const AppUser = (props: IAppUserProps & IAppUserActions) => {
  const withoutApiError: boolean = !props.apiErrorCode;

  useEffect(() => {
    if (withoutApiError) {
      props.appActions.init();
    }
  }, [props.apiErrorCode, props.appActions, withoutApiError]);

  if (props.isLoading) {
    return <AppLoader />;
  }

  const currentRoutingList = getAvailableRouteList(routingConfig, props.state, Roles.USER);
  const Alert = getAlert(alertConfig, props.state);

  return (
    <>
      <Routing history={history} config={currentRoutingList} />
      <Alert />
    </>
  );
};

export default AppUser;
