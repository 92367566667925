import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import Condition from '../../services/Condition';
import CheckboxCheckedIcon from '../../icons/CheckboxChecked';
import LockUncheckedIcon from '../../icons/LockUnchecked';
import LockCheckedIcon from '../../icons/LockChecked';
import styles from './assets/styles/index.module.css';
export var Checkbox = function (props) {
    var _a;
    var mainClassNames = cn((_a = {},
        _a[styles.main] = true,
        _a[styles.disabled] = props.isDisabled,
        _a));
    var checkboxClassNames = cn([styles.checkbox]);
    var lockClassNames = cn([styles.checkbox, styles.lock]);
    var onClick = function (event) {
        event.stopPropagation();
        props.onChange(!props.isChecked);
    };
    var renderLock = function () { return (React.createElement("div", { className: lockClassNames },
        React.createElement(Condition, { value: Boolean(props.isChecked) },
            React.createElement(LockCheckedIcon, { className: styles.icon })),
        React.createElement(Condition, { value: !Boolean(props.isChecked) },
            React.createElement(LockUncheckedIcon, { className: styles.icon })))); };
    var renderDefault = function () { return (React.createElement("div", { className: checkboxClassNames },
        React.createElement(Condition, { value: props.isChecked },
            React.createElement(CheckboxCheckedIcon, { className: styles.icon })))); };
    var renderByType = function () {
        if (fp.isEqual(props.type, 'lock'))
            return renderLock();
        return renderDefault();
    };
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: mainClassNames, onClick: onClick }, renderByType())));
};
Checkbox.defaultProps = {
    type: 'default',
};
export default Checkbox;
