import React, { useState } from 'react';
import cn from 'classnames';
import Calendar from '../Calendar';
import Condition from '../../services/Condition';
import CalendarIcon from '../../icons/Calendar';
import styles from './assets/styles/index.module.css';
export var CalendarPopup = function (props) {
    var _a = useState(false), isOpened = _a[0], setIsOpened = _a[1];
    var onButtonClick = function () {
        if (props.isDisabled)
            return;
        setIsOpened(!isOpened);
    };
    var onCalendarChange = function (value) {
        props.onCalendarChange(value);
        setIsOpened(false);
    };
    var calendarClassNames = cn([props.calendarClassName, styles.calendar]);
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: styles.main },
            React.createElement("button", { type: "button", className: styles.button, onClick: onButtonClick }, props.displayedButton ? (props.displayedButton) : (React.createElement(CalendarIcon, null)))),
        React.createElement(Condition, { value: isOpened },
            React.createElement(Calendar, { wrapperClassName: calendarClassNames, value: props.value, type: props.type, onChange: onCalendarChange }))));
};
export default CalendarPopup;
