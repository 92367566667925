import { TModel } from 'dto/operation-data';

export type DealerBrandCode = {
  brand: string;
  code: string;
  product_code: string;
};

export type TDealerBrand = {
  brand_id: string;
  flag_used: number;
  brand_name: string;
};

export type TBrandModel = {
  model_id: string;
  flag_used: number;
  model_name: string;
};

export type RequestDataEditForm = {
  brand?: string;
  code?: string;
  vin?: string;
  amount?: string;
  pdfFile?: any;
};

export type RequestDataUsedEditForm = {
  request_data_id: number | undefined; // undefined - для создания
  brand_id?: number;
  brand?: string;
  model_id?: number;
  model?: string;
  engine_volume?: string;
  engine_power?: string;
  electrical_engine_power?: string;
  car_run?: string;
  car_passport_date?: string;
  code?: string;
  vin?: string;
  not_vin?: number;
  amount?: string;
  contractPdf?: File;
  contractPdfId?: string;
  actOfAcceptancePdf?: File;
  actOfAcceptancePdfId?: string;
  proofOfPaymentPdf?: File;
  proofOfPaymentPdfId?: string;
};

export type RequestDataRfEditForm = {
  request_data_id: number | undefined; // undefined - для создания
  brand_id?: number;
  brand?: string;
  model_id?: string;
  model?: string;
  engine_volume?: string;
  engine_power?: string;
  electrical_engine_power?: string;
  car_run?: string;
  code?: string;
  vin?: string;
  amount?: string;
  contractPdf?: File;
  contractPdfId?: string;
  actOfAcceptancePdf?: File;
  actOfAcceptancePdfId?: string;
  proofOfPaymentPdf?: File;
  proofOfPaymentPdfId?: string;
};

// РЕПО

export type RepoRequestDataRecordDto = {
  account_date: Date;
  account_number: string;
  advance_percent: number;
  brand_code: string;
  brand_name: string;
  date_ins: Date;
  delivery_location: string;
  model_name: string;
  model_id: string;
  multi_brand: boolean;
  operation_data_id: number;
  origin_country: string;
  payment_purpose: string;
  price_with_vat: number;
  reciever: string;
  repo_request_data_id: number;
  repo_request_type_code: string;
  request_vin: string;
  supplier_doc_date: Date;
  vat_amount: number;
};

export type RepoRequestDataEditForm = {
  account_number?: string;
  account_date?: string;
  brand_name?: string;
  brand_id?: number;
  delivery_location?: string;
  model_id?: string;
  model_name?: string;
  origin_country?: string;
  payment_purpose?: string;
  price_with_vat?: string;
  // reciever_short_name?: string;
  request_data_id: number | undefined; // undefined - для создания
  request_vin?: string;
  supplier_doc_date?: string;
  vat_amount?: string;
  is_check: number;
};

export type TSendAdditional = {
  advance_percent: number;
  brand_code?: string;
  multi_brand?: boolean;
  reciever: string;
  repo_request_type_code: string;
};

export type TSentData = RepoRequestDataEditForm & PaymentDataListItemDto & TSendAdditional;

export type RepoRequestDataDTO = {
  records: RepoRequestDataRecordDto[];
  summary: {
    request_sum: number;
    advance_sum: number;
  };
};

export type TRepoRequestDataResponse = {
  status: string;
  headers: string[];
  data: RepoRequestDataDTO;
};

export type TRepoRequestDataBrandsResponse = {
  status: string;
  data: BrandListItemDto[];
};

export type TRepoModel = {
  model_id: string;
  model_name: string;
};

export type PaymentDataListItemDto = {
  payer_account_number?: string;
  reciever_inn?: string;
  reciever_account_number?: string;
  reciever_bic_bank?: string;
  reciever_loro_account?: string;
  reciever_bank_name?: string;
  reciever_short_name: string;
};

export type DeliveryLocationDto = {
  address: string;
};

export type TFinancingRepoRequestDataFinancingInfoDto = {
  brand_code: string;
  brand_name: string;
  multi_brand: boolean;
  models: TModel[];
  limit_finish_date: string;
  limit_amount: number;
  limit_rest: number;
  advance_percent: number;
  advance_percent_demo: number;
  payment_data: PaymentDataListItemDto[];
  delivery_locations: DeliveryLocationDto[];
};

export type TFinancingRepoRequestDataFinancingInfoResponse = {
  status: string;
  data: TFinancingRepoRequestDataFinancingInfoDto;
};

export type BrandListItemDto = {
  brand_code: string;
  brand_name: string;
  multi_brand: boolean;
};

export type FinancingRepoRequestFinancingInfoDTO = {
  // TODO Нужна новая реалиация
  request_data_id: number;
};

export type TTypeItem = {
  repo_request_type_code: string;
  repo_request_type_name: string;
  car_max_age: number;
  car_advanced_age: number;
  add_percent: number;
};

export type TRepoRequestTypesResponse = {
  status: string;
  data: TTypeItem[];
};

export enum LimitStatus {
  OK = 'OK',
  ID_NOT_FOUND = 'ID_NOT_FOUND',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
}

export enum RepoRequestApplyStatus {
  OK = 'OK',
  ID_NOT_FOUND = 'ID_NOT_FOUND',
  LIMIT_EXCEEDED = 'LIMIT_EXCEEDED',
}
