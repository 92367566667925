import React from 'react';

import { ButtonPrint, History } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import AppTemplate from 'templates/AppTemplate';

import InformationTariffs from 'modules/information/components/Tariffs';

const Tariffs = () => {
  const renderContent = () => <InformationTariffs />;
  const renderSidebarLeft = () => <History type="back" to={routingConfig.information.path} />;
  const renderSidebarRight = () => <ButtonPrint />;

  return (
    <AppTemplate
      content={renderContent()}
      sidebarLeft={renderSidebarLeft()}
      sidebarRight={renderSidebarRight()}
    />
  );
};

export default Tariffs;
