import { createSlice } from '@reduxjs/toolkit';

import { getStateFromParams } from 'helpers/store';
import { DealerSystems } from '@rfb/common';

export interface IInformationState {
  rbContactsText: string;
  rfContactsText: string;
  rbReminderText: string;
  rfReminderText: string;
  tariffsText: string;
  requisitesText: string;
  form: IInformationForm;
  errorList: { [key: string]: string[] };
  isFeedbackSending: boolean;
  isFeedbackSent: boolean;
  selectedSystem: DealerSystems | '';
  isLoading: boolean;
}

interface IInformationForm {
  subject: string;
  phone: string;
  contractNumber: string;
  message: string;
}

const initialForm: IInformationForm = {
  subject: '',
  phone: '',
  contractNumber: '',
  message: '',
};

const initialState: IInformationState = {
  rbContactsText: '',
  rfContactsText: '',
  rbReminderText: '',
  rfReminderText: '',
  tariffsText: '',
  requisitesText: '',
  form: initialForm,
  errorList: {},
  isFeedbackSending: false,
  isFeedbackSent: false,
  isLoading: false,
  selectedSystem: '',
};

const informationSlice = createSlice({
  name: 'information',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setForm: (state, action) => ({ ...state, form: { ...state.form, ...action.payload } }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    resetForm: (state) => ({ ...state, form: { ...initialForm } }),

    getContactsText: getStateFromParams,
    getContactsTextSuccessful: getStateFromParams,
    getContactsTextFailure: getStateFromParams,

    getReminderText: getStateFromParams,
    getReminderTextSuccessful: getStateFromParams,
    getReminderTextFailure: getStateFromParams,

    getRequisitesText: getStateFromParams,
    getRequisitesTextSuccessful: getStateFromParams,
    getRequisitesTextFailure: getStateFromParams,

    getTariffsText: getStateFromParams,
    getTariffsTextSuccessful: getStateFromParams,
    getTariffsTextFailure: getStateFromParams,

    sendFeedback: getStateFromParams,
    sendFeedbackSuccessful: getStateFromParams,
    sendFeedbackFailure: getStateFromParams,
  },
});

export const { name, actions, reducer } = informationSlice;
