import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { actions } from '../../store';

import Component, {
  IInformationFeedbackSentPropsExternal,
  IInformationFeedbackSentActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  _: any,
  props: IInformationFeedbackSentPropsExternal
): IInformationFeedbackSentPropsExternal => ({
  history: props.history,
});

const mapDispatchToProps = (dispatch: TODO_ANY): IInformationFeedbackSentActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
