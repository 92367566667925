import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { getTransformedAmount } from '@rfb/common';

import fp from 'lodash/fp';

import { actions, IFinancialState, name, selectors } from '../../store';

import React from 'react';
import FTButtonCell from '../FTButtonCell';
import Component, { IFTTableActions, IFTTableExternal, IFTTableProps } from './Component';

import { ITableHeaderElement } from '@rfb/ui-kit/lib/components/Table/components/Content/components/Header';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';
import styles from './assets/styles/index.module.css';
import { LinkCell } from './cell/LinkCell';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: {
    history: any;
    brand_type?: string;
    state?: string;
  }
): IFTTableProps & IFTTableExternal => {
  const filter = selectors.selectFilter(state);

  const getTransformedTableRow = (cellList: any[]) => {
    const [requestDataId, requestCode, requestBrand, requestVin, requestAmount, requestInvoice] =
      cellList;

    let data: any[] = [
      <FTButtonCell
        history={props.history}
        requestDataId={requestDataId}
        brand_type={props.brand_type ? props.brand_type : 'OTHER'}
        state={props.state}
      />,
      requestCode,
    ];

    if (props.brand_type !== 'PORSCHE') {
      data = [...data, requestBrand];
    }

    data = [...data, requestVin, getTransformedAmount(requestAmount)];

    if (props.brand_type === 'PORSCHE') {
      data = [...data, <LinkCell requestInvoice={requestInvoice} />];
    }

    return {
      data,
    };
  };

  const headers: ITableHeaderElement[] = [
    { title: '', value: '', columnClassName: styles.columnControls },
    {
      title: 'Код дилера',
      value: 'request_code',
      type: 'sorting',
      sortingType: 'letter',
      columnClassName: styles.columnDealerCode,
    },
    {
      title: 'Бренд',
      value: 'request_brand',
      type: 'sorting',
      sortingType: 'letter',
      columnClassName: styles.columnBrand,
    },
    {
      title: 'VIN',
      value: 'request_vin',
      type: 'sorting',
      sortingType: 'letter',
      columnClassName: styles.columnVin,
    },
    {
      title: 'Сумма финансирования',
      value: 'request_amount',
      type: 'sorting',
      sortingType: 'letter',
    },
  ];

  return {
    headers,
    rows: fp.pipe(
      selectors.selectDataWithFieldList(state),
      fp.map(getTransformedTableRow)
    )([
      'request_data_id',
      'request_code',
      'request_brand',
      'request_vin',
      'request_amount',
      'request_invoice',
    ]),
    pageCount: selectors.selectPageCount(state),
    filter,
    isLoading: selectors.selectIsLoading(state),
    isFinancingRequestInProgress: selectors.selectIsFinancingRequestInProgress(state),
    isRequestIncludePorsche: selectors.selectIsRequestIncludePorsche(state),
    totalRowCount: selectors.selectTotalCount(state),
    totalSum: selectors.selectTotalSum(state),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    isReadOnly: storage.get(readOnlyRoleKey),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: any): IFTTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
