import { bindActionCreators } from '@reduxjs/toolkit';
import { ITable } from '@rfb/ui-kit';
import {
  OperationStatusId,
  RepoOperationDataViewDTO,
  TRepoOperationData,
} from 'dto/operation-data';
import { getTransformedAmount } from 'helpers/transform/amount';
import fp from 'lodash/fp';
import { IOperationDataState, actions, name, selectors } from 'modules/operation-data/store';
import { connect } from 'react-redux';
import { store } from 'root-store';
import styles from './assets/styles/index.module.css';
import Component, {
  IOperationDataViewTableActions,
  IOperationDataViewTableProps,
  IOperationDataViewTablePropsExternal,
  RequestFinancingStage,
} from './component';

type TOperationDataViewTableHeader = ITable['headers'][number];

type TOperationDataViewTableColumn = {
  header: TOperationDataViewTableHeader;
  transform?: (value: any, row?: RepoOperationDataViewDTO['records'][number]) => any;
};

const mapStateToProps = (
  state: { [name]: IOperationDataState },
  props: IOperationDataViewTablePropsExternal
): IOperationDataViewTableProps & IOperationDataViewTablePropsExternal => {
  //const dataFilter: IOperationDataState['repoRequestDataFilter'] = selectors.selectRepoRequestDataFilter(state);
  const filter: IOperationDataState['filter'] = selectors.selectFilter(state);
  //const filter: IOperationDataState['filter'] = selectors.selectRepoRequestFilter(state);

  const operationDataView = selectors.selectOperationDataView(state);
  const data: RepoOperationDataViewDTO = operationDataView;
  const records = data ? data.records : [];

  const columns: TOperationDataViewTableColumn[] = [
    {
      header: {
        value: 'request_vin',
        title: 'VIN',
        type: 'input',
        inputTooltipText: 'Введите любые три символа',
        filterValue: filter.vin,
        isFixed: true,
        columnClassName: styles.columnVin,
        columnKey: filter.lastResetTimestamp,
        onFilterChange: (vin: string | string[]) =>
          fp.some(Boolean, [fp.gte(fp.size(vin), 3), fp.isEmpty(vin)])
            ? store.dispatch(actions.setFilter({ vin }))
            : fp.noop,
      },
    },
    {
      header: {
        value: 'repo_request_type_name',
        title: 'Тип',
        isFixed: true,
      },
    },
    {
      header: {
        value: 'brand_name',
        title: 'Марка',
        isFixed: true,
      },
    },
    {
      header: {
        value: 'model_name',
        title: 'Модель',
        isFixed: true,
      },
    },
    {
      header: {
        value: 'repo_request_status_name',
        title: 'Статус',
        isFixed: true,
      },
    },
    {
      header: {
        value: 'refuse_reason_name',
        title: 'Причина отказа',
        isFixed: true,
        columnClassName: styles.refuseReasonColumn,
      },
    },

    {
      header: {
        value: 'price_with_vat',
        title: 'Цена авто с НДС, руб.',
        isFixed: true,
        columnClassName: styles.moneyColumn,
      },
      transform: (price_with_vat: number | null) =>
        price_with_vat != null ? getTransformedAmount(price_with_vat) : ' ',
    },
    {
      header: {
        value: 'vat_amount',
        title: 'Сумма с НДС, руб.',
        isFixed: true,
        columnClassName: styles.moneyColumn,
      },
      transform: (vat_amount: number | null) =>
        vat_amount != null ? getTransformedAmount(vat_amount) : ' ',
    },
    {
      header: {
        value: 'advance_sum',
        title: 'Аванс, руб.',
        isFixed: true,
        columnClassName: styles.moneyColumn,
      },
      transform: (advance_sum: number | null) =>
        advance_sum != null ? getTransformedAmount(advance_sum) : ' ',
    },
  ];

  const rowDataList = fp.pipe(
    fp.map((row: RepoOperationDataViewDTO['records'][number]) => {
      return fp.map((column: TOperationDataViewTableColumn) => {
        return fp.isFunction(column.transform)
          ? column.transform(fp.path(column.header.value, row), row)
          : fp.path(column.header.value, row);
      }, columns);
    }),
    fp.map((data) => ({ data }))
  )(records);

  const rowConfigList = fp.pipe(fp.map((config) => ({ config })))(records);

  const currentOperationDataRecord = selectors.selectCurrentOperationDataRecord(state);
  const currentRepoOperationData: TRepoOperationData =
    selectors.selectCurrentRepoOperationData(state);
  const operationStatusId = currentRepoOperationData?.operation_status_id;

  function getStage(operationStatusId: OperationStatusId): RequestFinancingStage {
    let stage: RequestFinancingStage;
    switch (operationStatusId) {
      case OperationStatusId.NEW_OR_SENT:
        stage = RequestFinancingStage.CREATING;
        break;
      case OperationStatusId.IN_ACTION:
      case OperationStatusId.SENT_TO_1C:
      case OperationStatusId.REFUSAL_OR_NOT_AGREED:
        stage = RequestFinancingStage.AGREEMENT;
        break;
      case OperationStatusId.AGREEMENT_OR_SIGNING_IS_NEEDED:
      case OperationStatusId.READY_FOR_FINANCING_OR_SIGNED:
      case OperationStatusId.NOT_EXECUTED:
        stage = RequestFinancingStage.SIGNING;
        break;
      case OperationStatusId.EXECUTED:
        stage = RequestFinancingStage.EXECUTED;
        break;
      default:
        stage = RequestFinancingStage.NOT_DEFINED;
        break;
    }
    return stage;
  }

  return {
    stage: getStage(operationStatusId),
    filter,
    headers: fp.map(fp.path('header'), columns),
    rows: fp.pipe(fp.zip(rowConfigList), fp.map(fp.mergeAll))(rowDataList),
    page: filter.page,
    pageCount: selectors.selectPageCount(state),
    id: props.id,
    dealerSystem: props.dealerSystem,
    operationDataView: selectors.selectOperationDataView(state),
    currentOperationDataRecord,
    requestAttachment: selectors.selectRequestAttachment(state),
    isLoading: selectors.selectIsLoading(state),
    errors: selectors.selectErrorListByField(state)('api'),
  };
};

const mapDispatchToProps = (dispatch: any): IOperationDataViewTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
