import { put, takeEvery } from 'redux-saga/effects';
import storage from 'utils/storage';
import api from 'utils/api';

import { convertApiErrorCodesToMessages } from 'helpers/app';

import apiEndpoints from 'configs/api/endpoints';
import routingConfig from 'configs/routing';

import {
  IDTOVariableContactsResponse,
  IDTOVariableReminderResponse,
  IDTOVariableTariffsResponse,
  IDTOVariableRequisitesResponse,
} from 'dto/variable';

import { actions } from './index';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { dealerSystemsKey } from '../../auth/store';
import { convertPathUsingParams } from '@rfb/common';
import notification from '../../../utils/notification';

function* getContactsText() {
  try {
    const dealerSystemConfig = storage.get(dealerSystemsKey).data;
    if (dealerSystemConfig.rb_flag === 1 || dealerSystemConfig.rf_flag === 1) {
      const result: { data: IDTOVariableContactsResponse } = yield api.get(
        apiEndpoints.variable.contacts
      );
      yield put(actions.set({ rbContactsText: result.data.text }));
    }
    if (dealerSystemConfig.rf_flag === 1) {
      const result: { data: IDTOVariableContactsResponse } = yield api.get(
        apiEndpoints.variable.contacts_rf
      );
      yield put(actions.set({ rfContactsText: result.data.text }));
    }
  } catch (error) {
    yield put(actions.getContactsTextFailure(error.response?.data));
  }
}

function* getContactsTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* getReminderText() {
  try {
    const dealerSystemConfig = storage.get(dealerSystemsKey).data;
    if (dealerSystemConfig.rb_flag === 1) {
      const result: { data: IDTOVariableReminderResponse } = yield api.get(
        apiEndpoints.variable.reminder
      );
      yield put(actions.set({ rbReminderText: result.data.text }));
    }
    if (dealerSystemConfig.rf_flag === 1) {
      const result: { data: IDTOVariableReminderResponse } = yield api.get(
        apiEndpoints.variable.reminder_rf
      );
      yield put(actions.set({ rfReminderText: result.data.text }));
    }
  } catch (error) {
    yield put(actions.getReminderTextFailure(error.response?.data));
  }
}

function* getReminderTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* getTariffsText() {
  try {
    const result: { data: IDTOVariableTariffsResponse } = yield api.get(
      apiEndpoints.variable.tariffs
    );
    yield put(actions.getTariffsTextSuccessful(result.data.text));
  } catch (error) {
    yield put(actions.getTariffsTextFailure(error.response?.data));
  }
}

function* getTariffsTextSuccessful(action: TODO_ANY) {
  yield put(actions.set({ tariffsText: action.payload }));
}

function* getTariffsTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* getRequisitesText() {
  try {
    const result: { data: IDTOVariableRequisitesResponse } = yield api.get(
      apiEndpoints.variable.requisites
    );
    yield put(actions.getRequisitesTextSuccessful(result.data.text));
  } catch (error) {
    yield put(actions.getRequisitesTextFailure(error.response?.data));
  }
}

function* getRequisitesTextSuccessful(action: TODO_ANY) {
  yield put(actions.set({ requisitesText: action.payload }));
}

function* getRequisitesTextFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: action.payload?.message }));
}

function* sendFeedback(action: TODO_ANY) {
  try {
    yield put(actions.set({ isFeedbackSending: true }));
    yield api.post(apiEndpoints.feedback.create, action.payload.data);
    yield put(
      actions.sendFeedbackSuccessful({
        history: action.payload.history,
        notificationDealerSystem: action.payload.notificationDealerSystem,
      })
    );
  } catch (error) {
    yield put(actions.sendFeedbackFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isFeedbackSending: false }));
  }
}

function* sendFeedbackSuccessful(action: TODO_ANY) {
  yield put(actions.set({ isFeedbackSent: true }));
  if (!!action.payload.notificationDealerSystem) {
    notification.info('Ваше обращение успешно отправлено');
    const notificationBackPath = convertPathUsingParams(routingConfig.notifications.path, {
      dealerSystem: action.payload.notificationDealerSystem,
    });
    action.payload.history.push(notificationBackPath);
  } else {
    action.payload.history.push(routingConfig.feedbackSent.path);
  }
}

function* sendFeedbackFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

export default function* () {
  yield takeEvery(actions.getContactsText, getContactsText);
  yield takeEvery(actions.getContactsTextFailure, getContactsTextFailure);

  yield takeEvery(actions.getReminderText, getReminderText);
  yield takeEvery(actions.getReminderTextFailure, getReminderTextFailure);

  yield takeEvery(actions.getTariffsText, getTariffsText);
  yield takeEvery(actions.getTariffsTextSuccessful, getTariffsTextSuccessful);
  yield takeEvery(actions.getTariffsTextFailure, getTariffsTextFailure);

  yield takeEvery(actions.getRequisitesText, getRequisitesText);
  yield takeEvery(actions.getRequisitesTextSuccessful, getRequisitesTextSuccessful);
  yield takeEvery(actions.getRequisitesTextFailure, getRequisitesTextFailure);

  yield takeEvery(actions.sendFeedback, sendFeedback);
  yield takeEvery(actions.sendFeedbackSuccessful, sendFeedbackSuccessful);
  yield takeEvery(actions.sendFeedbackFailure, sendFeedbackFailure);
}
