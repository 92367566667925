enum ApiErrors {
  USER_NOT_FOUND = 1000,
  USER_BLOCKED = 1001,
  USER_BLOCKED_BY_ADMIN = 1002,
  USER_BLOCKED_FAILURE_AUTH = 1003,
  USER_INACTIVE = 1010,
  PASSWORD_EXPIRED = 1020,
  RF_INFO_VALIDATION_ERROR = 1030,
  SERVICE_TERMS_ERROR = 1040,
  INCORRECT_LOGIN_PASSWORD = 1050,
  TEMP_PASSWORD_FAILURE_AUTH = 1051,
  PASSWORD_CHANGE_REQUIRED_TIME_NOT_PASSED = 1052,
  PASSWORD_CHANGE_SAME_VALUE = 1053,
  OLD_PASSWORD_INCORRECT = 1054,
  INN_KPP_FAILURE_CHECK = 1060,
  RF_INFO_ERROR = 2010,
  PHONE_NUMBER_ERROR = 1031,
  NOT_CONFIRMED_LOGIN = 1032,
  NOT_CHOOSED_DEALER = 1033,
  WRONG_BRAND_ERROR = 1055,
  INVALID_PHONE_NUMBER = 1090,
  DEALER_NOT_ATTACHED = 3010,
  PHONE_NUMBER_RELOAD = 2020,
}

export default ApiErrors;
