export const clientLine = {
  'number': '1387',
  'type': 'KL',
  'status': 0,
  'name_brand': 'Porsche',
  'name_product': 'Новые машины',
  'start_date': '2021-01-22',
  'real_finish_date': null,
  'finish_date': '2021-11-03',
  'limit_amount': 20000000,
  'total_main_debt': 0,
  'rest_limit': 20000000,
  'date_near_line_pay': null,
  'flag_near_line_pay': 0,
  'percent_rate': '0.00',
  'date_percent_rate': '2021-05-07',
  'comiss_type': '',
  'summ_comiss_debt': 0,
  'line_block': 0,
  'overdue_debt': 0,
};
