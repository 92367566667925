import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IUserState } from '../../store';

import Component, {
  IUserCodeWordChangeProps,
  IUserCodeWordChangePropsExternal,
  IUserCodeWordChangeActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IUserState },
  props: { history: TODO_ANY }
): IUserCodeWordChangeProps & IUserCodeWordChangePropsExternal => ({
  codeWord: selectors.selectFormValueByField(state)('codeWord'),
  codeWordErrorList: selectors.selectErrorListByField(state)('codeWord'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IUserCodeWordChangeActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
