import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '../../icons/Logout';
import styles from './assets/styles/index.module.css';
export var UserWidget = function (props) { return (React.createElement("div", { className: styles.main },
    React.createElement("div", { "aria-label": "user profile" },
        React.createElement("div", { className: styles.sidebarLeft },
            React.createElement(NavLink, { className: styles.name, to: props.link }, props.name),
            React.createElement("div", { className: styles.email }, props.email))),
    React.createElement("div", { "aria-label": "logout" },
        React.createElement("div", { className: styles.sidebarRight },
            React.createElement(LogoutIcon, { className: styles.logout, onClick: props.onLogout }))))); };
export default UserWidget;
