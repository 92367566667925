import { createSlice } from '@reduxjs/toolkit';
import { Directions, TUsedCarSettings } from '@rfb/common';
import { IDropdownOption } from '@rfb/ui-kit';
import {
  BrandListItemDto,
  DealerBrandCode,
  DeliveryLocationDto,
  PaymentDataListItemDto,
  TBrandModel,
  TDealerBrand,
  TFinancingRepoRequestDataFinancingInfoResponse,
  TRepoRequestDataResponse,
  TRepoRequestTypesResponse,
} from 'dto/financial-block';
import { TRepoAttachment } from 'dto/operation-data';
import { FinancingRequestDTO, FinancingRequestUsedDTO } from 'dto/rf-info';

import { getStateFromParams } from 'helpers/store';

export enum RbAutoType {
  NONE,
  NEW_ONLY,
  USED_ONLY,
  MIXED, // NEW_ONLY и MIXED_ONLY
}
export enum RepoAutoType {
  NONE,
  NEW,
  DEMO,
  MIXED, // NEW и DEMO
}
export type TCodeTypeBrand = 'both' | 'only-porsche' | 'only-non-porsche' | 'none';

export type TBrand = {
  value: string;
  label: string;
  isMultiBrand: boolean;
};

export type TFormPrefilling = {
  brand?: TBrand;
  requestModel?: string;
  requestModelId?: string;
  originCountry?: string;
  deliveryLocation?: string;
  recieverShortName?: string;
  requestBrandCode?: string;
  receiverPaymentData: PaymentDataListItemDto;
} | null;

export interface IFinancialState {
  codeTypeBrand: TCodeTypeBrand;
  codeTypeUsed: RbAutoType | RepoAutoType; // TODO Разобраться с дублированием
  currentRepoCodeType: RepoAutoType; // TODO Разобраться с дублированием
  isNoCreditLines: boolean; // TODO Удалить времянку, нормально поддержав NONE
  requestData: FinancingRequestDTO[] | FinancingRequestUsedDTO[];
  usedCarSettings: TUsedCarSettings;
  filter: {
    brand_type?: string;
    sorting?: { value: string; direction: Directions };
    pageCount: number;
    page: number;
    perPage: number;
  };
  form: {
    requestDataId?: number;
    requestCode?: string;
    brandUsed?: string;
    requestBrand?: string;
    modelUsed?: string;
    requestModel?: string;
    requestModelId?: string;
    requestVin?: string;
    notVin?: boolean; // Только для Used
    requestCarPassportDate?: string;
    requestEngineDisplacement?: string;
    requestEnginePower?: string;
    requestElectricalEnginePower?: string;
    requestMileage?: string;
    requestAmount?: string;
    requestContractPdf: File | null;
    requestContractFileName: string;
    requestContractFileId: string;
    requestActOfAcceptancePdf: File | null;
    requestActOfAcceptanceFileName: string;
    requestActOfAcceptanceFileId: string;
    requestProofOfPaymentPdf: File | null;
    requestProofOfPaymentFileName: string;
    requestProofOfPaymentFileId: string;
    requestInvoiceFileName?: string;
    // РЕПО
    isOperationHasRecords: boolean;
    originCountry?: string;
    priceWithVAT?: string;
    vatAmount?: string;
    accountNumber?: string;
    accountNumberDate?: string;
    supplierDocDate?: string;
    deliveryLocation?: string;
    recieverShortName?: string;
    paymentPurpose?: string;
  };

  lastFinancingRepoRequestDataFinancingInfoResponse: TFinancingRepoRequestDataFinancingInfoResponse | null;
  repoRequestTypes: TRepoRequestTypesResponse | null;
  repoRequestData: TRepoRequestDataResponse;
  repoRequestDataModels: IDropdownOption[];
  currentLimitAmount: number | undefined;
  deliveryLocations: DeliveryLocationDto[];
  paymentDataList: PaymentDataListItemDto[];
  advancePercent: number;
  advancePercentDemo: number;
  pageCount: number;
  totalCount: number;
  totalSum: number;
  advanceSum: number;
  isLoading: boolean;
  isFinancingRequestInProgress: boolean;
  isRequestIncludePorsche: boolean;
  codes?: DealerBrandCode[];
  errorList: { [key: string]: string[] };
  requestCodeOptions: { label: string; value: string }[];
  requestModelOptions: { label: string; value: string }[];
  usedBrandOptions: TDealerBrand[];
  usedBrandModelOptions: TBrandModel[];
  pdf_file_name?: string;
  doc_timestamp?: number;

  brandOptions: BrandListItemDto[];
  operationDataId: number;
  uploadedRepoAttachments: File[];
  isLimitRequestChangeNeed: boolean;
  isRepoApplyCompleted: boolean;
  supplierDocDateStatus: number;
  supplierDocDateAddPercent: number;
  formPrefilling: TFormPrefilling;
  repoAttachments: File[];
  generatedRepoAttachments: TRepoAttachment[];
  repoRequestDataBrands: BrandListItemDto[];
}

const initialState: IFinancialState = {
  codeTypeBrand: 'none',
  codeTypeUsed: RbAutoType.NONE,
  currentRepoCodeType: RepoAutoType.NONE,
  isNoCreditLines: false,
  usedCarSettings: {
    max_age: 15,
    max_run: 200000,
    max_cost: 12000000,
  },
  form: {
    requestCode: '',
    brandUsed: '',
    requestBrand: '',
    modelUsed: '',
    requestModel: '',
    requestModelId: '',
    requestVin: '',
    notVin: false,
    requestCarPassportDate: '',
    requestEngineDisplacement: '',
    requestEnginePower: '',
    requestElectricalEnginePower: '',
    requestMileage: '',
    requestAmount: '',
    requestContractPdf: null,
    requestContractFileName: '',
    requestContractFileId: '',
    requestActOfAcceptancePdf: null,
    requestActOfAcceptanceFileName: '',
    requestActOfAcceptanceFileId: '',
    requestProofOfPaymentPdf: null,
    requestProofOfPaymentFileName: '',
    requestProofOfPaymentFileId: '',
    requestInvoiceFileName: '',
    // РЕПО
    isOperationHasRecords: false,
    originCountry: '',
    priceWithVAT: '',
    vatAmount: '',
    accountNumber: '',
    accountNumberDate: '',
    supplierDocDate: '',
    paymentPurpose: '',
  },
  requestCodeOptions: [],
  requestModelOptions: [],
  usedBrandOptions: [],
  usedBrandModelOptions: [],
  requestData: [],
  isLoading: false,
  filter: {
    sorting: { value: '', direction: Directions.ASC },
    pageCount: 0,
    page: 1,
    perPage: 20,
  },
  pageCount: 0,
  totalCount: 0,
  advanceSum: 0,
  totalSum: 0,
  errorList: {},
  isFinancingRequestInProgress: false,
  isRequestIncludePorsche: false,

  // РЕПО
  repoRequestTypes: null,
  repoRequestData: {
    status: '',
    data: {
      records: [],
      summary: {
        request_sum: 0,
        advance_sum: 0,
      },
    },
    headers: [],
  },
  brandOptions: [],
  lastFinancingRepoRequestDataFinancingInfoResponse: null,
  repoRequestDataModels: [],
  currentLimitAmount: undefined,
  uploadedRepoAttachments: [],
  operationDataId: 0,
  isLimitRequestChangeNeed: false,
  deliveryLocations: [],
  paymentDataList: [],
  advancePercent: 0,
  advancePercentDemo: 0,
  isRepoApplyCompleted: false,
  supplierDocDateStatus: 0,
  supplierDocDateAddPercent: 0,
  formPrefilling: null,
  repoAttachments: [],
  generatedRepoAttachments: [],
  repoRequestDataBrands: [],
};

const slice = createSlice({
  name: 'financial-block',
  initialState,
  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setForm: (state, action) => ({ ...state, form: { ...state.form, ...action.payload } }),
    resetForm: (state) => ({ ...state, form: { ...initialState.form } }),
    resetFormPrefilling: (state) => ({ ...state, formPrefilling: initialState.formPrefilling }),
    resetSupplierDocDateStatus: (state) => ({
      ...state,
      supplierDocDateStatus: initialState.supplierDocDateStatus,
    }),
    resetRepoRequestDataBrands: (state) => ({
      ...state,
      repoRequestDataBrands: initialState.repoRequestDataBrands,
    }),
    resetVin: (state) => ({
      ...state,
      form: {
        ...state.form,
        requestVin: initialState.form.requestVin,
      },
      errorList: {
        ...state.errorList,
        ['requestVin']: [],
      },
    }),
    resetErrors: (state) => ({ ...state, errorList: { ...initialState.errorList } }),

    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    setRequestData: (state, action) => ({
      ...state,
      requestData: action.payload,
    }),
    setFilter: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action.payload },
    }),
    reset: () => ({ ...initialState }),

    getRequestData: getStateFromParams,
    getRequestDataSuccessful: getStateFromParams,
    getRequestDataFailure: getStateFromParams,

    getRequestDataUsed: getStateFromParams,
    getRequestDataUsedSuccessful: getStateFromParams,

    createRequestData: getStateFromParams,
    createRequestDataUsed: getStateFromParams,
    createRequestDataSuccessful: getStateFromParams,
    createRequestDataFailure: getStateFromParams,

    updateRequestData: getStateFromParams,
    updateRequestDataUsed: getStateFromParams,
    updateRequestDataSuccessful: getStateFromParams,
    updateRequestDataFailure: getStateFromParams,

    deleteRequestData: getStateFromParams,
    deleteRequestDataUsed: getStateFromParams,
    deleteRequestDataSuccessful: getStateFromParams,

    getRequestCodeOptions: getStateFromParams,
    getRequestCodeOptionsSuccessful: getStateFromParams,
    getRequestCodeOptionsFailure: getStateFromParams,

    getBrandOptions: getStateFromParams,
    getBrandOptionsSuccessful: getStateFromParams,
    getBrandModelOptions: getStateFromParams,
    getBrandModelOptionsSuccessful: getStateFromParams,

    getDealerBrandCodes: getStateFromParams,
    getDealerBrandCodesSuccessful: getStateFromParams,
    getDealerBrandCodesFailure: getStateFromParams,

    docRequest: getStateFromParams,
    docRequestSuccessful: getStateFromParams,
    docRequestFailure: getStateFromParams,

    docDownload: getStateFromParams,
    docDownloadUsed: getStateFromParams,

    tranchesRequest: getStateFromParams,
    tranchesRequestSuccessful: getStateFromParams,
    tranchesRequestFailure: getStateFromParams,

    getRequestFileBody: getStateFromParams,

    attachmentDownload: getStateFromParams,

    getUsedCarSettings: getStateFromParams,

    // РЕПО
    getRepoRequestData: getStateFromParams,
    getRepoRequestDataSuccessful: getStateFromParams,
    getRepoRequestDataFailure: getStateFromParams,

    getRepoRequestDataById: getStateFromParams,
    getRepoRequestDataByIdSuccessful: getStateFromParams,
    getRepoRequestDataByIdFailure: getStateFromParams,

    createRepoRequestData: getStateFromParams,
    createRepoRequestDataSuccessful: getStateFromParams,
    createRepoRequestDataFailure: getStateFromParams,

    deleteRepoRequestDataById: getStateFromParams,
    deleteRepoRequestDataByIdSuccessful: getStateFromParams,
    deleteRepoRequestDataByIdFailure: getStateFromParams,

    updateRepoRequestData: getStateFromParams,
    updateRepoRequestDataSuccessful: getStateFromParams,
    updateRepoRequestDataFailure: getStateFromParams,

    getRepoRequestTypes: getStateFromParams,
    getRepoRequestTypesSuccessful: getStateFromParams,
    getRepoRequestTypesFailure: getStateFromParams,

    getRepoRequestDataBrands: getStateFromParams,
    getRepoRequestDataBrandsSuccessful: getStateFromParams,
    getRepoRequestDataBrandsFailure: getStateFromParams,

    prepareRepoFinancing: getStateFromParams,

    getRepoRequestDataFinancingInfo: getStateFromParams,
    getRepoRequestDataFinancingInfoSuccessful: getStateFromParams,
    getRepoRequestDataFinancingInfoFailure: getStateFromParams,

    uploadForRepo: getStateFromParams,
    uploadForRepoSuccessful: getStateFromParams,
    uploadForRepoFailure: getStateFromParams,

    getRepoAttachments: getStateFromParams,
    getRepoAttachmentsSuccessful: getStateFromParams,
    getRepoAttachmentsFailure: getStateFromParams,

    addAttachments: getStateFromParams,

    deleteAttachment: getStateFromParams,
    deleteAttachmentSuccessful: getStateFromParams,
    deleteAttachmentFailure: getStateFromParams,

    generateFileForDownload: getStateFromParams,
    generateFileForDownloadSuccessful: getStateFromParams,
    generateFileForDownloadFailure: getStateFromParams,

    saveAndSendForApproval: getStateFromParams,
    saveAndSendForApprovalSuccessful: getStateFromParams,
    saveAndSendForApprovalFailure: getStateFromParams,

    repoRequestApply: getStateFromParams,
    repoRequestApplySuccessful: getStateFromParams,
    repoRequestApplyFailure: getStateFromParams,

    checkSupplierDocDate: getStateFromParams,

    generateDocuments: getStateFromParams,
    generateDocumentsSuccessful: getStateFromParams,
  },
});

export const { name, actions, reducer } = slice;
