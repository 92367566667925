import { rest } from 'msw';
import {
  notification_response_body,
  notification_id_responseBody,
  notification_count_responseBody,
  notification_exception_response_body,
  notification_null_response_body,
  notification_id_responseWithLinkBody,
  notification_responseWithLetterWithLink_body,
} from './notification_response_body';

export const notificationsHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/notification`, (req, res, ctx) => {
    console.log('msw-handlers: GET /notification');
    return res(
      ctx.status(200),
      ctx.set('x-pagination-page-count', '4'),
      ctx.set('x-pagination-total-count', '16'),
      //ctx.set('x-pagination-current-page', '2'),
      // ctx.set('x-pagination-per-page', '5'),
      //ctx.json(notification_response_body)
      ctx.json(notification_responseWithLetterWithLink_body)
    );
  }),
  // rest.get(`${process.env.REACT_APP_API_URL}/notification`, (req, res, ctx) => {
  //   console.log('msw-handlers: GET /notification (with Exception)');
  //   return res(
  //     ctx.status(200),
  //     ctx.json(notification_exception_response_body)
  //   );
  // }),
  rest.get(`${process.env.REACT_APP_API_URL}/notification/count`, (req, res, ctx) => {
    console.log('msw-handlers: GET /notification/count');
    return res(ctx.status(200), ctx.json(notification_count_responseBody));
  }),
  rest.get(`${process.env.REACT_APP_API_URL}/notification/:id`, (req, res, ctx) => {
    const { id } = req.params;
    console.log(`msw-handlers: GET /notification/${id}`);
    return res(ctx.status(200), ctx.json(notification_id_responseBody));
    // return res(ctx.status(200), ctx.json(notification_id_responseWithLinkBody));
  }),
  // rest.post(`${process.env.REACT_APP_API_URL}/notification/:id/read`, (req, res, ctx) => {
  //   const { id } = req.params;
  //   console.log(`msw-handlers: POST /notification/${id}/read`);
  //   return res(ctx.status(201));
  // }),
  // rest.post(`${process.env.REACT_APP_API_URL}/notification/:id/read`, (req, res, ctx) => {
  //   const { id } = req.params;
  //   console.log(`msw-handlers: POST /notification/${id}/read`);
  //     return res(
  //       ctx.status(500),
  //       ctx.json(notification_exception_response_body)
  //     );
  // }),
  rest.post(`${process.env.REACT_APP_API_URL}/notification/:id/read`, (req, res, ctx) => {
    const { id } = req.params;
    console.log(`msw-handlers: POST /notification/${id}/read`);
    return res(ctx.status(200), ctx.json(notification_null_response_body));
  }),
];
