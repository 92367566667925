import { Condition } from '@rfb/ui-kit';
import { OperationDataTypeCode, OperationStatusId } from 'dto/operation-data';
import React from 'react';
import { useDispatch } from 'react-redux';
import { operationDataStatusValue } from '../../configs/status';
import { actions } from '../../store';
import styles from './assets/styles/index.module.css';
import { LinkButton } from './link-button';

interface StatusCellProps {
  status: OperationStatusId;
  recordId: number;
  operationTypeCode: string;
}

export const StatusCell = ({ status, recordId, operationTypeCode }: StatusCellProps) => {
  const dispatch = useDispatch();
  return (
    <div>
      {operationDataStatusValue[status]}
      <Condition
        value={
          status === OperationStatusId.SENT && operationTypeCode !== OperationDataTypeCode.REQU
        }
      >
        <br />
        <LinkButton
          className={styles.linkMargin}
          onClick={() => dispatch(actions.requestRevoke({ id: recordId }))}
        >
          Отозвать
        </LinkButton>
      </Condition>
    </div>
  );
};
