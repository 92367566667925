import React from 'react';

import { AuthTemplate, History } from '@rfb/ui-kit';

import ConfirmCode from '../../../modules/confirmation/components/ConfirmCode';

import routingConfig from 'configs/routing';
import { actions } from 'modules/financial-block/store';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import confirmTypes from 'modules/confirmation/configs/confirmTypes';
import { useDispatch } from 'react-redux';

import { convertPathUsingParams } from '@rfb/common';
import { useLocation } from 'react-router-dom';
import notification from 'utils/notification';
import { isUsedCars } from '../../../modules/financial-block/helpers';

const FinanceConfirm = (props: TODO_ANY) => {
  const propsMatchParams = props.match.params;
  const { search } = useLocation();
  const isUsed = isUsedCars(props.history);
  const dispatch = useDispatch();
  const onBackClick = (): void => {
    props.history.push(routingConfig.financialBlockFTTable.path);
  };

  // const backUrl = isUsed ? '/financing-tranches/list/rb/used' : '/financing-tranches/list/rb/new'; //?confirm=true';

  const backUrl = convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
    system: props.match.params.system,
    type: props.match.params.type,
  });

  function isRb() {
    return propsMatchParams.system === 'rb';
  }

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <ConfirmCode
        changePersonalDataFlag={true}
        confirmTypeCode={confirmTypes.IBD_CONFIRM_FINANCE}
        onSuccess={() => {
          dispatch(
            isRb()
              ? actions.docRequest({
                  isUsed,
                  history: props.history,
                  onSuccess: () => {
                    props.history.push(backUrl);
                  },
                })
              : actions.repoRequestApply({
                  history: props.history,
                  onSuccess: () => {
                    props.history.push(backUrl);
                  },
                })
          );
        }}
        onFailure={() => {
          notification.info('Запрос на оформление заявки не отправлен. Повторите попытку позднее.');
          props.history.push(backUrl);
        }}
        onCancel={onBackClick}
      />
    </div>
  );

  const renderLeftSidebar = (): JSX.Element => (
    <div>
      <History type="back" to={backUrl} />
    </div>
  );

  return <AuthTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default FinanceConfirm;
