var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var SortingLetterAsc = function (props) { return (React.createElement("svg", __assign({}, props, { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M3.52 8.6H2L4.2 3H5.8L8 8.6H6.48L6.16 7.72H3.84L3.52 8.6ZM4.856 4.904L4.28 6.52H5.72L5.144 4.904C5.08 4.72267 5.032 4.53333 5 4.336C4.968 4.53333 4.92 4.72267 4.856 4.904Z", fill: "#282423" }),
    React.createElement("path", { d: "M3.95513 16.9998H2.26713L3.77913 14.9758C3.00579 14.6878 2.61913 14.1385 2.61913 13.3278C2.61913 12.6718 2.83512 12.1865 3.26713 11.8718C3.69913 11.5571 4.24846 11.3998 4.91512 11.3998H7.27513V16.9998H5.79513V15.1758H5.27513L3.95513 16.9998ZM4.93113 12.6798C4.40313 12.6798 4.13913 12.8958 4.13913 13.3278C4.13913 13.7598 4.40313 13.9758 4.93113 13.9758H5.79513V12.6798H4.93113Z", fill: "#282423" }),
    React.createElement("path", { d: "M15 3V16M15 16L18 13M15 16L12 13", stroke: "#282423", strokeWidth: "1.5" }))); };
export default SortingLetterAsc;
