import { createSelector } from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import { name } from './index';

export const selectState = fp.path(name);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  selectCurrentNotification: createSelector(selectState, fp.path('currentNotification')),
  selectFilter: createSelector(selectState, fp.path('filter')),
  selectFilterTemp: createSelector(selectState, fp.path('filterTemp')),
  selectDealers: createSelector(selectState, fp.path('dealers')),
  selectSubject: createSelector(selectState, fp.path('subject')),
  selectMessage: createSelector(selectState, fp.path('message')),
  selectAttachments: createSelector(selectState, fp.path('attachments')),
  selectNotifications: createSelector(selectState, fp.path('notifications')),
  selectPageCount: createSelector(selectState, fp.path('pageCount')),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
  selectErrorListByField: createSelector(
    selectState,
    (state) => (field: string) => fp.pathOr([], ['errors', field], state)
  ),
};
