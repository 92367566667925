import React from 'react';

import { History } from '@rfb/ui-kit';

import AppTemplate from 'templates/AppTemplate';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmCode from 'modules/confirmation/components/ConfirmCode';
import confirmTypes from 'modules/confirmation/configs/confirmTypes';
import { actions, selectors } from 'modules/user/store';
import { DTOPhoneNumberChangeRequest } from 'dto/security';

const UserPhoneNumberChangeConfirmPage = (props: TODO_ANY) => {
  const phoneNumber = useSelector((state) =>
    selectors.selectFormValueByField(state)('phoneNumber')
  );
  const dispatch = useDispatch();

  const renderLeftSidebar = (): JSX.Element => (
    <div className={styles.sidebarLeft}>
      <History type="back" to={routingConfig.profilePhoneNumberChange.path} />
    </div>
  );

  const renderContent = (): JSX.Element => (
    <div className={styles.content}>
      <ConfirmCode
        header="Изменить Номер мобильного телефона"
        confirmTypeCode={confirmTypes.IBD_CONFIRM_CHANGE}
        onSuccess={() => {
          const data: DTOPhoneNumberChangeRequest = {
            phone_number: phoneNumber,
          };
          dispatch(
            actions.changePhoneNumber({
              data,
              history: props.history,
            })
          );
        }}
        onCancel={() => props.history?.push(routingConfig.profilePhoneNumberChange.path)}
      />
    </div>
  );

  return <AppTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default UserPhoneNumberChangeConfirmPage;
