var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var CheckboxChecked = function (props) { return (React.createElement("svg", __assign({}, props, { width: "19", height: "15", viewBox: "0 0 19 15", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M1 7L7.18182 13L18 1", stroke: "#E9041E", strokeWidth: "2" }))); };
export default CheckboxChecked;
