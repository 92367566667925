import React, { useEffect } from 'react';

import { Button, ButtonWithIcon, Condition, ITable, MessageList, Table } from '@rfb/ui-kit';

import { IFinancialState } from '../../store';

import cn from 'classnames';
import fp from 'lodash/fp';
import styles from './assets/styles/index.module.css';

import { convertPathUsingParams, DealerSystems, getTransformedAmount } from '@rfb/common';
import routingConfig from 'configs/routing';

export interface IFTTableProps {
  headers: ITable['headers'];
  rows: ITable['rows'];
  pageCount: number;
  totalSum: number;
  totalRowCount: number;
  filter: IFinancialState['filter'];
  isLoading: boolean;
  isFinancingRequestInProgress: boolean;
  isRequestIncludePorsche: boolean;
  apiErrorList: string[];
  isReadOnly: boolean;
}

export interface IFTTableExternal {
  history: any;
  brand_type?: string;
  state?: string;
}

export interface IFTTableActions {
  actions: {
    getRequestData: Function;
    docDownload: Function;
    setFilter: Function;
  };
}

const FTTable = (props: IFTTableProps & IFTTableActions & IFTTableExternal) => {
  useEffect(() => {
    props.actions.getRequestData({
      ...props.filter,
    });
  }, [props.filter, props.actions]);

  const getDocState = props.state === 'DOC';

  const onSortingChange = (sorting: ITable['sorting']): void =>
    props.actions.setFilter({ sorting });

  return (
    <div className={styles.main}>
      <div className={cn(styles.topBar, styles.flexCenter)}>
        {!props.isReadOnly && (
          <ButtonWithIcon
            type="add"
            text="Добавить автомобиль"
            isActive={
              !props.isLoading &&
              !props.isFinancingRequestInProgress &&
              !getDocState &&
              !props.isRequestIncludePorsche
            }
            onClick={() => {
              let url;
              const isRf = props.history.location.pathname.includes('/rf');
              if (isRf) {
                url = convertPathUsingParams(routingConfig.financialBlockFTCreateBySystem.path, {
                  system: DealerSystems.RUSFINANCE,
                });
              } else {
                url = props.history.location.pathname.includes('/new')
                  ? convertPathUsingParams(routingConfig.financialBlockFTCreate.path, {
                      system: DealerSystems.ROSBANK_AVTO,
                      type: 'new',
                    }) + props.history.location.search
                  : convertPathUsingParams(routingConfig.financialBlockFTCreateBySystem.path, {
                      system: DealerSystems.ROSBANK_AVTO,
                    }) + props.history.location.search;
              }
              props.history.push(url);
            }}
          />
        )}

        <div className={styles.label}>
          Кол-во автомобилей:
          <div className={styles.text}>{props.totalRowCount}</div>
        </div>
        <div className={styles.label}>
          Итоговая сумма:
          <div className={styles.text}>{getTransformedAmount(props.totalSum)}</div>
        </div>
        <Condition value={!getDocState}>
          {!props.isReadOnly && (
            <Button
              text="Отправить запрос на кредитную заявку"
              isDisabled={
                props.isLoading ||
                !fp.isEmpty(props.apiErrorList) ||
                props.isFinancingRequestInProgress ||
                props.rows.length === 0
              }
              onClick={() => {
                const url = convertPathUsingParams(routingConfig.financialBlockConfirmation.path, {
                  system: DealerSystems.ROSBANK_AVTO,
                  type: 'new',
                });

                props.history.push(url);
              }}
            />
          )}
        </Condition>
        <Condition value={getDocState}>
          <Button
            text="Скачать документ для просмотра"
            isDisabled={
              props.isLoading ||
              !fp.isEmpty(props.apiErrorList) ||
              props.isFinancingRequestInProgress
            }
            onClick={() => {
              props.actions.docDownload({
                history: props.history,
                brand_type: props.filter.brand_type,
              });
            }}
          />
        </Condition>
      </div>

      <Condition value={!fp.isEmpty(props.apiErrorList)}>
        <MessageList type="error" messages={props.apiErrorList} />
      </Condition>

      <Table
        headers={props.headers}
        rows={props.rows}
        sorting={props.filter.sorting}
        pageCount={props.pageCount}
        page={props.filter.page as number}
        isLoading={props.isLoading || props.isFinancingRequestInProgress}
        onSortingChange={onSortingChange}
        onPageChange={(page: number) => props.actions.setFilter({ page })}
      />
    </div>
  );
};

export default FTTable;
