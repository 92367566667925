import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name } from './index';

export const selectState = fp.path(name);
const selectForm = createSelector(selectState, fp.path('form'));

export default {
  selectRbContactsText: createSelector(selectState, fp.path('rbContactsText')),
  selectRfContactsText: createSelector(selectState, fp.path('rfContactsText')),
  selectRbReminderText: createSelector(selectState, fp.path('rbReminderText')),
  selectRfReminderText: createSelector(selectState, fp.path('rfReminderText')),
  selectTariffsText: createSelector(selectState, fp.path('tariffsText')),
  selectRequisitesText: createSelector(selectState, fp.path('requisitesText')),
  selectFormValueByField: createSelector(
    selectForm,
    (state) => (field: string) => fp.path(field, state)
  ),
  selectIsFeedbackSending: createSelector(selectState, fp.path('isFeedbackSending')),
  selectIsFeedbackSent: createSelector(selectState, fp.path('isFeedbackSent')),
  selectSelectedSystem: createSelector(selectState, fp.path('selectedSystem')),
  selectErrorListByField: createSelector(
    selectState,
    (state) => (field: string) => fp.pathOr([], ['errorList', field], state)
  ),
};
