import React from 'react';
import routingConfig from 'configs/routing';

import fp from 'lodash/fp';

import { Button, Condition, Input, Form, MessageList } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';

// TODO Replace the validator rules to the project root config folder
import { passwordRules, passwordRepeatRules } from 'modules/auth/configs/validation';

import { IDTOPasswordChangeRequest } from 'dto/security';

import styles from './assets/styles/index.module.css';

export interface IUserPasswordChangeProps {
  passwordOld: string;
  passwordOldErrorList: string[];
  passwordNew: string;
  passwordNewErrorList: string[];
  passwordNewRepeat: string;
  passwordNewRepeatErrorList: string[];
  apiErrorList: string[];
}

export interface IUserPasswordChangePropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface IUserPasswordChangeActions {
  actions: {
    setForm: Function;
    setError: Function;
    reset: Function;
    changePassword: Function;
  };
}

const PasswordExpired = (
  props: IUserPasswordChangeProps & IUserPasswordChangePropsExternal & IUserPasswordChangeActions
) => {
  const validators = {
    passwordOld: configureValidator({
      name: 'passwordOld',
      rule: passwordRules,
      setError: props.actions.setError,
    }),
    passwordNew: configureValidator({
      name: 'passwordNew',
      rule: passwordRules,
      setError: props.actions.setError,
    }),
    passwordNewRepeat: configureValidator({
      name: 'passwordNewRepeat',
      rule: passwordRepeatRules(props.passwordNew),
      setError: props.actions.setError,
    }),
  };

  const onPasswordOldChange = (passwordOld: string): void => {
    validators.passwordOld(passwordOld).finally((): void => props.actions.setForm({ passwordOld }));
  };
  const onPasswordNewChange = (passwordNew: string): void => {
    validators.passwordNew(passwordNew).finally((): void => props.actions.setForm({ passwordNew }));
  };
  const onPasswordNewRepeatChange = (passwordNewRepeat: string): void => {
    validators
      .passwordNewRepeat(passwordNewRepeat)
      .finally((): void => props.actions.setForm({ passwordNewRepeat }));
  };
  const onFormSubmit = (): void => {
    const data: IDTOPasswordChangeRequest = {
      old_password: props.passwordOld,
      new_password: props.passwordNew,
      new_password_retry: props.passwordNewRepeat,
    };
    const rules = [
      validators.passwordOld(data.old_password),
      validators.passwordNew(data.new_password),
      validators.passwordNewRepeat(data.new_password_retry),
    ];
    runRulesChain(rules).then(() =>
      props.history.push(routingConfig.profilePasswordChangeConfirmation.path)
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h1>Изменить пароль</h1>
      </div>

      <Form onSubmit={onFormSubmit}>
        <Input
          type="password"
          label="Старый пароль"
          value={props.passwordOld}
          onChange={onPasswordOldChange}
        />
        <MessageList type="error" messages={props.passwordOldErrorList} />

        <h2 className={styles.explanation}>
          Пароль должен содержать не менее 8 символов трех групп: символы нижнего регистра, символы
          верхнего регистра, числа, специальные символы
        </h2>

        <Input
          type="password"
          label="Новый пароль"
          value={props.passwordNew}
          onChange={onPasswordNewChange}
        />
        <MessageList type="error" messages={props.passwordNewErrorList} />

        <Input
          type="password"
          label="Повторите пароль"
          value={props.passwordNewRepeat}
          onChange={onPasswordNewRepeatChange}
        />
        <MessageList type="error" messages={props.passwordNewRepeatErrorList} />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Button type="submit" text="Сохранить" />
      </Form>
    </div>
  );
};

export default PasswordExpired;
