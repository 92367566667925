import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { name, actions, selectors, IInformationState } from '../../store';

import Component, {
  IInformationContactsProps,
  IInformationContactsActions,
  IInformationContactsPropsExternal,
} from './component';

const mapStateToProps = (
  state: { [name]: IInformationState },
  props: { history: TODO_ANY }
): IInformationContactsProps & IInformationContactsPropsExternal => ({
  rb_text: selectors.selectRbContactsText(state),
  rf_text: selectors.selectRfContactsText(state),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IInformationContactsActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
