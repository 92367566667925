export const tranchesPaymentDocDownload =
  '%PDF-1.4\n' +
  '%ГѓВ¤ГѓВјГѓВ¶ГѓВџ\n' +
  '2 0 obj\n' +
  '<</Length 3 0 R/Filter/FlateDecode>>\n' +
  'stream\n' +
  "xВњГ­MВЏ$;Г­>ВїВўГЏH=T>+В‘F-ГµГ¬ГЋ qВ­Г„qZx ВґВ‹Г„В»Г°Г·Г±gГўВ¤ВЄВє{Г¶ВЃВёВ°+Гµ$]В‰Г­ГВЋГ­8В®^ВћГќГ©_OГї<-В§ZВ©В¦g*Г‘=В—Г“ГЏyГєГѓВЇNГїxr'ГјГїГі_ВџbВЂoВі[ВћГ«)ГҐГ§Гµ|ГјГјВ—Г“OВїzrГ‹ГІВњГ¤В™ГіГ6ВЅwГє\bГіBГ¶ГђГ¦В‡Г•jГі,В¦\bГџГЉГ„%?В‡ГЎ!Г’В’jfJВЋВґВ”Г В¦Гѓ<B:ГЁQa0ГёВ€h0OВВљDГGDAq8a]yZВ±ВГёВ‘Гў*#.zВЁГГЉВ€ВЌ'*ВѕbГ±ГЅВџГ»Гї#Г®ГїГ®ГґГЏSВ¬0wГЌВ°<>Гµ>tВ“В›|ВЉ6Г‘RВІ_ГЎВЇГ·!yГњ6iВЃqkIВ§ГЇВЅГ№Г­dВљkxВЋCs\n" +
  'mВЂiГўВЂВ§Вї=1Г®Г–Г†|Гљ\fВ§В¦ВЃГ‡MВ ^В&ГђГЏВµГ¦Г©Гї"ВІГ€CВЃ\'ГџmВЂГNВ®ВЊГ’vГјsГЁГѓВЁ3WU\n' +
  "H`]+В iГЌoВ§ГЅf]P&+Г©/Г‚[ГЎSВљГ©ГґГµIВљTQF:В°r2В›_m В‡M'ВЇГy*NВ›'lГЋtГј\n" +
  'IГҐ9В¦[;4Г¬ВЁВЈВјrHВћ~HВµVГЂВќВЊdГіГЁВµВЇ`Вµ+XГ­\n' +
  'В†NГ XГЊГ„qГњВ­ВёBГњГ„ГџВ‡ГћВ·Г“В­^ГЌГЂOГ®Г”@ГЌВЉГ»В‰ВљГ€Г¦NГ†ГҐГђГЁВ‰0Г”Гѕ\n' +
  "ВЅKГ”'В™Гљ8AГ›\fIzВ„BffKГ’WXВЏГ‚F\n" +
  'Гё>ГґГ№В»ВЅВєyВ¤8В‰T$ВЎВ¶pSГѓВќГҐ9ГЈhГњEГ…PВ€В·x"ВЌВџГ„6AГ›ВєВГPГ€\fГ‚lIГє*Г‚qВ„5gГљoВ¶+ВёГ™sГ‚#Г¤d1Вј+WKГ§В·SВ™\b$В•ВЏ+Г­{2}+-ГЋ;Г’Г€pLГЇ}Z\\ВќГ›NВ±ГЃ|В‡ceГ™ВЂВЇГЁВІaВЌВЎ}ГЇВ€Г„&iГќВ­GВ¬Г…Г±ГЂГ°ВўpВЁВЌp\bВѓ<ГЎГµGВЎg!Г·Г¶7ZВ‹1ГЌВЅ\'Yw^В‰EВ№jГёВ­Г‚\n' +
  "В•PГ–gВґZВ¶ В§mC1%.Г¤faГђYe1'ВЋГјВ›В¶+Г­1В™Г±oГЇ%Г°ГsГ’mГ‡TВ™ГЋ{В¶Г­\t7В©Г“4В‹В›ГўГ¶JВ¦ГЋГ¶`ГІ^oEOВЅГ„{ГЊ=В™Вњ]Г·xГ№2Г‹В¶ГЈ=2Г»В¤GГЈrВ°В­]Гљd)В№ВЎГЅ>ГґpГёNOВЂvГѓВЇmГґВ®@Г„Гµ{Rvm/bВ‚<В©.ГЋh8В–'Г№IoВ—Q:Г™|В›ВЄГ‚ВЌВћgGi7В§Г¶Г„В¶qgВ§aГ·В„ВВ©В·ВЉ\n" +
  'ВЂГђ(Г„M`ВµГ¶7Г№Вћ-DГЁ\n' +
  "ГЏm]Г¬ahГ—ВЇ)+В†В®M.$Г™ГЃГ”Z1В„ГЅ*m%cyГЌВ†3_qГ‹В„E6В”ВћjВЏВ№xГГіВєГµВњrgГ“n<msВВ‹~Г Г°В¶'В°В№ГЌXВ©ВЌВ«Г¶Вў0ГёВ„Гќ\tnTmГ«ГЉВЅPГЉВЈВ‚Г’fVГ°В•Гѓ?\fsВЏOВ‚ГґВЊcВґГ—/O>ВђВ©ГґГЁВ№ВѕГјГ№ГґГ«w\\Г­Г©Г‹O|YГњГҐГ¬_ВЃВЏВЂГ±^В–t9В»В—%_Г c]\n" +
  '7*4iX]В®Г‹+ГЌГєtВ‰В‹ГЊГЅВЊГ“ГЊВ„sВ†ВЃВ—?}Г№Г­Г“Г›В—В§ГџMГ¤ВЂВ•В„ГіQГ…]GГ”@@Г’В€\t\b0"1ВЋВЁqГ‰В©\bВ­В€ВЎuГ†zrВ…ВїГ€`В’ГљgpВЎ?Г»tIh\\ГћВ€В®wВ„ГїvI/nHГЇГњВ‹sW;Г†Г pL/\n' +
  'ВЊГі@HВ‚В©Г„OГ»ГѓВ€В]В’ГЏВў_dГє\\Г»Г—В…ВљВ•>ВЇГґВ‰Г”.В™!y Г¤qГЂ}Г†eВєВ·Г‹ВЉВЋxГ‡ВҐГµГЅВѓ<Г iГЂВЈГЉГ“^ВјВїВњВЈВ¬Г‹ГєeВјГЃГЄFВћГ№В„LaYTВџQГГ¶+_ГєВ°Г‚_g"В–pГ»\n' +
  'В¬ГµГ—В‹Г«dВЎГ‚ВїГ’\bcВ©fГЃВ”)ВќВќВЉГ’vВ¬В›Гј=В“Г“ВѕoВ¤ГўГІВ‘:ВџpВЏГµ#"YВ„ZВ‘ГћГ”Г†В’ГЂГђГљ\bВ«В©ВўlГ‚CnГЈГ—p%Г„5]\'Вљ;ВЅВЅ.oГћГ»ГњtAВµВЋГ„NВВџВЊ%ГҐГ’Г’В¦ГЏГ `2jГµГ›%f;ВЄВ№Вќ8Г olГЅВ°ВЁtГ–В®ГЇВІВҐ\\ВЊ[:В›VГ‘В°%ГІГ¬В‰/0;YВ¬>tВ©"ГЎ\fW<nВїВ®DГЉNВЋyВђГ¤В›Г‚B{Г·Г“@IГ—ВЅfFВ”В±ГёВµeГ¬Г©3lqPГЃВіВЄ;Г‰]В”В‰8YГ»ГЁwВњГ­:В‡Q9ВіГЉ#Гќm{ГЇВјnD\n' +
  'ВњВ®M]В–Гђ4xГЉВ–lВёВ›ГЉLqГ–В ГЊ!^ВєВ‘|GВ…\tВ‰Г¬6[30hJГ‰Г‚В…,zГ—ГµВї$5ГЃВЉPg4MВ»Va]Г‡В…ВњГ¤\n' +
  'pl"Q\n';
