import React, { useEffect } from 'react';

import { Button } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

export interface IInformationFeedbackSentPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void,
  };
}

export interface IInformationFeedbackSentActions {
  actions: {
    set: Function,
  };
}

const InformationSent = (
  props: IInformationFeedbackSentPropsExternal & IInformationFeedbackSentActions
) => {
  const onButtonClick = (): void => props.history.push(routingConfig.information.path);

  useEffect(() => {
    return () => {
      props.actions.set({ isFeedbackSent: false });
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Ваше обращение успешно отправлено</h1>

      <Button wrapperClassName={styles.button} text="OK" onClick={onButtonClick} />
    </div>
  );
};

export default InformationSent;
