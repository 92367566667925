import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import React from 'react';
import { FinancingRequestDTO, FinancingRequestUsedDTO } from '../../../dto/rf-info';
import { DropDownCell } from './transform/DropDownCell/DropDownCell';

export const isUsedCars = (history: TODO_ANY): boolean => {
  let isNewCars =
    history.location.pathname.includes('/new') || history.location.search.includes('code_type=new');
  let isUsedCars =
    history.location.pathname.includes('/used') ||
    history.location.search.includes('code_type=used');
  if (!isNewCars && !isUsedCars) {
    isUsedCars = false;
  }
  return isUsedCars;
};

export const getTransformedAttachments = (
  notUse: TODO_ANY,
  row: FinancingRequestDTO | FinancingRequestUsedDTO | undefined
): JSX.Element => {
  return row?.request_attachments.length ? (
    <DropDownCell
      items={
        row
          ? row.request_attachments.map((attachment) => ({
              title: attachment.file_name,
              file_id: attachment.attachment_id,
              request_data_id: row.request_data_id,
            }))
          : []
      }
    />
  ) : (
    <div />
  );
};

export function getReceiverOptionValueAndLabel(
  recieverShortName: string,
  recieverBankName: string | undefined
) {
  return `${recieverShortName}, ${recieverBankName}`;
}

export function getAutoType(history: TODO_ANY): string {
  const pathname = history.location.pathname;
  const lastIndex = pathname.lastIndexOf('/');
  return lastIndex !== -1 ? pathname.substring(lastIndex + 1) : null;
}

export function log(message: string): boolean {
  console.log(message);
  return true;
}
