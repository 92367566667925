import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import Button from '../Button';
import Condition from '../../services/Condition';
import styles from './assets/styles/index.module.css';
export var NotificationConfirm = function (props) { return (React.createElement("div", { className: cn(props.wrapperClassName), role: "alertdialog" },
    React.createElement("div", { className: styles.main },
        React.createElement("div", { className: styles.message }, props.message),
        React.createElement("div", { className: styles.buttonGroup },
            React.createElement(Button, { wrapperClassName: styles.button, text: props.buttonTextAgree, onClick: props.onAgree }),
            React.createElement(Condition, { value: fp.isFunction(props.onDisagree) },
                React.createElement(Button, { wrapperClassName: styles.button, text: props.buttonTextDisagree, onClick: props.onDisagree })))))); };
NotificationConfirm.defaultProps = {
    buttonTextAgree: 'Да',
    buttonTextDisagree: 'Отмена',
};
export default NotificationConfirm;
