import React from 'react';

import { convertPathUsingParams } from '@rfb/common';

import { History } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import AppTemplate from 'templates/AppTemplate';

import TrancheRepaymentHeader from 'modules/tranche/components/RepaymentHeader';
import TrancheTableOrderable from 'modules/tranche/components/TableOrderable';

import { store } from 'root-store';
import { selectors as clientLineSelectors } from 'modules/client-line/store';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const Main = (props: TODO_ANY) => {
  const renderContent = () => (
    <div>
      <div className={styles.table}>
        <div className={styles.header}>
          <TrancheRepaymentHeader history={props.history} />
        </div>
        <div className={styles.table}>
          <TrancheTableOrderable />
        </div>
      </div>
    </div>
  );

  const renderLeftSidebar = () => {
    const state = store.getState();
    const currentClientLine = clientLineSelectors.selectCurrentClientLine(state);

    const url: string = currentClientLine.number
      ? convertPathUsingParams(routingConfig.tranchesListByClientLineIdAndType.path, {
          clientLineId: currentClientLine.number,
          clientLineType: currentClientLine.type,
        })
      : routingConfig.tranches.path;

    return <History type="back" to={url} />;
  };

  return <AppTemplate sidebarLeft={renderLeftSidebar()} content={renderContent()} />;
};

export default Main;
