import React, { SVGProps } from 'react';

export const Clip = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg version="1.1" {...props} width="20" height="20" viewBox="-1 0 13 13" opacity="0.6">
    <desc>Created with Fabric.js 4.6.0</desc>
    <defs></defs>
    <g transform="matrix(0.02 0 0 -0.02 5.96 6.5)" id="G-sTF0H34gVlUTuDtdWv5">
      <path
        vector-effect="non-scaling-stroke"
        transform=" translate(0, 0)"
        d="M 0 -256 C -54.013 -256 -97.955 -212.05700000000002 -97.955 -158.04500000000002 L -97.955 180.93599999999998 C -97.955 222.32599999999996 -64.281 255.99999999999997 -22.891000000000005 255.99999999999997 C 18.498999999999995 255.99999999999997 52.17299999999999 222.32599999999996 52.17299999999999 180.93599999999998 L 52.17299999999999 -133.48900000000003 C 52.17299999999999 -161.81600000000003 29.12699999999999 -184.86400000000003 0.7979999999999876 -184.86400000000003 C -27.529000000000014 -184.86400000000003 -50.576000000000015 -161.81700000000004 -50.576000000000015 -133.48900000000003 L -50.576000000000015 163.42199999999997 L -19.229000000000013 163.42199999999997 L -19.229000000000013 -133.48900000000003 C -19.229000000000013 -144.53100000000003 -10.245000000000013 -153.51700000000002 0.7989999999999853 -153.51700000000002 C 11.842999999999986 -153.51700000000002 20.826999999999984 -144.53200000000004 20.826999999999984 -133.48900000000003 L 20.826999999999984 180.93499999999995 C 20.826999999999984 205.04099999999994 1.2149999999999856 224.65199999999993 -22.89100000000002 224.65199999999993 C -46.99700000000002 224.65199999999993 -66.60800000000002 205.03999999999994 -66.60800000000002 180.93499999999995 L -66.60800000000002 -158.04500000000007 C -66.60800000000002 -194.77200000000008 -36.72800000000002 -224.65300000000008 -1.4210854715202004e-14 -224.65300000000008 C 36.72799999999999 -224.65300000000008 66.60799999999999 -194.77200000000008 66.60799999999999 -158.04500000000007 L 66.60799999999999 163.4219999999999 L 97.95499999999998 163.4219999999999 L 97.95499999999998 -158.04500000000007 C 97.95499999999998 -212.05700000000007 54.012999999999984 -256.00000000000006 -1.4210854715202004e-14 -256.00000000000006 z"
        stroke-linecap="round"
      />
    </g>
  </svg>
);

export default Clip;
