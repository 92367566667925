import React from 'react';
import ReactSwitch from 'react-switch';
import cn from 'classnames';
import fp from 'lodash/fp';
import Condition from '../../services/Condition';
import styles from './assets/styles/index.module.css';
import './assets/styles/override.css';
export var SwitchPositionEnum;
(function (SwitchPositionEnum) {
    SwitchPositionEnum[SwitchPositionEnum["LEFT"] = 0] = "LEFT";
    SwitchPositionEnum[SwitchPositionEnum["CENTER"] = 1] = "CENTER";
    SwitchPositionEnum[SwitchPositionEnum["RIGHT"] = 2] = "RIGHT";
})(SwitchPositionEnum || (SwitchPositionEnum = {}));
export var Switch = function (props) {
    var _a, _b;
    var prefixClassNames = cn((_a = {},
        _a[styles.textPrefix] = true,
        _a[styles.text] = true,
        _a[styles.textActive] = !props.isChecked,
        _a[styles.textInactive] = props.isChecked,
        _a));
    var postfixClassNames = cn((_b = {},
        _b[styles.textPostfix] = true,
        _b[styles.text] = true,
        _b[styles.textActive] = props.isChecked,
        _b[styles.textInactive] = !props.isChecked,
        _b));
    var switchClassNames = cn([styles.switch, 'ui-kit-switch']);
    var mainStyle = styles.main;
    switch (props.position) {
        case SwitchPositionEnum.CENTER:
            mainStyle = styles.mainCenter;
            break;
        case SwitchPositionEnum.RIGHT:
            mainStyle = styles.mainRight;
            break;
        case SwitchPositionEnum.LEFT:
        default:
            mainStyle = styles.main;
            break;
    }
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: mainStyle },
            React.createElement(Condition, { value: !fp.isEmpty(props.prefix) },
                React.createElement("div", { className: prefixClassNames }, props.prefix)),
            React.createElement(ReactSwitch, { className: switchClassNames, checked: props.isChecked, checkedIcon: false, uncheckedIcon: false, width: 42, height: 26, handleDiameter: 22, activeBoxShadow: "", onChange: props.onChange }),
            React.createElement(Condition, { value: !fp.isEmpty(props.postfix) },
                React.createElement("div", { className: postfixClassNames }, props.postfix)))));
};
export default Switch;
