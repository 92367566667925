import React from 'react';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import FTButtonCellUsed from '../FTButtonCellUsed';
import history from 'utils/history';

export const getFTButtonCell = (notUse: TODO_ANY, row: TODO_ANY): JSX.Element => {
  return (
    <FTButtonCellUsed
      history={history}
      requestDataId={row.request_data_id}
      brand_type={row.brand_type ? row.brand_type : 'OTHER'}
      state={row.state}
      isActive={!history.location.search.includes('state=DOC')}
    />
  );
};
