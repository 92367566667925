import { call, put, takeEvery } from 'redux-saga/effects';

import { convertApiErrorCodesToMessages } from 'helpers/app';

import apiEndpoints from 'configs/api/endpoints';
import { DTOCodeConfirmationResponse, DTOGetConfirmCodeResponse } from 'dto/confirmcode';

import { actions as authActions } from 'modules/auth/store/slice';
import { actions } from './index';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import notification from 'utils/notification';
import new_api from '../../../utils/new-api';

function* codeRequest(action: TODO_ANY) {
  try {
    yield put(actions.reset());
    yield put(actions.set({ isLoading: true }));
    const result: { data: DTOGetConfirmCodeResponse } = yield new_api.post(
      apiEndpoints.confirmation.sendSms,
      action.payload.data
    );
    yield put(
      actions.codeRequestSuccessful({
        phoneNumber: result.data['masked_phone_number'],
        factorUrl: result.data.factor_url,
        onSuccess: action.payload.onSuccess,
      })
    );
  } catch (error) {
    yield put(actions.codeRequestFailure(error.response?.data));
  } finally {
    yield put(actions.set({ isLoading: false }));
  }
}

function* codeRequestSuccessful(action: TODO_ANY) {
  yield put(
    actions.set({
      phoneNumber: action.payload.phoneNumber,
      factorUrl: `${action.payload.factorUrl}/check-confirm-code`,
    })
  );
  yield call(action.payload.onSuccess);
}

function* codeRequestFailure(action: TODO_ANY) {
  yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
}

function* confirm(action: TODO_ANY) {
  try {
    yield put(actions.set({ isSending: true }));
    const result: { data: DTOCodeConfirmationResponse } = yield new_api.post(
      apiEndpoints.confirmation.checkCode,
      action.payload.data
    );
    if (result.data.check_factor_result === 'OK') {
      yield put(actions.confirmSuccessful({ onSuccess: action.payload.onSuccess }));
    } else {
      // yield put(actions.confirmFailure(result.data));
      const errorResponseData = {
        onFailure: () => {
          console.log(`FAKE onFailure`);
        },
      };
      errorResponseData.onFailure = action.payload.onFailure;
      yield put(actions.confirmFailure(errorResponseData));
    }
  } catch (error) {
    const errorResponseData = error.response?.data;
    errorResponseData.onFailure = action.payload.onFailure;
    yield put(actions.confirmFailure(errorResponseData));
  } finally {
    yield put(actions.set({ isSending: false }));
  }
}

function* confirmSuccessful(action: TODO_ANY) {
  yield call(action.payload.onSuccess);
}

function* confirmFailure(action: TODO_ANY) {
  if (action.payload.check_factor_result === 'CONFIRM_SMS_COUNT_EXCEEDED') {
    yield put(authActions.logout({}));
    notification.info('Превышено число СМС для подтверждения');
  } else if (action.payload.check_factor_result === 'CONFIRM_ATTEMPT_COUNT_EXCEEDED') {
    yield put(actions.set({ isBlocked: true }));
    yield put(
      actions.setError({
        code: [
          `Использованы 3 попытки неверного ввода кода.
    Через 5 минут можно повторить попытку или обратиться в Банк.
    `,
        ],
      })
    );
  } else if (action.payload.check_factor_msg) {
    yield put(actions.setError({ code: [action.payload.check_factor_msg] }));
  } else {
    yield put(actions.setError({ api: convertApiErrorCodesToMessages(action.payload) }));
    yield call(action.payload.onFailure);
  }
}

const sagas = function* () {
  yield takeEvery(actions.codeRequest, codeRequest);
  yield takeEvery(actions.codeRequestSuccessful, codeRequestSuccessful);
  yield takeEvery(actions.codeRequestFailure, codeRequestFailure);

  yield takeEvery(actions.confirm, confirm);
  yield takeEvery(actions.confirmSuccessful, confirmSuccessful);
  yield takeEvery(actions.confirmFailure, confirmFailure);
};

export default sagas;
