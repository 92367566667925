import React from 'react';

import AppGuest from './components/AppGuest';
import AppUser from './components/AppUser';

import '@rfb/common/assets/styles/index.css';

import { Logger } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

export interface IAppProps {
  state: TODO_ANY;
  token: string;
}
export interface IAppActions {
  actions: {
    getClientLogSettings: Function;
  };
}

// Логгер клиентского приложения
let logger: Logger;

const App = (props: IAppProps & IAppActions) => (props.token ? <AppUser /> : <AppGuest />);

export default App;
