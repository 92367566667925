var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var SortingLetterDesc = function (props) { return (React.createElement("svg", __assign({}, props, { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M3.95513 8.6H2.26713L3.77913 6.576C3.00579 6.288 2.61913 5.73867 2.61913 4.928C2.61913 4.272 2.83512 3.78667 3.26713 3.472C3.69913 3.15733 4.24846 3 4.91512 3H7.27513V8.6H5.79513V6.776H5.27513L3.95513 8.6ZM4.93113 4.28C4.40313 4.28 4.13913 4.496 4.13913 4.928C4.13913 5.36 4.40313 5.576 4.93113 5.576H5.79513V4.28H4.93113Z", fill: "#282423" }),
    React.createElement("path", { d: "M3.52 17.0002H2L4.2 11.4002H5.8L8 17.0002H6.48L6.16 16.1202H3.84L3.52 17.0002ZM4.856 13.3042L4.28 14.9202H5.72L5.144 13.3042C5.08 13.1229 5.032 12.9335 5 12.7362C4.968 12.9335 4.92 13.1229 4.856 13.3042Z", fill: "#282423" }),
    React.createElement("path", { d: "M15 3V16M15 16L18 13M15 16L12 13", stroke: "#282423", strokeWidth: "1.5" }))); };
export default SortingLetterDesc;
