import { Logger, downloadLog } from '@rfb/common';
import { Button } from '@rfb/ui-kit';
import { actions as appActions } from 'modules/app/store';
import React from 'react';
import { useDispatch } from 'react-redux';
import AppTemplate from 'templates/AppTemplate';
import styles from './assets/styles/index.module.css';

const ClientLogPage = () => {
  const dispatch = useDispatch();
  const renderContent = () => (
    <>
      <div>
        <h1 className={styles.title}>Журнал работы приложения</h1>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
        <Button text="Отправить на сервер" onClick={() => dispatch(appActions.postClientLog({}))} />
        <Button text="Сохранить в файле" onClick={() => downloadLog(Logger.getInstance())} />
      </div>
    </>
  );

  return <AppTemplate content={renderContent()} />;
};

export default ClientLogPage;
