import fp from 'lodash/fp';
import React, { useEffect, useState } from 'react';

import { configureValidator, getTransformedAmount, runRulesChain } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import {
  Button,
  Condition,
  DatePickerLargeTextSize,
  Dropdown,
  IDropdownOption,
  Input,
  MessageList,
  Text,
} from '@rfb/ui-kit';
import {
  PaymentDataListItemDto,
  TFinancingRepoRequestDataFinancingInfoResponse,
  TSentData,
} from 'dto/financial-block';
import {
  accountNumberDateRules,
  accountNumberRules,
  brandRules,
  deliveryLocationRules,
  originCountryRules,
  paymentPurposeRules,
  priceWithVATRules,
  recieverShortNameRules,
  requestModelRules,
  requestVinRules,
  supplierDocDateRules,
  vatAmountRules,
} from 'modules/financial-block/configs/validation';
import { getAutoType, getReceiverOptionValueAndLabel } from 'modules/financial-block/helpers';
import { TBrand, TFormPrefilling } from 'modules/financial-block/store';
import MoneyInput from './MoneyInput';
import styles from './assets/styles/index.module.css';

export interface IAllCreateFormProps {
  accountNumber: string;
  accountNumberErrorList: string[];
  accountNumberDate: string;
  accountNumberDateErrorList: string[];
  advancePercent: number;
  advancePercentDemo: number;
  apiErrorList: string[];
  priceWithVAT: string;
  priceWithVATErrorList: string[];
  currentLimitAmount: number | undefined;
  deliveryLocation: string;
  deliveryLocations: IDropdownOption[];
  deliveryLocationErrorList: string[];
  isLoading: boolean;
  lastFinancingRepoRequestDataFinancingInfoResponse: TFinancingRepoRequestDataFinancingInfoResponse;
  vatAmountErrorList: string[];
  originCountry: string;
  originCountryErrorList: string[];
  paymentPurpose: string;
  paymentPurposeErrorList: string[];
  paymentDataList: PaymentDataListItemDto[];
  recieverShortName: string;
  recieverShortNameErrorList: string[];
  repoRequestDataBrands: IDropdownOption[];
  repoRequestDataModels: IDropdownOption[];
  requestBrand: string;
  requestBrandCode: string;
  requestBrandErrorList: string[];
  requestCode: string;
  requestDataId?: number;
  requestModel: string;
  requestModelId: string;
  requestModelErrorList: string[];
  requestVin: string;
  requestVinErrorList: string[];
  supplierDocDate: string;
  supplierDocDateErrorList: string[];
  supplierDocDateStatus: number;
  supplierDocDateAddPercent: number;
  formPrefilling: TFormPrefilling;
  operationDataId: number;
}
export interface IAllCreateFormActions {
  actions: {
    set: Function;
    setForm: Function;
    resetForm: Function;
    resetErrors: Function;
    resetSupplierDocDateStatus: Function;
    resetRepoRequestDataBrands: Function;
    createRepoRequestData: Function;
    checkSupplierDocDate: Function;
    updateRepoRequestData: Function;
    getRequestCodeOptions: Function;
    getRepoRequestDataBrands: Function;
    getRepoRequestDataFinancingInfo: Function;
    setError: Function;
  };
}
export interface IAllCreateFormPropsExternal {
  requestDataId?: number;
  history: any;
  brand_type: string;
  form: 'create' | 'edit';
  backUrl: string;
}

function isDateBeforeTodayWithoutTime(date1: Date, date2: Date): boolean {
  const d1 = new Date(date1.getTime());
  const d2 = new Date(date2.getTime());
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);
  return d1.getTime() < d2.getTime();
}

function isDateBeforeToday(inputDate: string): boolean {
  return isDateBeforeTodayWithoutTime(new Date(inputDate), new Date());
}

const getVatAmountByPriceWithVAT = (priceWithVAT: string): number => {
  const priceWithVATNum = parseFloat(priceWithVAT);
  const newVatAmount = (priceWithVATNum / 1.2) * 0.2;
  return parseFloat(newVatAmount.toFixed(2));
};

const AddCarFormRepo = (
  props: IAllCreateFormProps & IAllCreateFormActions & IAllCreateFormPropsExternal
) => {
  const [currentBrand, setCurrentBrand] = useState<TBrand | null>(null);
  const [paymentDataIndex, setPaymentDataIndex] = useState<number>(-1);
  const [receiverPaymentData, setReceiverPaymentData] = useState<PaymentDataListItemDto | null>(
    null
  );
  const [isSupplierDocDateEdit, setSupplierDocDateEdit] = useState(false);

  let currentLimitAmount: number | undefined = props.currentLimitAmount;
  let priceWithVAT = parseFloat(props.priceWithVAT);
  if (isNaN(priceWithVAT)) priceWithVAT = 0;
  if (priceWithVAT && currentLimitAmount) currentLimitAmount -= priceWithVAT;

  const isEdit = () => props.form === 'edit';
  const getCurrentBrand: TODO_ANY = (brandCode: string) => {
    const result = props.repoRequestDataBrands.find((item: any) => item.value === brandCode);
    return result;
  };

  const getCurrentBrandCode = (brandLabel: string): string => {
    const brand = props.repoRequestDataBrands.find(
      (item: IDropdownOption) => item.label === brandLabel
    );
    return brand!.value;
  };

  const isLimitAmountExceeded = currentLimitAmount && currentLimitAmount <= 0;
  const limitFinishDate =
    props.lastFinancingRepoRequestDataFinancingInfoResponse?.data.limit_finish_date;
  const isLimitFinishDateExceeded = limitFinishDate && isDateBeforeToday(limitFinishDate);

  const hasErrors =
    !fp.isEmpty(props.requestBrandErrorList) ||
    !fp.isEmpty(props.requestModelErrorList) ||
    !fp.isEmpty(props.requestVinErrorList) ||
    !fp.isEmpty(props.originCountryErrorList) ||
    !fp.isEmpty(props.priceWithVATErrorList) ||
    !fp.isEmpty(props.vatAmountErrorList) ||
    !fp.isEmpty(props.accountNumberErrorList) ||
    !fp.isEmpty(props.accountNumberDateErrorList) ||
    !fp.isEmpty(props.deliveryLocationErrorList) ||
    !fp.isEmpty(props.supplierDocDateErrorList) ||
    !fp.isEmpty(props.paymentPurposeErrorList) ||
    props.supplierDocDateStatus === 2; // Делаем нестандартную валидацию из-за нестандартного сообщения об ошибке (крупнее)

  let brandCode: string | undefined;
  useEffect(() => {
    // let brandCode = null;
    if (isEdit()) {
      brandCode = props.requestBrandCode;
    } else if (props.formPrefilling) {
      brandCode = props.formPrefilling.requestBrandCode;
      props.actions.setForm({ requestBrand: props.formPrefilling.brand?.label });
      props.actions.setForm({ requestModel: props.formPrefilling.requestModel });
      props.actions.setForm({ requestModelId: props.formPrefilling.requestModelId });
      props.actions.setForm({ originCountry: props.formPrefilling.originCountry });
      props.actions.setForm({ deliveryLocation: props.formPrefilling.deliveryLocation });
      props.actions.setForm({ recieverShortName: props.formPrefilling.recieverShortName });
      setReceiverPaymentData(props.formPrefilling.receiverPaymentData!);
      setCurrentBrand(props.formPrefilling.brand!);
    }
    brandCode &&
      props.actions.getRepoRequestDataFinancingInfo({
        brandCode,
        onSuccess: (paymentDataList: PaymentDataListItemDto[]) => {
          if (isEdit()) {
            const result = fillReceiverPaymentData(props.recieverShortName, paymentDataList);
            if (result.paymentDataIndex >= 0) {
              setPaymentDataIndex(result.paymentDataIndex);
              setReceiverPaymentData(result.receiverPaymentData);
            }
            // if (isSupplierDocDateEdit) {
            //   onSupplierDocDateChange(props.supplierDocDate);
            // }
            onSupplierDocDateChange(props.supplierDocDate);
          }
        },
      });
    return () => {
      props.actions.resetForm();
      props.actions.resetErrors();
      props.actions.resetSupplierDocDateStatus();
      props.actions.resetRepoRequestDataBrands();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!props.repoRequestDataBrands?.length) {
      props.actions.getRepoRequestDataBrands({
        repoRequestTypeCode: getAutoType(props.history).toUpperCase(),
      });
    } else if (props.repoRequestDataBrands?.length === 1) {
      onBrandChange(props.repoRequestDataBrands[0].value, props.repoRequestDataBrands[0]); // Имитация выбора
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.repoRequestDataBrands]);

  if (isEdit()) {
    if (!currentBrand && props.repoRequestDataBrands?.length) {
      const currentBrand = getCurrentBrand(props.requestBrandCode);
      setCurrentBrand(currentBrand);
    }
  }

  const areAllPaymentPurposePropsReady = () =>
    !!(props.requestBrand && props.requestModel && props.requestVin && props.priceWithVAT);

  useEffect(() => {
    // Редактирование поля 'Назначение платежа'
    if (areAllPaymentPurposePropsReady()) {
      const paymentPurpose = `Оплата за а/м ${props.requestBrand} ${props.requestModel} ${
        props.requestVin
      } Сумма ${props.priceWithVAT} руб., в т.ч. НДС(20%) ${getVatAmountByPriceWithVAT(
        props.priceWithVAT
      )} рублей `;
      props.actions.setForm({
        paymentPurpose,
      });
    }
  }, [
    props.requestBrand,
    props.requestModel,
    props.requestVin,
    props.priceWithVAT,
    props.accountNumber,
    props.accountNumberDate,
  ]);

  useEffect(() => {
    const result = fillReceiverPaymentData(props.recieverShortName);
    if (result.paymentDataIndex >= 0) {
      setPaymentDataIndex(result.paymentDataIndex);
      setReceiverPaymentData(result.receiverPaymentData);
    }
  }, [props.recieverShortName]);

  function getReceiverOptions(paymentDataList: PaymentDataListItemDto[]) {
    return paymentDataList.map((item: PaymentDataListItemDto) => {
      const valueAndLabel = getReceiverOptionValueAndLabel(
        item.reciever_short_name,
        item.reciever_bank_name
      );
      return {
        value: valueAndLabel,
        label: valueAndLabel,
      };
    });
  }

  function getReceiverLabel(paymentDataList: PaymentDataListItemDto[], recieverShortName: string) {
    const paymentData: PaymentDataListItemDto | undefined = paymentDataList.find(
      (item) => item.reciever_short_name === recieverShortName
    );
    const result = `${paymentData?.reciever_short_name}, ${paymentData?.reciever_bank_name}`;
    return result;
  }

  useEffect(() => {
    const recieverOptions = getReceiverOptions(props.paymentDataList);
    if (props.recieverShortName) {
      const paymentDataIndex =
        recieverOptions &&
        recieverOptions.findIndex((item) => item.value === props.recieverShortName);
      // fillReceiverPaymentData();
      setPaymentDataIndex(paymentDataIndex);
      // setReceiverPaymentData(result.receiverPaymentData);
    }
  }, [props.paymentDataList]);

  const validators = {
    requestBrand: configureValidator({
      name: 'requestBrand',
      rule: brandRules,
      setError: props.actions.setError,
    }),
    requestModel: configureValidator({
      name: 'requestModel',
      rule: requestModelRules,
      setError: props.actions.setError,
    }),
    requestVin: configureValidator({
      name: 'requestVin',
      rule: requestVinRules,
      setError: props.actions.setError,
    }),
    originCountry: configureValidator({
      name: 'originCountry',
      rule: originCountryRules,
      setError: props.actions.setError,
    }),
    priceWithVAT: configureValidator({
      name: 'priceWithVAT',
      rule: priceWithVATRules(currentLimitAmount!),
      setError: props.actions.setError,
    }),
    vatAmount: configureValidator({
      name: 'vatAmount',
      rule: vatAmountRules,
      setError: props.actions.setError,
    }),
    accountNumber: configureValidator({
      name: 'accountNumber',
      rule: accountNumberRules,
      setError: props.actions.setError,
    }),
    accountNumberDate: configureValidator({
      name: 'accountNumberDate',
      rule: accountNumberDateRules,
      setError: props.actions.setError,
    }),
    supplierDocDate: configureValidator({
      name: 'supplierDocDate',
      rule: supplierDocDateRules,
      setError: props.actions.setError,
    }),
    deliveryLocation: configureValidator({
      name: 'deliveryLocation',
      rule: deliveryLocationRules,
      setError: props.actions.setError,
    }),
    recieverShortName: configureValidator({
      name: 'recieverShortName',
      rule: recieverShortNameRules,
      setError: props.actions.setError,
    }),
    paymentPurpose: configureValidator({
      name: 'paymentPurpose',
      rule: paymentPurposeRules,
      setError: props.actions.setError,
    }),
  };

  const saveRequestData = (values: any) => {
    if (props.form === 'create') {
      props.actions.createRepoRequestData(values);
    } else if (isEdit()) {
      props.actions.updateRepoRequestData(values);
    }
  };

  const findPaymentDataIndex = (
    recieverShortName: string,
    recieverOptionsLocal = getReceiverOptions(props.paymentDataList)
  ): number =>
    recieverOptionsLocal &&
    recieverOptionsLocal.findIndex((item) => item.value === recieverShortName);

  const fillReceiverPaymentData = (
    recieverShortName: string,
    paymentDataList: PaymentDataListItemDto[] = []
  ): { paymentDataIndex: number; receiverPaymentData: PaymentDataListItemDto | null } => {
    let paymentDataIndexLocal = -1;
    let receiverPaymentDataLocal: PaymentDataListItemDto | null = null;
    if (!!props.formPrefilling?.receiverPaymentData) {
      receiverPaymentDataLocal = props.formPrefilling.receiverPaymentData;
    } else {
      const paymentDataListLocal =
        paymentDataList.length !== 0 ? paymentDataList : props.paymentDataList;
      if (paymentDataListLocal.length !== 0) {
        if (paymentDataIndex >= 0) {
          paymentDataIndexLocal = paymentDataIndex;
        } else {
          // При редактировании
          const recieverOptionsLocal = getReceiverOptions(paymentDataListLocal);
          if (recieverOptionsLocal?.length && recieverShortName) {
            paymentDataIndexLocal = findPaymentDataIndex(recieverShortName, recieverOptionsLocal);
          }
        }
        if (paymentDataIndexLocal >= 0) {
          receiverPaymentDataLocal = {
            reciever_inn: paymentDataListLocal[paymentDataIndexLocal].reciever_inn,
            payer_account_number: paymentDataListLocal[paymentDataIndexLocal].payer_account_number,
            reciever_short_name: paymentDataListLocal[paymentDataIndexLocal].reciever_short_name,
            reciever_account_number:
              paymentDataListLocal[paymentDataIndexLocal].reciever_account_number,
            reciever_bic_bank: paymentDataListLocal[paymentDataIndexLocal].reciever_bic_bank,
            reciever_loro_account:
              paymentDataListLocal[paymentDataIndexLocal].reciever_loro_account,
            reciever_bank_name: paymentDataListLocal[paymentDataIndexLocal].reciever_bank_name,
          };
        }
      }
    }
    const result =
      paymentDataIndexLocal >= 0
        ? { paymentDataIndex: paymentDataIndexLocal, receiverPaymentData: receiverPaymentDataLocal }
        : { paymentDataIndex: -1, receiverPaymentData: null };
    return result;
  };

  const handleSubmit = () => {
    const receiverPaymentDataLocal = props.paymentDataList[paymentDataIndex];
    const typeCode = getAutoType(props.history).toUpperCase();
    let calculatedPercent = props.supplierDocDateAddPercent;
    switch (props.supplierDocDateStatus) {
      case 0:
        calculatedPercent = typeCode === 'NEW' ? props.advancePercent : props.advancePercentDemo;
        break;
      case 1:
        if (typeCode === 'NEW') calculatedPercent += props.advancePercent;
        break;
      case 2:
      default:
        break;
    }

    const rules = [
      validators.accountNumberDate(props.accountNumberDate),
      validators.accountNumber(props.accountNumber),
      validators.requestBrand(props.requestBrand),
      validators.deliveryLocation(props.deliveryLocation),
      validators.requestModel(props.requestModel),
      validators.originCountry(props.originCountry),
      validators.priceWithVAT(props.priceWithVAT),
      validators.recieverShortName(props.recieverShortName),
      validators.requestVin(props.requestVin),
      validators.supplierDocDate(props.supplierDocDate),
    ];

    runRulesChain(rules)
      .then(() => {
        const sendData: TSentData = {
          advance_percent: parseFloat(calculatedPercent.toFixed(2)),
          brand_name: props.requestBrand,
          delivery_location: props.deliveryLocation,
          model_name: props.requestModel,
          model_id: props.requestModelId,
          request_vin: props.requestVin,
          origin_country: props.originCountry,
          price_with_vat: props.priceWithVAT,
          vat_amount: JSON.stringify(getVatAmountByPriceWithVAT(props.priceWithVAT)),
          account_number: props.accountNumber,
          account_date: props.accountNumberDate,
          supplier_doc_date: props.supplierDocDate,
          payment_purpose: props.paymentPurpose,
          // TODO Разобратья с дублированием значения
          reciever: receiverPaymentDataLocal.reciever_short_name,
          reciever_short_name: receiverPaymentDataLocal.reciever_short_name,
          repo_request_type_code: typeCode,
          request_data_id: props.requestDataId,
          brand_code: currentBrand!.value,
          multi_brand: currentBrand!.isMultiBrand,
          payer_account_number: receiverPaymentDataLocal.payer_account_number,
          reciever_inn: receiverPaymentDataLocal.reciever_inn,
          reciever_account_number: receiverPaymentDataLocal.reciever_account_number,
          reciever_bic_bank: receiverPaymentDataLocal.reciever_bic_bank,
          reciever_loro_account: receiverPaymentDataLocal.reciever_loro_account,
          reciever_bank_name: receiverPaymentDataLocal.reciever_bank_name,
          is_check: 0, // На клиенте так
        };
        const result = saveRequestData({
          requestDataId: props.requestDataId, // TODO для создания не нужен ?
          data: sendData,
          brand_type: props.brand_type,
          onSuccess: () => {
            props.history.push(props.backUrl);
          },
        });
        return result;
      })
      .catch(fp.noop);
  };

  const onBrandChange = (brandCode: string, option: any): void => {
    const currentBrand = getCurrentBrand(brandCode);
    setCurrentBrand(currentBrand);
    if (props.requestBrandCode != option.value)
      // Сброс значений, зависящих от марки авто
      validators.requestBrand(brandCode).finally((): void => {
        props.actions.setForm({
          requestBrand: option.label,
          requestBrandCode: option.value,
          requestModel: '',
          requestModelId: null,
          requestVin: '',
          priceWithVAT: '',
          deliveryLocation: '',
          recieverShortName: '',
        });
        //setVatAmount(null);
        setPaymentDataIndex(-1);
        props.actions.resetErrors();
      });
    props.actions.getRepoRequestDataFinancingInfo({
      brandCode,
    });
  };

  const onModelChange = (model: string, option: any): void => {
    validators.requestModel(model).finally((): void =>
      props.actions.setForm({
        requestModel: option.label,
        requestModelId: option.value,
      })
    );
  };

  const onVinChange = (requestVin: string): void => {
    validators.requestVin(requestVin).finally((): void => props.actions.setForm({ requestVin }));
  };

  const onOriginCountryChange = (originCountry: string): void => {
    validators.originCountry(originCountry);
    props.actions.setForm({ originCountry });
  };

  const onPriceWithVATChange = (priceWithVAT: string): void => {
    props.actions.setForm({ priceWithVAT });
  };

  const onVatAmountChange = (vatAmount: string): void => {
    validators.vatAmount(vatAmount).finally((): void => props.actions.setForm({ vatAmount }));
    props.actions.setForm({ vatAmount });
  };

  const onAccountNumberChange = (accountNumber: string): void => {
    validators
      .accountNumber(accountNumber)
      .finally((): void => props.actions.setForm({ accountNumber }));
  };

  const onAccountNumberDateChange = (value: string): void => {
    validators
      .accountNumberDate(value)
      .finally((): void => props.actions.setForm({ accountNumberDate: value }));
  };

  const onSupplierDocDateChange = (supplierDocDate: string): void => {
    validators.supplierDocDate(supplierDocDate).finally((): void => {
      props.actions.setForm({ supplierDocDate });
      setSupplierDocDateEdit(true);
      props.actions.checkSupplierDocDate({
        repoRequestTypeCode: getAutoType(props.history).toUpperCase(),
        supplierDocDate,
        brandCode: props.requestBrandCode ?? getCurrentBrandCode(props.requestBrand),
        operationDataId: props.operationDataId,
      });
    });
  };

  const onDeliveryLocationChange = (deliveryLocation: string, option: any): void => {
    validators.deliveryLocation(deliveryLocation).finally((): void =>
      props.actions.setForm({
        deliveryLocation: option.label,
      })
    );
  };

  const onRecieverShortNameChange = (recieverShortName: string): void => {
    validators.recieverShortName(recieverShortName).finally((): void => {
      props.actions.setForm({
        recieverShortName,
      });
    });
    const newPaymentDataIndex = findPaymentDataIndex(recieverShortName);
    setPaymentDataIndex(newPaymentDataIndex);
  };

  function onPaymentPurposeChange(event: TODO_ANY) {
    event.preventDefault();
    const paymentPurpose = event.target.value;
    validators.paymentPurpose(paymentPurpose).finally((): void => {
      console.log(`AddCarFormRepo/onPaymentPurposeChange/validators/finally`);
    });
    props.actions.setForm({ paymentPurpose });
  }

  function formatDate(dateStr: string): string {
    const parts = dateStr.split('-'); // Разделяем строку на части
    return `${parts[2]}.${parts[1]}.${parts[0]}`; // Переставляем и соединяем
  }

  function isReceiverPaymentDataReady(): boolean {
    const result = !!props.paymentDataList.length && paymentDataIndex >= 0;
    return result;
  }
  let vatAmount = getVatAmountByPriceWithVAT(props.priceWithVAT);
  const strVatAmount: string = isNaN(vatAmount) ? '' : vatAmount.toString();
  return (
    <div>
      <div className={styles.form} role="form">
        <div aria-label="form field">
          <Condition value={props.repoRequestDataBrands?.length > 1}>
            <div className={isEdit() ? styles.disabled : ''}>
              <Dropdown
                label="Марка"
                value={props.requestBrand}
                options={props.repoRequestDataBrands}
                hasError={!fp.isEmpty(props.requestBrandErrorList)}
                onChange={onBrandChange}
              />
            </div>
          </Condition>
          <Condition value={props.repoRequestDataBrands?.length <= 1}>
            <Text
              label="Марка"
              content={props.repoRequestDataBrands && props.repoRequestDataBrands[0]?.label}
            />
          </Condition>
          <Condition value={!!props.requestBrand}>
            <div style={{ marginTop: '7px', marginBottom: '5px' }}>
              <div
                style={{
                  marginBottom: '2px',
                  color: `${isLimitAmountExceeded ? 'red' : 'var(--brand-color-black)'}`,
                  fontWeight: '500',
                }}
              >
                {`Доступный лимит - ${getTransformedAmount(
                  isLimitAmountExceeded ? 0 : currentLimitAmount ?? 0
                ).replace(',', '.')} руб`}
              </div>
              <Condition value={!!limitFinishDate && !isLimitFinishDateExceeded}>
                <div
                  style={{
                    color: 'var(--brand-color-black',
                    fontWeight: '500',
                  }}
                >
                  {`Срок доступности лимита - ${limitFinishDate}`}
                </div>
              </Condition>
            </div>
          </Condition>
          <MessageList type="error" messages={props.requestBrandErrorList} />
        </div>

        <Condition value={!!props.requestBrand}>
          <div aria-label="form field">
            <Dropdown
              label="Модель"
              value={props.requestModel}
              options={props.repoRequestDataModels ?? []}
              hasError={!fp.isEmpty(props.requestModelErrorList)}
              onChange={onModelChange}
            />
            <MessageList type="error" messages={props.requestModelErrorList} />
          </div>
        </Condition>

        <div aria-label="form field">
          <Input
            disabled={!props.requestBrand}
            keyUnique="requestVin"
            type="vin-number"
            label="VIN автомобиля"
            name="requestVin"
            value={props.requestVin}
            hasError={!fp.isEmpty(props.requestVinErrorList)}
            onChange={onVinChange}
          />
          <MessageList type="error" messages={props.requestVinErrorList} />
        </div>

        <div aria-label="form field">
          <Input
            disabled={!props.requestBrand}
            keyUnique="originCountry"
            type="text"
            label="Страна происхождения Автомобиля"
            name="originCountry"
            value={props.originCountry}
            hasError={!fp.isEmpty(props.originCountryErrorList)}
            onChange={onOriginCountryChange}
          />
          <MessageList type="error" messages={props.originCountryErrorList} />
        </div>
        <div aria-label="form field">
          <Input
            disabled={!props.requestBrand}
            type="money"
            keyUnique="priceWithVAT"
            label="Цена Автомобиля с НДС (руб)"
            name="priceWithVAT"
            value={props.priceWithVAT}
            hasError={!fp.isEmpty(props.priceWithVATErrorList)}
            onChange={onPriceWithVATChange}
          />
          <MessageList type="error" messages={props.priceWithVATErrorList} />
        </div>

        <div
          aria-label="form field"
          style={{
            opacity: !props.requestBrand ? 0.5 : 1,
            pointerEvents: !props.requestBrand ? 'none' : 'auto',
          }}
        >
          <MoneyInput title="НДС, (руб)" initialValue={strVatAmount} onChange={onVatAmountChange} />
          <MessageList type="error" messages={props.vatAmountErrorList} />
        </div>

        <div aria-label="form field">
          <Input
            disabled={!props.requestBrand}
            keyUnique="accountNumber"
            type="text"
            label="Номер счёта Официального дилера"
            name="accountNumber"
            value={props.accountNumber}
            hasError={!fp.isEmpty(props.accountNumberErrorList)}
            onChange={onAccountNumberChange}
          />
          <MessageList type="error" messages={props.accountNumberErrorList} />
        </div>

        <div
          aria-label="form field"
          style={{
            opacity: !props.requestBrand ? 0.5 : 1,
            pointerEvents: !props.requestBrand ? 'none' : 'auto',
          }}
        >
          <div className={styles.dateField}>
            <div
              style={{
                marginTop: '30px',
                opacity: '0.4',
              }}
            >
              Дата счёта Официального дилера
            </div>
            <DatePickerLargeTextSize
              wrapperClassName={styles.dateFieldPicker}
              value={props.accountNumberDate}
              onChange={onAccountNumberDateChange}
            />
          </div>
          <MessageList type="error" messages={props.accountNumberDateErrorList} />
        </div>

        <div
          aria-label="form field"
          style={{
            opacity: !props.requestBrand ? 0.5 : 1,
            pointerEvents: !props.requestBrand ? 'none' : 'auto',
          }}
        >
          <div className={styles.dateField}>
            <div
              style={{
                marginTop: '30px',
                opacity: '0.4',
              }}
            >
              Дата документов Поставщика
            </div>
            <DatePickerLargeTextSize
              wrapperClassName={styles.dateFieldPicker}
              value={props.supplierDocDate}
              onChange={onSupplierDocDateChange}
            />
          </div>
          <Condition value={props.supplierDocDateStatus !== 0}>
            <div style={{ marginTop: '7px', marginBottom: '5px' }}>
              <div
                style={{
                  marginBottom: '2px',
                  color: 'red',
                  fontWeight: '500',
                }}
              >
                {props.supplierDocDateStatus === 1
                  ? `Размер аванса увеличен на ${props.supplierDocDateAddPercent}%`
                  : 'Финансирование невозможно. Превышена дата окончания доступности коммерческого кредита'}
              </div>
            </div>
          </Condition>
          <MessageList type="error" messages={props.supplierDocDateErrorList} />
        </div>

        <Condition value={!!props.requestBrand}>
          <div aria-label="form field">
            <Dropdown
              label="Адрес места доставки"
              value={props.deliveryLocation}
              options={props.deliveryLocations ?? []}
              hasError={!fp.isEmpty(props.deliveryLocationErrorList)}
              onChange={onDeliveryLocationChange}
            />
            <Condition value={!fp.isEmpty(props.deliveryLocationErrorList)}>
              <MessageList type="error" messages={props.deliveryLocationErrorList} />
            </Condition>
            <div style={{ marginTop: '5px' }}>
              Если адреса места доставки нет в списке, свяжитесь с ООО "РУСФИНАНС".
            </div>
          </div>

          <div style={{ marginTop: '40px' }} aria-label="form field">
            <Dropdown
              label="Конечный получатель"
              value={props.recieverShortName ? props.recieverShortName : ''}
              options={getReceiverOptions(props.paymentDataList)}
              hasError={!fp.isEmpty(props.recieverShortNameErrorList)}
              onChange={onRecieverShortNameChange}
            />
            <Condition value={!fp.isEmpty(props.apiErrorList)}>
              <MessageList type="error" messages={props.recieverShortNameErrorList} />
            </Condition>
          </div>
          {isReceiverPaymentDataReady() && (
            <div style={{ marginTop: '5px' }}>
              <div>
                {`ИНН получателя ${props.paymentDataList[paymentDataIndex].reciever_inn}`}
                <br />
                {`Расчётный счёт получателя ${props.paymentDataList[paymentDataIndex].reciever_account_number}`}
                <br />
                {`Наименование банка получателя ${props.paymentDataList[paymentDataIndex].reciever_bank_name}`}
                <br />
                {`БИК банка получателя ${props.paymentDataList[paymentDataIndex].reciever_bic_bank}`}
                <br />
                {`К/с банка получателя ${props.paymentDataList[paymentDataIndex].reciever_loro_account}`}
                <br />
              </div>
              <div style={{ marginTop: '5px', fontWeight: '500', color: 'red' }}>
                Если платёжные реквизиты некорректны или получателя нет в списке, свяжитесь с ООО
                "РУСФИНАНС".
              </div>
            </div>
          )}
        </Condition>

        <div aria-label="form field" style={{ marginTop: '30px' }}>
          <div
            style={{
              top: '25px',
              fontSize: '11px',
              textTransform: 'uppercase',
            }}
          >
            Назначение платежа
          </div>

          <textarea
            className={styles.paymentPurpose}
            rows={3}
            name="paymentPurpose"
            value={props.paymentPurpose}
            onChange={onPaymentPurposeChange}
          />

          <Condition value={!fp.isEmpty(props.paymentPurposeErrorList)}>
            <MessageList type="error" messages={props.paymentPurposeErrorList} />
          </Condition>
          <div style={{ marginTop: '5px' }}>
            При необходимости отредактируйте назначение платежа
          </div>
        </div>

        <Button
          isDisabled={props.isLoading || !props.requestBrand || hasErrors}
          wrapperClassName={styles.button}
          type="submit"
          text="Сохранить"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default AddCarFormRepo;
