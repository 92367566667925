import { connect } from 'react-redux';

import Component, { IAppGuestActions, IAppGuestProps } from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actions as appActions } from 'modules/app/store';

const mapStateToProps = (state: TODO_ANY): IAppGuestProps => ({
  state,
});

const mapDispatchToProps = (dispatch: any): IAppGuestActions => ({
  actions: bindActionCreators(appActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
