import React, { useEffect } from 'react';

import { Alert, Button, Html } from '@rfb/ui-kit';

import styles from './assets/styles/index.module.css';

export interface IAuthServiceTermsProps {
  text: string;
}

export interface IAuthServiceTermsActions {
  actions: {
    getServiceTermsText: Function,
    sendServiceTermsAgreement: Function,
    disagreeServiceTerms: Function,
  };
}

export interface IAuthServiceTermsPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void,
  };
}
const Agreement = (
  props: IAuthServiceTermsProps & IAuthServiceTermsPropsExternal & IAuthServiceTermsActions
) => {
  useEffect(() => {
    if (props.text) return;
    props.actions.getServiceTermsText();
    // ESLINT Необходимо для отправки запроса только в момент монтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAgreeClick = (): void =>
    props.actions.sendServiceTermsAgreement({ history: props.history });
  const onDisagreeClick = (): void =>
    props.actions.disagreeServiceTerms({ history: props.history });

  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <Html text={props.text} />
      </div>

      <div className={styles.alert}>
        <Alert>
          <div className={styles.buttons}>
            <Button wrapperClassName={styles.buttonAgree} text="Принять" onClick={onAgreeClick} />
            <Button
              wrapperClassName={styles.butonDisagree}
              text="Отменить"
              onClick={onDisagreeClick}
            />
          </div>
        </Alert>
      </div>
    </div>
  );
};

export default Agreement;
