import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { getTransformedAmount } from '@rfb/common';

import { TranchePayment } from '../../configs/payment';

import { name, actions, selectors, getPaymentListFromDB, ITrancheState } from '../../store';

import Component, { ITableOrderableProps, ITableOrderableActions } from './component';

import { calculateTrancheRepaymentAmount } from '../../helpers/amount';

const mapStateToProps = (state: { [name]: ITrancheState }): ITableOrderableProps => ({
  headers: [
    { value: 'vin', title: 'VIN' },
    { value: 'sum', title: 'Сумма финансирования / предоплаты' },
    { value: 'repayment_sum', title: 'Сумма для погашения' },
  ],
  data: fp.map(
    (tranche: ITrancheState['paymentList'][number]): [string, string, string] => [
      tranche.vin,
      getTransformedAmount(
        fp.isEqual(tranche.payment_type, TranchePayment.PRE)
          ? tranche.sum_pre_pay
          : tranche.sum_amount
      ),
      getTransformedAmount(parseFloat(calculateTrancheRepaymentAmount(tranche).toString())),
    ],
    selectors.selectPaymentList()
  ),
  orderList: selectors.selectPaymentOrderList(state),
  isTrancheRepaymentCompleted: selectors.selectIsTrancheRepaymentCompleted(state),
});

const mapDispatchToProps = (dispatch: any): ITableOrderableActions => ({
  actions: bindActionCreators(actions, dispatch),
  getPaymentListFromDB,
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
