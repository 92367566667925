export const clientLines_GET_ResponseBody = {
  records: [
    {
      number: 1358,
      type: 'FL2',
      status: 0,
      name_brand: 'Kia',
      name_product: 'Новые машины',
    },
  ],
  exception: {
    code: 1123,
    message_rf: null,
    message_rb: null,
  },
};
