var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import Table from '../Table';
import ArrowDecrementIcon from '../../icons/ArrowOpened';
import ArrowIncrementIcon from '../../icons/ArrowClosed';
import styles from './assets/styles/index.module.css';
export var TableOrderable = function (props) {
    var orderList = !fp.isEmpty(props.orderList) ? props.orderList : fp.range(0, fp.size(props.rows));
    var isFirstElement = function (order) { return fp.isEqual(order, fp.head(orderList)); };
    var isLastElement = function (order) { return fp.isEqual(order, fp.last(orderList)); };
    var changeOrder = function (orderValue, step) {
        var orderListCopy = __spreadArrays(orderList);
        var index = fp.findIndex(fp.isEqual(orderValue), orderListCopy);
        orderListCopy[index] = orderListCopy[index + step];
        orderListCopy[index + step] = orderValue;
        return props.onOrderChange(orderListCopy);
    };
    var onOrderIncrement = function (orderValue) { return function () {
        return !isLastElement(orderValue) ? changeOrder(orderValue, 1) : fp.noop;
    }; };
    var onOrderDecrement = function (orderValue) { return function () {
        return !isFirstElement(orderValue) ? changeOrder(orderValue, -1) : fp.noop;
    }; };
    var renderIndexCell = function (orderValue) {
        var _a, _b;
        var decrementClassNames = cn((_a = {},
            _a[styles.arrow] = true,
            _a[styles.arrowDisabled] = isFirstElement(orderValue),
            _a));
        var incrementClassNames = cn((_b = {},
            _b[styles.arrow] = true,
            _b[styles.arrowDisabled] = isLastElement(orderValue),
            _b));
        return React.createElement("div", { className: styles.cellIndex },
            React.createElement("span", { className: styles.arrowWrapper, onClick: onOrderDecrement(orderValue) },
                React.createElement(ArrowDecrementIcon, { className: decrementClassNames })),
            React.createElement("div", { className: styles.index }, fp.findIndex(fp.isEqual(orderValue), orderList) + 1),
            React.createElement("span", { className: styles.arrowWrapper, onClick: onOrderIncrement(orderValue) },
                React.createElement(ArrowIncrementIcon, { className: incrementClassNames })));
    };
    var addIndexCellToRow = function (index, list) { return ({
        data: __spreadArrays([renderIndexCell(index)], list),
    }); };
    var getRowsWithIndexCell = function () {
        return fp.map(function (orderIndex) {
            var rowByIndex = fp.nth(orderIndex)(props.rows);
            return addIndexCellToRow(orderIndex, rowByIndex);
        }, orderList);
    };
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: styles.main },
            React.createElement(Table, { headers: __spreadArrays([{ value: 'priority', title: 'Приоритет' }], props.headers), rows: getRowsWithIndexCell(), onSortingChange: fp.noop, onPageChange: fp.noop, page: 0, pageCount: 0 }))));
};
export default TableOrderable;
