import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import Component, {
  FTButtonCellActions,
  FTButtonCellExternal,
  FTButtonCellProps,
} from './component';
import { actions, IFinancialState, name, selectors } from 'modules/financial-block/store';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: {
    history: TODO_ANY;
    requestDataId: number;
    brand_type: string;
    state?: string;
    isActive: boolean;
  }
): FTButtonCellProps & FTButtonCellExternal => {
  return {
    record: selectors.selectRequestDataById(state)(props.requestDataId),
    filter: selectors.selectFilter(state),
    requestDataId: props.requestDataId,
    history: props.history,
    brand_type: props.brand_type,
    isActive: props.isActive,
  };
};
const mapDispatchToProps = (dispatch: any): FTButtonCellActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
