import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { IDTORFInfoAccountsResponse } from '../../../../dto/rf-info';

import { actions, IAccountsState, name, selectors } from '../../store';

import Component, { IAccountsSelectActions, IAccountsSelectProps } from './component';

const getLabel = (props: IDTORFInfoAccountsResponse) =>
  `${props.account} - ${props.close_date ? 'закрыт' : 'открыт'}`;

const mapStateToProps = (state: { [name]: IAccountsState }): IAccountsSelectProps => ({
  optionList: (selectors.selectAccounts(state) || []).map(
    (account: IDTORFInfoAccountsResponse) => ({ label: getLabel(account), value: account.account })
  ),
  accounts: selectors.selectAccounts(state),
  account: selectors.selectCurrentId(state),
  filter: selectors.selectFilter(state),
  isLoading: selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: any): IAccountsSelectActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
