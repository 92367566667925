import React, { useState } from 'react';

import { Alert, Button, Condition } from '@rfb/ui-kit';

import storage from 'utils/storage';
import styles from './assets/styles/index.module.css';

export const awaitingSigningStorageKey: string = 'awaitingSigningStorageKey';

const ReqAwaitingSigning = () => {
  const [hidden, setHidden] = useState(false);
  const awaitingSigningStorageKeyValue: any = storage.get(awaitingSigningStorageKey);

  const onButtonClick = (): void => {
    storage.set(awaitingSigningStorageKey, true);
    setHidden(true); // TODO Проверить на избыточность, почему недостаточно awaitingSigningStorageKey?
  };

  return (
    <Condition value={!hidden}>
      <Alert>
        <div className={styles.content}>
          <div className={styles.textWrapper}>
            <div className={styles.text}>
              В Журнале операций РУСФИНАНС есть заявки, ожидающие подписания
            </div>
          </div>
          <div className={styles.button}>
            <Button text="OK" onClick={onButtonClick} />
          </div>
        </div>
      </Alert>
    </Condition>
  );
};

export default ReqAwaitingSigning;
