import { bindActionCreators } from '@reduxjs/toolkit';
import { getTransformedAmount } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { ITable } from '@rfb/ui-kit';
import { RepoRequestDataDTO } from 'dto/financial-block';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';
import { IFinancialState, actions, name, selectors } from '../../store';
import styles from './assets/styles/index.module.css';
import Component, {
  IFTTableRepoActions,
  IFTTableRepoExternal,
  IFTTableRepoProps,
} from './component';
import { getFTButtonCellRepo } from './ftbutton-cell';

type TFTTableHeader = ITable['headers'][number];

type TFTTableColumn = {
  header: TFTTableHeader;
  transform?: (value: any, row?: RepoRequestDataDTO['records'][number]) => any;
};

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: {
    history: TODO_ANY;
    brand_type?: string;
    state?: string;
    codeType?: string;
  }
): IFTTableRepoProps & IFTTableRepoExternal => {
  const filter = selectors.selectFilter(state);
  const requests: RepoRequestDataDTO['records'] = selectors.selectRepoRequestData(state);

  const columns: TFTTableColumn[] = [
    {
      header: {
        value: '',
        title: '',
        isFixed: true,
        columnClassName: styles.columnControls,
      },
      transform: getFTButtonCellRepo,
    },
    {
      header: {
        value: 'brand_name',
        title: 'Марка',
        isFixed: true,
        columnClassName: styles.columnBrand,
      },
    },
    {
      header: {
        value: 'model_name',
        title: 'Модель',
        isFixed: true,
        columnClassName: styles.columnModel,
      },
    },
    {
      header: {
        value: 'request_vin',
        title: 'VIN',
        isFixed: true,
        columnClassName: styles.columnVin,
      },
    },
    {
      header: {
        value: 'origin_country',
        title: 'Страна происхожд. а/м',
        isFixed: true,
        // columnClassName: styles.columnRequestCarPassportDate,
      },
    },
    {
      header: {
        value: 'price_with_vat',
        title: 'Цена а/м с НДС, руб.',
        isFixed: true,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'vat_amount',
        title: 'НДС, руб.',
        isFixed: true,
      },
      transform: getTransformedAmount,
    },
    {
      header: {
        value: 'account_number',
        title: '№ счёта',
        isFixed: true,
        // columnClassName: styles.columnEnginePower,
      },
    },
    {
      header: {
        value: 'account_date',
        title: 'Дата счёта',
        isFixed: true,
        // columnClassName: styles.columnMileage,
      },
    },
    {
      header: {
        value: 'supplier_doc_date',
        title: 'Дата док. поставщика',
      },
    },
    {
      header: {
        value: 'delivery_location',
        title: 'Адрес места доставки',
      },
    },
    {
      header: {
        value: 'reciever',
        title: 'Конечный получатель',
      },
    },
    {
      header: {
        value: 'payment_purpose',
        title: 'Назначение платежа',
      },
    },
  ];

  const rowDataList = fp.pipe(
    fp.map((row: RepoRequestDataDTO['records'][number]) =>
      fp.map(
        (column: TFTTableColumn) =>
          fp.isFunction(column.transform)
            ? column.transform(fp.path(column.header.value, row), row)
            : fp.path(column.header.value, row),
        columns
      )
    ),
    fp.map((data) => ({ data }))
  )(requests);

  const rowConfigList = fp.pipe(fp.map((config) => ({ config })))(requests);
  const operationDataId = selectors.selectOperationDataId(state);

  return {
    headers: fp.map(fp.path('header'), columns),
    rows: fp.pipe(fp.zip(rowConfigList), fp.map(fp.mergeAll))(rowDataList),
    pageCount: selectors.selectPageCount(state),
    filter,
    isLoading: selectors.selectIsLoading(state),
    isFinancingRequestInProgress: selectors.selectIsFinancingRequestInProgress(state),
    totalSum: selectors.selectTotalSum(state),
    advanceSum: selectors.selectAdvanceSum(state),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    isReadOnly: storage.get(readOnlyRoleKey),
    operationDataId,
    isLimitRequestChangeNeed: selectors.selectIsLimitRequestChangeNeed(state),
    isRepoApplyCompleted: selectors.selectIsRepoApplyCompleted(state),
    attachments: selectors.selectRepoAttachments(state),
    uploadedRepoAttachments: selectors.selectUploadedRepoAttachments(state),
    generatedRepoAttachments: selectors.selectGeneratedRepoAttachments(state),
    currentRepoCodeType: selectors.selectCurrentRepoCodeType(state),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: TODO_ANY): IFTTableRepoActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
