import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actions, INotificationState, name, selectors } from '../../store';
import Component, {
  INotificationViewActions,
  INotificationViewProps,
  INotificationViewPropsExternal,
} from './component';
import { TNotificationView } from '../../../../dto/notification';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';

const mapStateToProps = (
  state: { [name]: INotificationState },
  props: INotificationViewPropsExternal
): INotificationViewProps & INotificationViewPropsExternal => {
  const notification: TNotificationView = selectors.selectCurrentNotification(state);
  const filter: INotificationState['filter'] = selectors.selectFilter(state);

  return {
    id: props.id,
    notification_date: notification.notification_date,
    notificationDealerSystem: props.notificationDealerSystem,
    sender: notification.sender,
    subject: notification.subject,
    message: notification.message,
    attachments: notification.attachments,
    isReadOnly: storage.get(readOnlyRoleKey),
    inOutDirection: filter.inOutDirection,
    isLoading: selectors.selectIsLoading(state),
    errors: selectors.selectErrorListByField(state)('api'),
  };
};

const mapDispatchToProps = (dispatch: any): INotificationViewActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
