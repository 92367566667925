import { connect } from 'react-redux';

import { IDTORFInfoAccountResponse } from '../../../../../../dto/rf-info';

import { name, selectors } from '../../../../store';

import Component, {
  IAccountsBalanceProps,
} from './component';

const mapStateToProps = (state: { [name]: IDTORFInfoAccountResponse }): IAccountsBalanceProps => ({
  value: selectors.selectCurrentAccountData(state).rest,
});

export default connect(mapStateToProps)(Component);
