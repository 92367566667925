import React from 'react';

import { Link, AuthTemplate } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

const Error422 = () => {
  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.title}>
        <h2>Возможно произошла ошибка,</h2>
        <h2>повторите попытку</h2>
      </div>

      <div className={styles.buttons}>
        <Link title="Вернуться на главную страницу" to={routingConfig.login.path} />
      </div>
    </div>
  );

  return <AuthTemplate content={renderContent()} />;
};

export default Error422;
