import { createSlice } from '@reduxjs/toolkit';

import { getStateFromParams } from 'helpers/store';

import { IDTOProfileViewResponse } from 'dto/profile';
import { IDTORFInfoDealerResponse } from 'dto/rf-info';

export interface IUserState {
  profile?: IDTOProfileViewResponse;
  dealer?: IDTORFInfoDealerResponse;
  form: {
    phoneNumber: string;
    passwordOld: string;
    passwordNew: string;
    passwordNewRepeat: string;
  };
  errorList: { [key: string]: string[] };
  isLoading?: boolean;
}

const initialState: IUserState = {
  profile: undefined,
  dealer: undefined,
  errorList: {},
  form: {
    phoneNumber: '',
    passwordOld: '',
    passwordNew: '',
    passwordNewRepeat: '',
  },
  isLoading: false,
};

const userSlice = createSlice({
  name: 'user',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setForm: (state, action) => ({ ...state, form: { ...state.form, ...action.payload } }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    reset: (state) => ({
      ...initialState,
      profile: state.profile,
      dealer: state.dealer,
    }),

    getProfile: getStateFromParams,
    getProfileSuccessful: getStateFromParams,
    getProfileFailure: getStateFromParams,

    getDealer: getStateFromParams,
    getDealerSuccessful: getStateFromParams,
    getDealerFailure: getStateFromParams,

    changePassword: getStateFromParams,
    changePasswordSuccessful: getStateFromParams,
    changePasswordFailure: getStateFromParams,

    changePhoneNumber: getStateFromParams,
    changePhoneNumberSuccessful: getStateFromParams,
    changePhoneNumberFailure: getStateFromParams,

    changeCodeWord: getStateFromParams,
    changeCodeWordSuccessful: getStateFromParams,
    changeCodeWordFailure: getStateFromParams,

    downloadStatement: getStateFromParams,
  },
});

export const { name, actions, reducer } = userSlice;
