import React from 'react';
import DropDownConfig from './DropDownConfig';

interface TMenuElement {
  title: string;
  file_id: number;
  request_data_id: number;
}
interface DropDownCellProps {
  items: TMenuElement[];
}

export const DropDownCell = (props: DropDownCellProps) => {
  return (
    <DropDownConfig
      config={[
        {
          title: props.items.length
            ? `${props.items[0]}` + (props.items.length > 1 ? ', ...' : '')
            : '',
          items: props.items.map((item) => ({
            title: item.title,
            file_id: item.file_id,
            request_data_id: item.request_data_id,
          })),
        },
      ]}
    />
  );
};
