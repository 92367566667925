import React from 'react';
import fp from 'lodash/fp';

import { Button, Condition, Dropdown, Form, Input, MessageList } from '@rfb/ui-kit';
import styles from './assets/styles/index.module.css';

interface IFeedbackFormProps {
  options: { value: string, label: string }[];
  subject: string;
  optionErrorList: string[];
  email: string;
  phone: string;
  phoneErrorList: string[];
  contractNumber: string;
  message: string;
  messageErrorList: string[];
  apiErrorList: string[];
  dealerName: string;
  isFeedbackSending: boolean;
  onSubjectChange: (subject: string) => void,
  onPhoneChange: (phone: string) => void,
  onContractNumberChange: (contractNumber: string) => void,
  onMessageChange: (message: string) => void,
  onFormSubmit: () => void,
}

export const FeedbackForm = (props: IFeedbackFormProps) => (
  <div className={styles.main}>
    <h1 className={styles.title}>
      Если у вас есть вопрос или предложение, можете отправить нам сообщение
    </h1>

    <Form onSubmit={props.onFormSubmit}>
      <Dropdown
        wrapperClassName={styles.subject}
        label='Тема сообщения'
        options={props.options}
        value={props.subject}
        hasError={!fp.isEmpty(props.optionErrorList)}
        onChange={props.onSubjectChange}
      />
      <MessageList type='error' messages={props.optionErrorList} />

      <Input
        wrapperClassName={styles.email}
        label='E-mail'
        value={props.email}
        isStatic
        onChange={fp.noop}
      />

      <Input
        wrapperClassName={styles.phone}
        type='phone'
        label='Номер телефона'
        value={props.phone}
        hasError={!fp.isEmpty(props.phoneErrorList)}
        onChange={props.onPhoneChange}
      />
      <MessageList type='error' messages={props.phoneErrorList} />

      <Input
        wrapperClassName={styles.contractNumber}
        label='Номер договора'
        value={props.contractNumber}
        onChange={props.onContractNumberChange}
      />

      <Input
        wrapperClassName={styles.message}
        type='textarea'
        label='Ваше сообщение'
        value={props.message}
        hasError={!fp.isEmpty(props.messageErrorList)}
        onChange={props.onMessageChange}
        maxLength={1000}
      />
      <MessageList type='error' messages={props.messageErrorList} />

      <Condition value={!fp.isEmpty(props.apiErrorList)}>
        <MessageList type='error' messages={props.apiErrorList} />
      </Condition>

      <Button
        wrapperClassName={styles.button}
        type='submit'
        text='Отправить'
        isDisabled={props.isFeedbackSending}
      />
    </Form>
  </div>
);
