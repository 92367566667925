import { IRouteConfig, Roles } from '@rfb/common';

import fp from 'lodash/fp';

import DealerPage from 'pages/auth/dealer';
import AuthLoginPage from 'pages/auth/login';
import LoginConfirm from 'pages/auth/login-confirm';
import AuthLoginFirstPage from 'pages/auth/login-first';
import AuthPasswordExpiredPage from 'pages/auth/password-expired';
import AuthPasswordRestorePage from 'pages/auth/password-restore';
import AuthPasswordRestoreSentPage from 'pages/auth/password-restore-sent';
import PhoneValidationPage from 'pages/auth/phone-validation';
import AuthServiceTermsPage from 'pages/auth/service-terms';

import AppHelpPage from 'pages/app/help';
import AppMainPage from 'pages/app/main';

import ClientLinesDetailByIdPage from 'pages/client-lines/detail-by-id';

import InformationContactsPage from 'pages/information/contacts';
import InformationFeedbackPage from 'pages/information/feedback';
import InformationFeedbackSentPage from 'pages/information/feedback-sent';
import InformationMainPage from 'pages/information/main';
import InformationReminderPage from 'pages/information/reminder';
import InformationTariffsPage from 'pages/information/tariffs';

import PersonalDataChangePage from 'pages/profile/change-personal-data';
import ProfileCodeWordChangePage from 'pages/profile/code-word-change';
import ProfileCodeWordChangePageConfirmPage from 'pages/profile/code-word-change-confirm';
import ProfileMainPage from 'pages/profile/main';
import ProfilePasswordChangePage from 'pages/profile/password-change';
import ProfilePasswordChangeConfirmPage from 'pages/profile/password-change-confirm';
import ProfilePhoneNumberChangePage from 'pages/profile/phone-number-change';
import ProfilePhoneNumberChangeConfirmPage from 'pages/profile/phone-number-change-confirm';

import AccountExtendedStatementPage from 'pages/accounts/extended-statement';
import AccountsPage from 'pages/accounts/list';
import AccountRestrictionsByIdPage from 'pages/accounts/restrictions-by-id';

import TranchesListByClientLineIdPage from 'pages/tranches/list-by-client-line-id';
import TranchesMainPage from 'pages/tranches/main';
import TranchesRepaymentPage from 'pages/tranches/repayment';

import FTForm from 'pages/financial-block/add-car-form';
import FinanceConfirm from 'pages/financial-block/finance-confirm';
import FinancialBlockPickerPage from 'pages/financial-block/financial-block-picker';
import FinancialBlockFTTable from 'pages/financial-block/main';

import Error404Page from 'pages/errors/404';
import Error422Page from 'pages/errors/422';
import Error500Page from 'pages/errors/500';

import { selectors as appSelectors } from 'modules/app/store';
import { selectors as authSelectors } from 'modules/auth/store';
import { selectors as informationSelectors } from 'modules/information/store';

import apiErrors from 'configs/api/errors';
import ClientLogPage from 'pages/app/client-log';
import UploadRepo from 'pages/financial-block/finance-upload-repo';
import { OperationDataPage } from 'pages/operation-data/list';
import OperationDataViewPage from 'pages/operation-data/view';
import NotificationFeedbackPage from '../../pages/information/feedback/feedbackFromNotificationPage';
import NotificationsPage from '../../pages/notifications/sent';
import NotificationViewPage from '../../pages/notifications/view';
import TrancheRepaymentConfirmPage from '../../pages/tranches/tranche-repayment-confirm';

const prefix: { [key: string]: string } = {
  auth: '/auth',
  clientLines: '/client-lines',
  information: '/information',
  profile: '/profile',
  accounts: '/accounts',
  operationData: '/operation-data',
  tranches: '/tranches',
  financingTranches: '/financing-tranches',
  financingTranchesCarType: '/financing-tranches-cartype',
};

const config: IRouteConfig = {
  login: {
    path: '/',
    component: AuthLoginPage,
    accessList: [Roles.GUEST, Roles.READ_ONLY],
    isExact: true,
  },

  phoneNumberValidation: {
    path: '/',
    component: PhoneValidationPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    check: fp.pipe(appSelectors.selectApiErrorCode, fp.isEqual(apiErrors.PHONE_NUMBER_ERROR)),
    isExact: true,
  },

  smsLoginConfirm: {
    path: '/',
    component: LoginConfirm,
    accessList: [Roles.USER, Roles.READ_ONLY],
    check: fp.pipe(appSelectors.selectApiErrorCode, fp.isEqual(apiErrors.NOT_CONFIRMED_LOGIN)),
    isExact: true,
  },

  dealer: {
    path: '/',
    component: DealerPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    check: fp.pipe(appSelectors.selectApiErrorCode, fp.isEqual(apiErrors.NOT_CHOOSED_DEALER)),
    isExact: true,
  },

  innKppValidation: {
    path: '/',
    component: AuthLoginFirstPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    check: fp.pipe(appSelectors.selectApiErrorCode, fp.isEqual(apiErrors.RF_INFO_VALIDATION_ERROR)),
    isExact: true,
  },

  serviceTerms: {
    path: '/',
    component: AuthServiceTermsPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    check: fp.pipe(appSelectors.selectApiErrorCode, fp.isEqual(apiErrors.SERVICE_TERMS_ERROR)),
    isExact: true,
  },

  passwordRestore: {
    path: prefix.auth + '/password-restore',
    component: AuthPasswordRestorePage,
    accessList: [Roles.GUEST, Roles.READ_ONLY],
  },

  passwordRestoreSent: {
    path: prefix.auth + '/password-restore-sent',
    component: AuthPasswordRestoreSentPage,
    accessList: [Roles.GUEST, Roles.READ_ONLY],
    check: fp.pipe(authSelectors.selectIsPasswordRestoreSent, fp.isEqual(true)),
  },

  passwordExpired: {
    path: '',
    component: AuthPasswordExpiredPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    check: fp.pipe(appSelectors.selectApiErrorCode, fp.isEqual(apiErrors.PASSWORD_EXPIRED)),
  },

  main: {
    path: '/',
    component: AppMainPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  help: {
    path: '/help',
    component: AppHelpPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  clientLog: {
    path: '/client-log',
    component: ClientLogPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  clientLinesDetailById: {
    path: prefix.clientLines + '/:id/:type/:multiBrand/details',
    component: ClientLinesDetailByIdPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  accounts: {
    path: prefix.accounts,
    component: AccountsPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  operationData: {
    path: prefix.operationData,
    component: OperationDataPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  operationDataSystemPicker: {
    path: `${prefix.operationData}/:system`,
    component: OperationDataPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  operationDataView: {
    path: `${prefix.operationData}/:id/:dealerSystem/view`,
    component: OperationDataViewPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  accountRestrictions: {
    path: prefix.accounts + '/:id',
    component: AccountRestrictionsByIdPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  extendedStatement: {
    path: prefix.accounts + '/extended-statement/:id',
    component: AccountExtendedStatementPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  information: {
    path: prefix.information,
    component: InformationMainPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  contacts: {
    path: prefix.information + '/contacts',
    component: InformationContactsPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  twoSystemContacts: {
    path: prefix.information + '/contacts/two-system',
    component: InformationContactsPage,
    accessList: [Roles.USER],
    isExact: true,
  },

  feedback: {
    path: prefix.information + '/feedback',
    component: InformationFeedbackPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  twoSystemFeedback: {
    path: prefix.information + '/feedback/two-system',
    component: InformationFeedbackPage,
    accessList: [Roles.USER],
    isExact: true,
  },

  feedbackFromNotifications: {
    path: prefix.information + '/feedback/:dealerSystem',
    component: NotificationFeedbackPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  feedbackSent: {
    path: prefix.information + '/feedback-sent',
    component: InformationFeedbackSentPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    check: fp.pipe(informationSelectors.selectIsFeedbackSent, fp.isEqual(true)),
    isExact: true,
  },

  reminder: {
    path: prefix.information + '/reminder',
    component: InformationReminderPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },
  twoSystemReminder: {
    path: prefix.information + '/reminder/two-system',
    component: InformationReminderPage,
    accessList: [Roles.USER],
    isExact: true,
  },

  tariffs: {
    path: prefix.information + '/tariffs',
    component: InformationTariffsPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  profile: {
    path: prefix.profile,
    component: ProfileMainPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  profilePasswordChange: {
    path: prefix.profile + '/password-change',
    component: ProfilePasswordChangePage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  profilePasswordChangeConfirmation: {
    path: prefix.profile + '/password-change/confirm',
    component: ProfilePasswordChangeConfirmPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  profilePhoneNumberChange: {
    path: prefix.profile + '/phone-number-change',
    component: ProfilePhoneNumberChangePage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  profilePhoneNumberChangeConfirmation: {
    path: prefix.profile + '/phone-number-change/confirm',
    component: ProfilePhoneNumberChangeConfirmPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  profileCodeWordChange: {
    path: prefix.profile + '/code-word-change',
    component: ProfileCodeWordChangePage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  profileCodeWordChangeConfirmation: {
    path: prefix.profile + '/code-word-change/confirm',
    component: ProfileCodeWordChangePageConfirmPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  profileFioChange: {
    path: prefix.profile + '/fio-change',
    component: PersonalDataChangePage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  tranches: {
    path: prefix.tranches,
    component: TranchesMainPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  repayment: {
    path: prefix.tranches + '/repayment',
    component: TranchesRepaymentPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
  },

  trancheRepaymentConfirmation: {
    path: prefix.tranches + '/tranches-payment/confirm',
    component: TrancheRepaymentConfirmPage,
    accessList: [Roles.USER],
  },

  tranchesListByClientLineIdAndType: {
    path: prefix.tranches + '/:clientLineId/:clientLineType',
    component: TranchesListByClientLineIdPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  tranchesListByClientLineIdAndTypeAndMultibrand: {
    path: prefix.tranches + '/:clientLineId/:clientLineType/:multiBrand',
    component: TranchesListByClientLineIdPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  // Блок финансирования
  financialBlockFinancialBlockPicker: {
    path: prefix.financingTranches,
    component: FinancialBlockPickerPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  financialBlockFinancialBlockCarTypePicker: {
    path: `${prefix.financingTranchesCarType}/:system`,
    component: FinancialBlockPickerPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  financialBlockFTTable: {
    path: prefix.financingTranches + '/list/:system/:type',
    component: FinancialBlockFTTable,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  financialBlockFTTableBySystem: {
    path: prefix.financingTranches + '/list/:system',
    component: FinancialBlockFTTable,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  financialBlockFTCreate: {
    path: prefix.financingTranches + '/create/:system/:type',
    component: FTForm,
    accessList: [Roles.USER],
  },
  financialBlockFTCreateBySystem: {
    path: prefix.financingTranches + '/create/:system',
    component: FTForm,
    accessList: [Roles.USER],
  },
  financialBlockFTEdit: {
    path: prefix.financingTranches + '/:requestDataId/edit/:system',
    component: FTForm,
    accessList: [Roles.USER],
  },
  financialBlockFTEditRepo: {
    path: prefix.financingTranches + '/:requestDataId/edit/:system/:type',
    component: FTForm,
    accessList: [Roles.USER],
  },

  financialBlockConfirmation: {
    path: prefix.financingTranches + '/confirm/:system/:type',
    component: FinanceConfirm,
    accessList: [Roles.USER],
  },
  financialBlockUploadFormRepo: {
    path: prefix.financingTranches + '/upload-repo/:system/:type',
    component: UploadRepo,
    accessList: [Roles.USER],
  },

  // Notifications
  notifications: {
    path: '/notifications/:dealerSystem',
    component: NotificationsPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },
  notificationView: {
    path: '/notification/:id/:dealerSystem/view',
    component: NotificationViewPage,
    accessList: [Roles.USER, Roles.READ_ONLY],
    isExact: true,
  },

  error500: {
    path: '/500',
    component: Error500Page,
    accessList: [Roles.GUEST, Roles.USER, Roles.READ_ONLY],
  },

  error422: {
    path: '/422',
    component: Error422Page,
    accessList: [Roles.GUEST, Roles.USER, Roles.READ_ONLY],
  },

  error404: {
    path: '*',
    component: Error404Page,
    accessList: [Roles.GUEST, Roles.USER, Roles.READ_ONLY],
  },
};

export default config;
