import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import { Button, ButtonReset, Condition } from '@rfb/ui-kit';

import storageDB from 'utils/storage-db';

import { IDTORFInfoTranchesPaymentRequest } from 'dto/rf-info';

import { ITrancheState } from '../../store';

import styles from './assets/styles/index.module.css';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

export interface ITrancheRepaymentHeaderProps {
  paymentOrderedList: ITrancheState['paymentList'];
  repaymentAmountOrderedList: TODO_ANY;
  paymentListCount: number;
  amount: string;
  isSending: boolean;
  repaymentData: IDTORFInfoTranchesPaymentRequest;
  repaymentFileName: string;
  isTrancheRepaymentCompleted: boolean;
}

export interface ITrancheRepaymentHeaderPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
  };
}

export interface ITrancheRepaymentHeaderActions {
  actions: {
    set: Function;
    trancheRepaymentDocRequestConfirm: Function;
    trancheRepaymentDocDownload: Function;
    trancheRepaymentDocApprove: Function;
    sendPaymentList: Function;
    trancheRepaymentReset: Function;
  };
}

const RepaymentHeader = (
  props: ITrancheRepaymentHeaderProps &
    ITrancheRepaymentHeaderPropsExternal &
    ITrancheRepaymentHeaderActions
) => {
  useEffect(() => {
    return function cleanup() {
      props.isTrancheRepaymentCompleted &&
        props.actions.trancheRepaymentReset({ history: props.history });
    };
  }, []);

  const payments = fp.map(
    (item: ITrancheState['paymentList'][number]) => ({
      vin: item.vin,
      payment_type: item.payment_type,
      repayment_amount: '',
    }),
    props.paymentOrderedList
  );

  for (let i = 0; i < payments.length; i++) {
    payments[i].repayment_amount = props.repaymentAmountOrderedList[i];
  }

  const onDocRequest = (): void => {
    const data: IDTORFInfoTranchesPaymentRequest = { payments };
    props.actions.trancheRepaymentDocRequestConfirm({ data, history: props.history });
  };

  const onDocDownload = (): void => {
    props.actions.trancheRepaymentDocDownload({
      repaymentFileName: props.repaymentFileName,
      history: props.history,
    });
  };

  const onClear = (): void => {
    storageDB.set('tranche-payment-list', []);
    props.actions.set({ paymentList: [], orderList: [] });
  };

  const hasSelectedTranches: boolean = fp.gt(props.paymentListCount, 0);
  return (
    <div className={styles.main}>
      <div className={styles.blockA}>
        <h1 className={styles.title}>Транши на погашение</h1>
        <Condition value={hasSelectedTranches}>
          <Condition value={!props.isTrancheRepaymentCompleted}>
            <Button
              wrapperClassName={styles.buttonSend}
              text="Подтвердить приоритеты и отправить заявку на погашение"
              isDisabled={props.isSending}
              onClick={onDocRequest}
            />
          </Condition>
          <Condition value={props.isTrancheRepaymentCompleted}>
            <Button
              wrapperClassName={styles.buttonSend}
              text="Скачать документ для просмотра"
              isDisabled={props.isSending}
              onClick={onDocDownload}
            />
          </Condition>
        </Condition>
      </div>
      <div className={styles.blockB}>
        <div className={styles.trancheCount}>
          <span className={styles.trancheCountTitle}>Кол-во траншей:</span>
          <span className={styles.trancheCountValue}>{props.paymentListCount}</span>
        </div>
        <div className={styles.priceTotal}>
          <div
            className={styles.priceTotalTooltip}
            data-text="Включает сумму процентов, просроченную задолженность и комиссию(при наличии)"
          >
            <span className={styles.priceTotalTitle}>Итоговая сумма для погашения:</span>
            <span className={styles.priceTotalValue}>{props.amount}</span>
          </div>
        </div>
        <Condition value={hasSelectedTranches && !props.isTrancheRepaymentCompleted}>
          <ButtonReset
            wrapperClassName={styles.reset}
            title="СБРОСИТЬ ВСЕ ВЫБРАННЫЕ ТРАНШИ"
            isActive
            onClear={onClear}
          />
        </Condition>
      </div>
    </div>
  );
};

export default RepaymentHeader;
