import { connect } from 'react-redux';

import fp from 'lodash/fp';

import { IClientLineState, name, selectors } from '../../../../store';

import { getTransformedDate } from 'helpers/transform/date';
import {
  getTransformedNumberForView,
  getTransformedNumberForViewDC,
} from '../../../../helpers/transform';

import { IDTORFInfoClientLineFullResponse, IDTORFInfoRateResponse } from 'dto/rf-info';

import { ClientLineTypes } from 'modules/client-line/configs/type';
import Component, { IClientLineDetailHeader, IClientLineDetailHeaderExternal } from './component';

const mapStateToProps = (
  state: { [name]: IClientLineState },
  props: IClientLineDetailHeaderExternal
): IClientLineDetailHeader & IClientLineDetailHeaderExternal => {
  const currentClientLine: IDTORFInfoClientLineFullResponse =
    selectors.selectCurrentClientLine(state);
  console.log(`clientLine/Header/mapStateToProps: `, currentClientLine);
  const rate: IDTORFInfoRateResponse = selectors.selectRate(state);

  return {
    clientLineId: currentClientLine.number,
    type: currentClientLine.type,
    number:
      currentClientLine.type !== ClientLineTypes.DC
        ? getTransformedNumberForView(currentClientLine.number, currentClientLine.type)
        : getTransformedNumberForViewDC(currentClientLine.agreement_number),
    status: currentClientLine.status,
    rateDate: rate ? getTransformedDate(rate.date_rate) : '?',
    rateValue: fp.toNumber(rate?.value),
    dateBegin: getTransformedDate(currentClientLine.start_date),
    dateEnd: getTransformedDate(currentClientLine.real_finish_date),
    isRateChangeNeed: currentClientLine.is_rate_change_need,
    multiBrand: props.multiBrand,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
