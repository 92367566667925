import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import Component, { IDealerProps, IDealerActions, IDealerPropsExternal } from './component';
import { name, actions, IAuthState, selectors } from 'modules/auth/store';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: { history: TODO_ANY }
): IDealerProps & IDealerPropsExternal => {
  function isFitsTheInnMask(element: TODO_ANY) {
    return element.inn.indexOf(state['auth'].filter.innMask) === 0;
  }

  return {
    config: selectors.selectUserDealers(state).filter(isFitsTheInnMask),
    isLoading: selectors.selectIsLoading(state),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    history: props.history,
  };
};
const mapDispatchToProps = (dispatch: any): IDealerActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
