import React from 'react';

import { History, AuthTemplate } from '@rfb/ui-kit';

import AuthPasswordRestoreSent from 'modules/auth/components/PasswordRestoreSent';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const PasswordRestoreSent = (props: TODO_ANY) => {
  const renderContent = () => (
    <div className={styles.main}>
      <AuthPasswordRestoreSent history={props.history} />
    </div>
  );

  const renderLeftSidebar = () => <History type="back" to={routingConfig.passwordRestore.path} />;

  return <AuthTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default PasswordRestoreSent;
