import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { getTransformedAmount } from '@rfb/common';

import { name, actions, selectors, ITrancheState } from '../../store';

import Component, {
  ITrancheRepaymentHeaderProps,
  ITrancheRepaymentHeaderPropsExternal,
  ITrancheRepaymentHeaderActions,
} from './component';

const mapStateToProps = (
  state: { [name]: ITrancheState },
  props: ITrancheRepaymentHeaderPropsExternal
): ITrancheRepaymentHeaderProps & ITrancheRepaymentHeaderPropsExternal => ({
  paymentOrderedList: selectors.selectPaymentOrderedList(state),
  repaymentAmountOrderedList: selectors.selectRepaymentAmountOrderedList(state),
  paymentListCount: selectors.selectPaymentListCount(state),
  amount: fp.pipe(selectors.selectRepaymentListAmount, getTransformedAmount)(state),
  isSending: selectors.selectIsSending(state),
  isTrancheRepaymentCompleted: selectors.selectIsTrancheRepaymentCompleted(state),
  repaymentData: selectors.selectRepaymentData(state),
  repaymentFileName: selectors.selectRepaymentFileName(state),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): ITrancheRepaymentHeaderActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
