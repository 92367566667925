import { createSlice } from '@reduxjs/toolkit';

import { getStateFromParams } from 'helpers/store';

import { ClientLineStatus } from '../configs/status';

import {
  IDTORFInfoClientLineFullResponse,
  IDTORFInfoClientLineShortResponse,
  IDTORFInfoRateResponse,
} from 'dto/rf-info';

export interface IClientLineState {
  data: IDTORFInfoClientLineShortResponse[];
  dataFull: { [key: string]: IDTORFInfoClientLineFullResponse };
  rate: IDTORFInfoRateResponse;
  currentId: number;
  pageCount: number;
  filter: { [key: string]: string | number | boolean };
  errorList: { [key: string]: string[] };
  isLoading: boolean;
}

const initialState: IClientLineState = {
  data: [],
  dataFull: {},
  rate: { date_rate: '', value: '' },
  currentId: 0,
  pageCount: 0,
  filter: { status: ClientLineStatus.OPENED, page: 1 },
  errorList: {},
  isLoading: false,
};

const clientLineSlice = createSlice({
  name: 'client-line',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
    setDataFull: (state, action) => ({
      ...state,
      dataFull: { ...state.dataFull, ...action.payload },
    }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    setFilter: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action.payload },
    }),
    reset: () => ({ ...initialState }),

    getDataShort: getStateFromParams,
    getDataShortSuccessful: getStateFromParams,
    getDataShortFailure: getStateFromParams,

    getDataFullById: getStateFromParams,
    getDataFullByIdSuccessful: getStateFromParams,
    getDataFullByIdFailure: getStateFromParams,
  },
});

export const { name, actions, reducer } = clientLineSlice;
