import { RCNotification } from '@rfb/common';

import { NotificationError, NotificationInfo, NotificationConfirm } from '@rfb/ui-kit';

import '@rfb/common/utils/notification/implementations/rc-notification/assets/styles/override.css';

const settings = {
  templates: {
    info: NotificationInfo,
    err: NotificationError,
    confirm: NotificationConfirm,
  },
};

export default RCNotification(settings);
