export const responseBody = {
  records: [
    {
      operation_data_id: 202,
      operation_create_date: '2022-03-23 11:46:59',
      operation_type_code: 'PAY',
      operation_status_id: 1,
      operation_amount: 988680.0,
      sent_file_name: 'Pay_10213987312_11.46.59_23.03.2022.csv',
      doc_request_file_id: '0902743f8205a9e7',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 201,
      operation_create_date: '2022-03-23 11:19:05',
      operation_type_code: 'PAY',
      operation_status_id: 1,
      operation_amount: 2696736.0,
      sent_file_name: 'Pay_10213987312_11.19.05_23.03.2022.csv',
      doc_request_file_id: '0902743f8205a9e6',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 199,
      operation_create_date: '2022-03-22 15:38:27',
      operation_type_code: 'PAY',
      operation_status_id: 1,
      operation_amount: 1885155.0,
      sent_file_name: 'Pay_10213987312_15.38.27_22.03.2022.csv',
      doc_request_file_id: '0902743f8205a9e4',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 198,
      operation_create_date: '2022-03-22 15:29:07',
      operation_type_code: 'PAY',
      operation_status_id: 1,
      operation_amount: 1988364.0,
      sent_file_name: 'Pay_10213987312_15.29.07_22.03.2022.csv',
      doc_request_file_id: '0902743f82057b11',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 197,
      operation_create_date: '2022-03-22 15:19:20',
      operation_type_code: 'PAY',
      operation_status_id: 1,
      operation_amount: 1697052.0,
      sent_file_name: 'Pay_10213987312_15.19.20_22.03.2022.csv',
      doc_request_file_id: '0902743f82057b10',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 196,
      operation_create_date: '2022-01-27 17:07:13',
      operation_type_code: 'REQ',
      operation_status_id: 1,
      operation_amount: 11111,
      sent_file_name: 'Req_10213987312_17.07.13_27.01.2022.csv',
      doc_request_file_id: '0902743f81fcffba',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 195,
      operation_create_date: '2022-01-25 09:07:48',
      operation_type_code: 'PAY',
      operation_status_id: 1,
      operation_amount: 4972506.01,
      sent_file_name: 'Pay_10213987312_09.07.48_25.01.2022.csv',
      doc_request_file_id: '0902743f81fcb328',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 194,
      operation_create_date: '2022-01-24 14:52:44',
      operation_type_code: 'REQ',
      operation_status_id: 1,
      operation_amount: 190000,
      sent_file_name: 'Req_10213987312_14.52.44_24.01.2022.csv',
      doc_request_file_id: '0902743f81fc7afd',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
    {
      operation_data_id: 193,
      operation_create_date: '2022-01-24 14:49:00',
      operation_type_code: 'REQ',
      operation_status_id: 1,
      operation_amount: 133,
      sent_file_name: 'Req_10213987312_14.49.00_24.01.2022.csv',
      doc_request_file_id: '0902743f81fc7afc',
      doc_invoice_file_id: null,
      user_name: 'Степанова Елена Александровна',
      remove_flag: true,
    },
  ],
  filters: {
    operation_type_code: ['PAY', 'REQ'],
    operation_status_id: [10, 4, 5, 6, 9, 1, 8],
  },
};
