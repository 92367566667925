var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Logout = function (props) { return (React.createElement("svg", __assign({}, props, { width: "17", height: "16", viewBox: "0 0 17 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M9 5.23333L9 2L1 2L0.999999 13L9 13L9 9.76666", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M15 7.5L12 4.5M15 7.5L12 10.5M15 7.5L5 7.49963", strokeWidth: "1.5" }))); };
export default Logout;
