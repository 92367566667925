import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import { name, actions, selectors, IFinancialState } from '../../store';

import Component, {
  IAllCreateFormActions,
  IAllCreateFormProps,
  IAllCreateFormPropsExternal,
} from './component';

import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: IAllCreateFormPropsExternal
): IAllCreateFormPropsExternal & IAllCreateFormProps => {
  const form = selectors.selectForm(state);

  return {
    requestAmount: form.requestAmount,
    requestCode: form.requestCode,
    requestBrand: form.requestBrand,
    requestPdf: form.requestPdf,
    requestVin: form.requestVin,
    requestInvoiceFileName: form.requestInvoiceFileName,
    requestDataId: form.requestDataId,
    isLoading: selectors.selectIsLoading(state),
    requestAmountErrorList: selectors.selectErrorListByField(state)('requestAmount'),
    requestCodeErrorList: selectors.selectErrorListByField(state)('requestCode'),
    requestPdfErrorList: selectors.selectErrorListByField(state)('requestPdf'),
    requestVinErrorList: selectors.selectErrorListByField(state)('requestVin'),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    requestCodeOptions: selectors.selectRequestCodeOptions(state),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: TODO_ANY): IAllCreateFormActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
