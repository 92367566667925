import React from 'react';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import DealerChoice from '../../../modules/auth/components/DealerChoice';

const DealerPage = (props: TODO_ANY) => {
  return <DealerChoice history={props.history} />;
};

export default DealerPage;
