import React from 'react';
import ReactDropdown from 'react-dropdown';
import cn from 'classnames';
import fp from 'lodash/fp';
import ArrowClosedIcon from '../../icons/ArrowClosed';
import ArrowOpenedIcon from '../../icons/ArrowOpened';
import styles from './assets/styles/index.module.css';
import './assets/styles/override.css';
export var Dropdown = function (props) {
    var _a, _b;
    var withoutSelectedValue = fp.isEmpty(props.value);
    var labelClassNames = cn((_a = {},
        _a[styles.label] = true,
        _a[styles.labelHidden] = withoutSelectedValue,
        _a));
    var dropdownControlClassNames = cn((_b = {},
        _b[styles.dropdownControl] = true,
        _b[styles.dropdownControlError] = props.hasError,
        _b));
    return (React.createElement("div", { className: cn(props.wrapperClassName), role: "combobox" },
        React.createElement("div", { className: cn(styles.main, 'ui-kit-dropdown') },
            React.createElement("div", { className: labelClassNames }, props.label),
            React.createElement(ReactDropdown, { options: props.options, value: props.value, placeholder: props.label, onChange: function (option) { return props.onChange(option.value, option); }, arrowClosed: React.createElement(ArrowClosedIcon, null), arrowOpen: React.createElement(ArrowOpenedIcon, null), controlClassName: dropdownControlClassNames, placeholderClassName: styles.dropdownPlaceholder, menuClassName: styles.dropdownMenu, disabled: props.disabled }))));
};
Dropdown.defaultProps = {
    hasError: false,
};
export default Dropdown;
