import { createSlice } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { IMenuElement, IMenuSubMenu } from '@rfb/ui-kit';
import { getStateFromParams } from 'helpers/store';

export interface IAppState {
  helpText: string;
  appMenuConfig: (IMenuElement | IMenuSubMenu)[];
  errorList: { [key: string]: string[] };
  apiErrorCode: number;
  isAppInit: boolean;
  isLoading: boolean;
  isSystemAvailable: boolean;
  systemUnavailabilityMessage: string;
  errors: { [key: string]: string[] };
  requestCount: number;
}

export const initialState: IAppState = {
  helpText: '',
  errorList: {},
  apiErrorCode: 0,
  isAppInit: false,
  isLoading: true,
  isSystemAvailable: true,
  systemUnavailabilityMessage: '',
  errors: {},
  requestCount: 0,
  appMenuConfig: [
    {
      title: 'Договоры',
      items: [
        { title: 'Информация по договорам', link: '/' },
        { title: 'Информация по траншам', link: '/tranches' },
        { title: 'Запрос на финансирование', link: '/financing-tranches' },
      ],
    },
    { title: 'Расчётные счета', link: '/accounts' },
    { title: 'Журнал операций', link: '/operation-data' },
    {
      title: 'Помощь',
      items: [
        { title: 'Правила работы в личном кабинете', link: '/help' },
        { title: 'Журнал работы приложения', link: '/client-log' },
      ],
    },
    { title: 'Информация', link: '/information' },
    {
      title: 'Почта',
      count: 0,
      link: '/notifications/rb',
    },
  ],
};

const appSlice = createSlice({
  name: 'app',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    resetNotificationCounters: (state) => ({
      ...state,
      appMenuConfig: [...initialState.appMenuConfig],
    }),

    checkSystemAvailability: getStateFromParams,
    checkSystemAvailabilityFailure: getStateFromParams,

    init: getStateFromParams,
    initSuccessful: fp.identity,
    initFailure: getStateFromParams,

    getHelpText: getStateFromParams,
    getHelpTextSuccessful: getStateFromParams,
    getHelpTextFailure: getStateFromParams,

    getNotificationCount: getStateFromParams,

    getCountAgreeRequest: getStateFromParams,

    getClientLogSettings: getStateFromParams,
    postClientLog: getStateFromParams,
  },
});

export const { name, actions, reducer } = appSlice;
