export const dealer_GET_ResponseBody = {
  diasoft_id: '10000253051',
  name: 'ООО "А.С.-Авто"',
  inn: '3443064792',
  kpp: '344301001',
};

export const dealerLakyMotors_GET_ResponseBody = {
    "diasoft_id": "10216237686",
    "name": "АО \"Автоцентр Лаки Моторс\"",
    "inn": "6679028695",
    "kpp": "665801001"
};

export const dealer_GET_Dubinin_ResponseBody = {
  "diasoft_id": "10130275532",
  "name": "ООО \"СЕЙХО Моторс СПОРТ\"",
  "inn": "7447128275",
  "kpp": "744701001"
};
