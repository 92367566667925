import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { actions, IAccountsState, name, selectors } from '../../store';

import ExtendedStatement, {
  IExtendedStatementActions,
  IExtendedStatementProps,
  IExtendedStatementPropsExternal,
} from './component';

const mapStateToProps = (
  state: { [name]: IAccountsState },
  props: IExtendedStatementPropsExternal
): IExtendedStatementPropsExternal & IExtendedStatementProps => ({
  id: props.id,
  dateStart: selectors.selectDateStart(state),
  dateEnd: selectors.selectDateEnd(state),
  statementData: selectors.selectExtendedStatementData(state),
  pageCount: selectors.selectStatementsPageCount(state),
  isLoading: selectors.selectIsLoading(state),
  page: selectors.selectFilterByField(state)('pageStatements'),
});

const mapDispatchToProps = (dispatch: any): IExtendedStatementActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedStatement);
