"use strict";
exports.__esModule = true;
exports.BlockReasons = void 0;
var BlockReasons;
(function (BlockReasons) {
    BlockReasons[BlockReasons["BLOCKED"] = 0] = "BLOCKED";
    BlockReasons[BlockReasons["BLOCKED_BY_ADMIN"] = 1] = "BLOCKED_BY_ADMIN";
    BlockReasons[BlockReasons["BLOCKED_FAILURE_AUTH"] = 2] = "BLOCKED_FAILURE_AUTH";
    BlockReasons[BlockReasons["BLOCKED_WRONG_CODE_SMS"] = 3] = "BLOCKED_WRONG_CODE_SMS";
})(BlockReasons = exports.BlockReasons || (exports.BlockReasons = {}));
