import { createSlice } from '@reduxjs/toolkit';
import { Directions, getStateFromParams } from '@rfb/common';
import { TNotification } from 'dto/notification';
import fp from 'lodash/fp';
import { InOutDirection, NotificationReadStatusFilterCode } from '../configs/type';

export interface INotificationState {
  subject: string;
  message: string;
  attachments: File[];
  checkAllMode: boolean;
  selectedList: string[];
  notifications: TNotification[];
  currentNotification: TNotification;
  filter: {
    sender: string;
    subject: string;
    statusCode: NotificationReadStatusFilterCode | '';
    read_by: string;
    sorting?: { value: string; direction: Directions };
    inOutDirection: InOutDirection;
    pageCount: number;
    page: number;
    perPage: number;
    isFilterActive: boolean;
    lastResetTimestamp: number;
  };
  pageCount: number;
  errors: { [key: string]: string[] };
  isLoading: boolean;
}

const initialState: INotificationState = {
  subject: '',
  message: '',
  attachments: [],
  checkAllMode: false,
  selectedList: [],
  notifications: [],
  currentNotification: {
    notification_id: 0,
    notification_date: '',
    notification_type_code: '',
    notification_source_code: '',
    sender: '',
    subject: '',
    read_by: '',
    read_date: '',
    attachments: [],
  },
  filter: {
    sender: '',
    subject: '',
    statusCode: '',
    read_by: '',
    sorting: { value: '', direction: Directions.ASC },
    inOutDirection: InOutDirection.IN,
    pageCount: 0,
    page: 1,
    perPage: 20,
    isFilterActive: false,
    lastResetTimestamp: 0,
  },
  pageCount: 0,
  errors: {},
  isLoading: false,
};

const notificationsSlice = createSlice({
  name: 'notificationVisualization',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setSubject: (state, action) => ({
      ...state,
      subject: action.payload,
    }),
    setMessage: (state, action) => ({
      ...state,
      message: action.payload,
    }),
    setAttachments: (state, action) => ({
      ...state,
      attachments: action.payload,
    }),
    setError: (state, action) => ({
      ...state,
      errors: { ...state.errors, ...action.payload },
    }),
    setFilter: (state, action) => ({
      ...state,
      filter: { ...state.filter, ...action.payload, isFilterActive: true },
    }),
    reset: () => ({ ...initialState }),
    resetFilter: (state) => ({
      ...state,
      filter: { ...initialState.filter, lastResetTimestamp: Date.now() },
    }),
    resetFilterByOmitList: (state, action) => ({
      ...state,
      filter: {
        ...state.filter,
        ...fp.omit<INotificationState['filter']>(action.payload, initialState.filter),
        lastResetTimestamp: Date.now(),
      },
    }),
    resetSelection: (state) => ({ ...state, selectedList: [...initialState.selectedList] }), // TODO Убедиться в необходимости
    resetForm: (state) => ({
      ...state,
      subject: initialState.subject,
      message: initialState.message,
      attachments: initialState.attachments,
    }),

    getDealers: getStateFromParams,
    getDealersSuccessful: getStateFromParams,
    getDealersFailure: getStateFromParams,

    getNotifications: getStateFromParams,
    getNotificationsSuccessful: getStateFromParams,
    getNotificationsFailure: getStateFromParams,

    getNotificationById: getStateFromParams,
    getNotificationByIdSuccessful: getStateFromParams,
    getNotificationByIdFailure: getStateFromParams,

    setNotificationRead: getStateFromParams,

    createNotification: getStateFromParams,
    createNotificationFailure: getStateFromParams,

    attachmentUpload: getStateFromParams,
    attachmentUploadSuccessful: getStateFromParams,

    documentumFileDownload: getStateFromParams,

    toggleCheckAllMode: getStateFromParams,
    loadAllDealersByFilter: getStateFromParams,

    resetNotificationCounters: getStateFromParams,

    docListFileDownload: getStateFromParams,
  },
});

export const { name, actions, reducer } = notificationsSlice;
