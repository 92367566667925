import React from 'react';

import fp from 'lodash/fp';

import { getTransformedAmount } from '@rfb/common';

import styles from './assets/styles/index.module.css';

export interface IAccountsSummaryProps {
  rest_in: string;
  rest_out: string;
  turn_cred: string;
  turn_debt: string;
}

const Summary = (props: IAccountsSummaryProps) => {
  return (
    <div className={styles.summary}>
      <div className={styles.start}>
        <div className={styles.title}>Начальный остаток:</div>
        <div className={styles.value}>{getTransformedAmount(fp.toNumber(props.rest_in))}</div>
      </div>
      <div className={styles.end}>
        <div className={styles.title}>Конечный остаток:</div>
        <div className={styles.value}>{getTransformedAmount(fp.toNumber(props.rest_out))}</div>
      </div>
      <div className={styles.income}>
        <div className={styles.title}>Всего поступило:</div>
        <div className={styles.value}>{getTransformedAmount(fp.toNumber(props.turn_cred))}</div>
      </div>
      <div className={styles.charge}>
        <div className={styles.title}>Всего списано: </div>
        <div className={styles.value}>{getTransformedAmount(fp.toNumber(props.turn_debt))}</div>
      </div>
    </div>
  );
};

export default Summary;
