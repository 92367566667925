import moment from 'moment';
import 'moment/locale/ru';

export function toDate(value) {
  return moment(value).format('DD.MM.YYYY');
}

export function toRuDate(value = undefined) {
  return moment(value).locale('ru').format('D MMMM');
}
