import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';
import dayjs from 'dayjs';

import { getTransformedDate } from 'helpers/transform/date';

import { IDealer, IDTOProfileViewResponse } from 'dto/profile';

import { name } from './index';
import { IDTORFInfoDealerResponse } from 'dto/rf-info';

export const selectState = fp.path(name);

const selectProfile = createSelector(selectState, fp.pathOr({}, 'profile'));
const selectOrganization = createSelector(selectState, fp.pathOr({}, 'dealer'));
const selectForm = createSelector(selectState, fp.path('form'));
const selectDealer = createSelector(selectState, (state) => {
  const profile: IDTOProfileViewResponse = state.profile;
  const dealer: IDTORFInfoDealerResponse = state?.dealer;
  const result = profile?.dealers?.find((dealer) => dealer.diasoft_id === dealer?.diasoft_id);
  return result;
});

const selectors = {
  selectProfile,
  selectFullName: createSelector(
    selectProfile,
    (profile: IDTOProfileViewResponse) =>
      `${profile.last_name} ${profile.first_name} ${profile.middle_name}`
  ),
  selectIsReadOnly: createSelector(selectProfile, fp.path('read_only')),
  selectEmail: createSelector(selectProfile, fp.path('email')),
  selectPhoneNumber: createSelector(selectProfile, fp.path('phone_number')),
  selectAuthorityDateRange: createSelector(
    selectDealer,
    (dealer?: IDealer) =>
      `${dealer?.authority_begin_date ? getTransformedDate(dealer?.authority_begin_date) : ''} - ${
        dealer?.authority_end_date ? getTransformedDate(dealer?.authority_end_date) : ''
      }`
  ),
  selectAuthorityExpiredRemainDays: createSelector(selectDealer, (dealer?: IDealer) =>
    dayjs(dealer?.authority_end_date).diff(dayjs(), 'day')
  ),
  selectPasswordExpiredDate: createSelector(selectProfile, (profile: IDTOProfileViewResponse) =>
    getTransformedDate(profile.password_unsafe_at)
  ),
  selectPasswordExpiredRemainDays: createSelector(
    selectProfile,
    (profile: IDTOProfileViewResponse) => dayjs(profile.password_unsafe_at).diff(dayjs(), 'day')
  ),
  selectOrganization,
  selectDealerValueByField: createSelector(
    selectOrganization,
    (state) => (field: string) => fp.path(field, state)
  ),
  selectFormValueByField: createSelector(
    selectForm,
    (state) => (field: string) => fp.path(field, state)
  ),
  selectErrorListByField: createSelector(
    selectState,
    (state) => (field: string) => fp.pathOr([], ['errorList', field], state)
  ),
};

export default selectors;
