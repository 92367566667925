import React from 'react';
import DropDownRenderer from './DropDownRenderer';

interface IMenuElement {
  title: string;
  file_id: string;
}

interface IMenuSubMenu {
  title: string;
  items?: IMenuElement[];
}

export interface IMenuProps {
  config: (IMenuElement | IMenuSubMenu)[];
}

const DropDownConfig = (props: IMenuProps) => <DropDownRenderer config={props.config} />;

export default DropDownConfig;
