import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { actions, IFinancialState, name, selectors } from '../../store';

import Component, {
  IAllCreateFormActions,
  IAllCreateFormProps,
  IAllCreateFormPropsExternal,
} from './component';

import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { DeliveryLocationDto } from 'dto/financial-block';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: IAllCreateFormPropsExternal
): IAllCreateFormPropsExternal & IAllCreateFormProps => {
  const form = selectors.selectForm(state);
  const priceWithVAT = form.priceWithVAT;
  let currentLimitAmount;
  const lastFinancingRepoRequestDataFinancingInfoResponse =
    selectors.selectLastFinancingRepoRequestDataFinancingInfoResponse(state);
  if (lastFinancingRepoRequestDataFinancingInfoResponse) {
    currentLimitAmount = lastFinancingRepoRequestDataFinancingInfoResponse.data.limit_rest;
  }
  const result = {
    advancePercent: selectors.selectAdvancePercent(state),
    advancePercentDemo: selectors.selectAdvancePercentDemo(state),
    accountNumber: form.accountNumber,
    accountNumberErrorList: selectors.selectErrorListByField(state)('accountNumber'),
    accountNumberDate: form.accountNumberDate,
    accountNumberDateErrorList: selectors.selectErrorListByField(state)('accountNumberDate'),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    priceWithVAT,
    priceWithVATErrorList: selectors.selectErrorListByField(state)('priceWithVAT'),
    currentLimitAmount,
    deliveryLocation: form.deliveryLocation,
    deliveryLocations: selectors
      .selectDeliveryLocations(state)
      .map((location: DeliveryLocationDto) => ({
        value: location.address.replace(/\s/g, ''),
        label: location.address,
      })),
    deliveryLocationErrorList: selectors.selectErrorListByField(state)('deliveryLocation'),
    isLoading: selectors.selectIsLoading(state),
    lastFinancingRepoRequestDataFinancingInfoResponse,
    vatAmountErrorList: selectors.selectErrorListByField(state)('vatAmount'),
    originCountry: form.originCountry,
    originCountryErrorList: selectors.selectErrorListByField(state)('originCountry'),
    paymentPurpose: form.paymentPurpose,
    paymentPurposeErrorList: selectors.selectErrorListByField(state)('paymentPurpose'),
    paymentDataList: selectors.selectPaymentDataList(state),
    recieverShortName: form.recieverShortName,
    recieverShortNameErrorList: selectors.selectErrorListByField(state)('recieverShortName'),
    repoRequestDataBrands: selectors.selectRepoRequestDataBrands(state),
    repoRequestDataModels: selectors.selectRepoRequestDataModels(state),
    requestBrand: form.requestBrand,
    requestBrandCode: form.requestBrandCode,
    requestBrandErrorList: selectors.selectErrorListByField(state)('requestBrand'),
    requestCode: form.requestCode,
    requestDataId: form.requestDataId,
    requestModel: form.requestModel,
    requestModelId: form.requestModelId,
    requestModelErrorList: selectors.selectErrorListByField(state)('requestModel'),
    requestVin: form.requestVin,
    requestVinErrorList: selectors.selectErrorListByField(state)('requestVin'),
    supplierDocDate: form.supplierDocDate,
    supplierDocDateErrorList: selectors.selectErrorListByField(state)('supplierDocDate'),
    supplierDocDateStatus: selectors.selectSupplierDocDateStatus(state),
    supplierDocDateAddPercent: selectors.selectSupplierDocDateAddPercent(state),
    formPrefilling: selectors.selectFormPrefilling(state),
    operationDataId: selectors.selectOperationDataId(state),
    ...props,
  };
  return result;
};

const mapDispatchToProps = (dispatch: TODO_ANY): IAllCreateFormActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
