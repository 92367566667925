var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import fp from 'lodash/fp';
import { Condition } from '../../services/Condition';
import { Content } from './components/Content';
import { Pagination } from './components/Pagination';
import styles from './assets/styles/index.module.css';
export var Table = function (props) {
    var shouldRenderPagination = fp.every(Boolean, [
        !props.isLoading,
        fp.gt(props.pageCount, 1),
    ]);
    return (React.createElement("div", { className: styles.main },
        React.createElement("div", { className: styles.content },
            React.createElement(Content, __assign({}, props))),
        React.createElement(Condition, { value: shouldRenderPagination },
            React.createElement("div", { className: styles.pagination },
                React.createElement(Pagination, __assign({}, props))))));
};
export default Table;
