import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import React from 'react';
import {
  IDTOOperationDataResponse,
  OperationDataTypeCode,
  OperationStatusId,
  TOperationData,
} from '../../../../dto/operation-data';
import {
  OperationDataStatusValue,
  operationDataStatusId,
  operationDataStatusValue,
} from '../../configs/status';
import {
  OperationDataTypeValue,
  operationDataTypeCode,
  operationDataTypeValue,
} from '../../configs/type';
import { AttachmentCell, DropDownCell } from './DropDownCell/DropDownCell';
import { OperationNumberCell } from './OperationNumberCell';
import { DocCell } from './doc-cell';
import { ErrorCell } from './error-cell';
import { StatusCell } from './status-cell';

export const getTypeValue = (operationDataType: OperationDataTypeCode): string => {
  return operationDataTypeValue[operationDataType];
};
export const getTypeCode = (operationDataType: OperationDataTypeValue): OperationDataTypeCode => {
  return operationDataTypeCode[operationDataType];
};

export const getStatusValue = (statusId: OperationStatusId): string => {
  return operationDataStatusValue[statusId];
};

export const getStatusId = (statusValue: OperationDataStatusValue): OperationStatusId => {
  return operationDataStatusId[statusValue];
};

export const getTransformedStatusToCellComponent = (
  status: OperationStatusId,
  // row: IDTOOperationDataResponse['records'][number]
  row: TODO_ANY
): JSX.Element => {
  return (
    <StatusCell
      status={status}
      recordId={row.operation_data_id}
      operationTypeCode={row.operation_type_code}
    />
  );
};

export const getTransformedOperationNumberCell = (
  notUse: TODO_ANY,
  // row: IDTOOperationDataResponse['records'][number]
  row: any
): JSX.Element => {
  return <OperationNumberCell record={row} />;
};

export const getTransformedDocument = (notUse: TODO_ANY, row: TODO_ANY): JSX.Element => {
  return (
    <DocCell
      doc_request_file_id={row.doc_request_file_id}
      doc_invoice_file_id={row.doc_invoice_file_id}
    />
  );
};

export const getTransformedErrors = (notUse: TODO_ANY, row: TODO_ANY): JSX.Element => {
  return (
    <ErrorCell
      status={row.operation_status_id}
      recordId={row.operation_data_id}
      loadOperationError={row.load_operation_error}
    />
  );
};

export const getTransformedAttachments = (
  notUse: TODO_ANY,
  row: TOperationData | undefined
): JSX.Element => {
  return row?.attachments.length ? (
    <DropDownCell
      items={
        row
          ? row.attachments.map((attachment) => ({
              title: attachment.file_name,
              file_id: attachment.file_id,
            }))
          : []
      }
    />
  ) : (
    <div />
  );
};
