export const requestData_GET = {
  data: [
    {
      request_data_id: 101,
      request_brand: 'Porsche',
      request_code: '3620200',
      request_vin: 'POR34215432524352',
      request_amount: 24355,
      date_ins: '2022-01-11 10:37:32',
      request_invoice_file_name: 'Жизнь модуля.pdf',
    },
  ],
  request_sum: '24355',
};

export const requestData_POST_ResponseBody = {
  request_data_id: 95,
  request_brand: 'Porsche',
  request_code: '3620200',
  request_vin: 'VIN11111111111111',
  request_amount: '1000',
  date_ins: '2021-12-23 07:23:27',
  request_invoice_file_name: 'Pay_10000285124_07.24.50_10.08.2021.pdf',
};
