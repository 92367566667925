import { Condition } from '@rfb/ui-kit';
import React, { useEffect, useState } from 'react';
import styles from './assets/styles/index.module.css';

// TODO Устранить дублирование
interface MoneyInputProps {
  title: string;
  initialValue: string;
  onChange: (value: string) => void;
}

function formatMoney(input: string): string {
  if (input === '') {
    return '';
  }
  const number = parseFloat(input);
  if (isNaN(number)) {
    return 'Неверный формат';
  }
  return number.toFixed(2);
}

const MoneyInput: React.FC<MoneyInputProps> = (props: MoneyInputProps) => {
  const [fieldValue, setFieldValue] = useState<string>('');

  useEffect(() => {
    const formattedValue = formatMoney(props.initialValue);
    setFieldValue(formattedValue);
  }, [props.initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue, selectionStart } = event.target;

    const dotIndex = newValue.indexOf('.');
    if (selectionStart !== null && selectionStart <= dotIndex) {
      return;
    }
    if (/^\d+\.\d{0,2}$/.test(newValue)) {
      setFieldValue(newValue);
      props.onChange(newValue);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const dotIndex = value.indexOf('.');
    // Устанавливаем курсор сразу после десятичной точки
    event.target.setSelectionRange(dotIndex + 1, dotIndex + 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value, selectionStart, selectionEnd } = event.currentTarget;
    const dotIndex = value.indexOf('.');

    // Запрещаем перемещение курсора влево от десятичной точки
    if (event.key === 'ArrowLeft' && selectionStart !== null && selectionStart <= dotIndex + 1) {
      event.preventDefault();
    }

    // Запрещаем перемещение курсора вправо в область целых чисел
    if (event.key === 'ArrowRight' && selectionEnd !== null && selectionEnd <= dotIndex) {
      event.preventDefault();
    }

    // Запрещаем удаление символов в области целых чисел
    if (
      (event.key === 'Backspace' || event.key === 'Delete') &&
      selectionStart !== null &&
      selectionStart <= dotIndex
    ) {
      event.preventDefault();
    }
  };

  const fieldName = props.title;

  return (
    <div style={{ position: 'relative' }}>
      <Condition value={!!fieldValue}>
        <div
          style={{
            position: 'absolute',
            top: '11px',
            fontSize: '11px',
            textTransform: 'uppercase',
          }}
        >
          {fieldName}
        </div>
      </Condition>
      <input
        className={styles.moneyInput}
        type="text"
        value={fieldValue}
        onChange={handleChange}
        placeholder={fieldName}
        // onFocus={handleFocus}
        // onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default MoneyInput;
