import React from 'react';
import new_api from 'utils/new-api';
import notification from 'utils/notification';

export interface DownloadLinkExternal {
  fileName: string;
  fileUrl: string;
}

export interface DownloadLinkActions {
  actions: {
    generateFileForDownload: Function;
  };
}

// TODO Вынести в common
const DownloadLink = (props: DownloadLinkExternal) => {
  const handleDownload = async () => {
    const link = document.createElement('a');

    const url = process.env.REACT_APP_NEW_API_URL + props.fileUrl;
    const config = {
      responseType: 'blob',
    };
    try {
      const response = await new_api.get(url, config);
      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
      // window.open(fileUrl, '_blank');
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = props.fileName;
      document.body.appendChild(link);
      link.target = '_blank';
      link.click();
    } catch (error) {
      notification.info('Документ недоступен. Попробуйте повторить попытку позднее');
    }
  };

  return (
    <a
      style={{
        cursor: 'pointer',
        textDecoration: 'underline',
        color: 'gray',
      }}
      target="_blank"
      onClick={(e) => {
        e.preventDefault();
        handleDownload();
      }}
    >
      {props.fileName}
    </a>
  );
};

export default DownloadLink;
