import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import { getTransformedApiDate, getTransformedDateObject } from '@rfb/common';
import CalendarPopup from '../CalendarPopup';
import DateInput from '../DatePicker/components/DateInput';
import Condition from '../../services/Condition';
import ShiftIcon from '../../icons/Next';
import styles from './assets/styles/index.module.css';
var dateEmptyPlaceholder = '_';
export var DatePickerRange = function (props) {
    var _a;
    // Начальный период
    var daysBackCount = 7;
    // На сколько дней сдвигать при клике на стрелочки
    var daysShift = 1;
    var now = getTransformedApiDate(new Date().toString());
    var defaultStartDate = getTransformedApiDate(new Date(Date.now() - daysBackCount * 3600 * 24 * 1000).toString());
    var _b = useState(''), startDate = _b[0], setStartDate = _b[1];
    var _c = useState(''), endDate = _c[0], setEndDate = _c[1];
    useEffect(function () {
        setStartDate(fp.isEmpty(props.start) ? defaultStartDate : props.start);
    }, [props.start]);
    useEffect(function () {
        setEndDate(fp.isEmpty(props.end) ? now : props.end);
    }, [props.end]);
    var onCalendarChange = function (values) {
        var dateList = values;
        setStartDate(fp.head(dateList));
        setEndDate(fp.last(dateList));
        props.onChange(dateList);
    };
    var onDateStartChange = function (date) {
        if (fp.isEqual(startDate, date))
            return;
        setStartDate(date);
        props.onChange([date, props.end ? endDate : '']);
    };
    var onDateEndChange = function (date) {
        if (fp.isEqual(endDate, date))
            return;
        setEndDate(date);
        props.onChange([props.start ? startDate : '', date]);
    };
    var handleShiftLeft = function () {
        var start = getTransformedApiDate(new Date(getTransformedDateObject(startDate).getTime() - daysShift * 3600 * 24 * 1000).toString());
        var end = getTransformedApiDate(new Date(getTransformedDateObject(endDate).getTime() - daysShift * 3600 * 24 * 1000).toString());
        setStartDate(start);
        setEndDate(end);
        props.onChange([start, end]);
    };
    var handleShiftRight = function () {
        var start = getTransformedApiDate(new Date(getTransformedDateObject(startDate).getTime() + daysShift * 3600 * 24 * 1000).toString());
        var end = getTransformedApiDate(new Date(getTransformedDateObject(endDate).getTime() + daysShift * 3600 * 24 * 1000).toString());
        setStartDate(start);
        setEndDate(end);
        props.onChange([start, end]);
    };
    var classNames = cn(props.wrapperClassName, (_a = {},
        _a[styles.shift] = true,
        _a));
    return (React.createElement("div", { className: classNames },
        React.createElement(Condition, { value: !!props.withShift },
            React.createElement("button", { className: styles.shiftLeft, onClick: handleShiftLeft },
                React.createElement(ShiftIcon, null))),
        React.createElement("div", { className: styles.main },
            React.createElement(CalendarPopup, { wrapperClassName: styles.calendar, type: "range", value: [startDate, endDate], isDisabled: props.isDisabled, onCalendarChange: onCalendarChange }),
            "\u0441",
            React.createElement(DateInput, { value: props.start ? startDate : dateEmptyPlaceholder, onChange: onDateStartChange }),
            "\u043F\u043E",
            ' ',
            React.createElement(DateInput, { value: props.end ? endDate : dateEmptyPlaceholder, onChange: onDateEndChange }),
            React.createElement(Condition, { value: React.isValidElement(props.displayedButtonPostfix) }, props.displayedButtonPostfix)),
        React.createElement(Condition, { value: !!props.withShift },
            React.createElement("button", { className: styles.shiftRight, onClick: handleShiftRight },
                React.createElement(ShiftIcon, null)))));
};
export default DatePickerRange;
