import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { DropDownCell } from 'modules/operation-data/helpers/transform/DropDownCell/DropDownCell';
import React from 'react';

// Сделано(фактически продублировано) специально для этого места из-за фильтрации по attachment.type_code
export const getTransformedAttachments = (notUse: TODO_ANY, row: TODO_ANY): JSX.Element => {
  return row?.attachments.length ? (
    <DropDownCell
      items={
        row
          ? row.attachments
              .filter(
                (attachment: { type_code: string }) =>
                  attachment.type_code !== 'TAB' && attachment.type_code !== 'TABT'
              )
              .map((attachment: { file_name: string; file_id: string }) => ({
                title: attachment.file_name,
                file_id: attachment.file_id,
              }))
          : []
      }
    />
  ) : (
    <div />
  );
};
