import { convertPathUsingParams } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { History } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import NotificationView from 'modules/notifications/components/NotificationView';
import React from 'react';
import AppTemplate from 'templates/AppTemplate';
import styles from './assets/styles/index.module.css';

const NotificationViewPage = (props: TODO_ANY) => {
  const renderContent = () => (
    <div className={styles.content}>
      <NotificationView
        id={props.match.params.id}
        notificationDealerSystem={props.match.params.dealerSystem}
      />
    </div>
  );

  const renderSidebarLeft = () => (
    <History
      type="back"
      to={convertPathUsingParams(routingConfig.notifications.path, {
        dealerSystem: props.match.params.dealerSystem,
      })}
    />
  );

  return <AppTemplate sidebarLeft={renderSidebarLeft()} content={renderContent()} />;
};

export default NotificationViewPage;
