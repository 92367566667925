import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Loader, MessageList } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';

import { convertPathUsingParams, DealerSystems, IDealerSystemConfig } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { RbAutoType, RepoAutoType } from '../../store';
import styles from './assets/styles/index.module.css';

export interface IFinancialPickerProps {
  codeType: RbAutoType | RepoAutoType;
  isLoading: boolean;
  apiErrorList: string[];
  dealerSystemConfig: IDealerSystemConfig;
}

export interface IFinancialPickerPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void;
    location: TODO_ANY;
  };
  dealerSystem?: DealerSystems;
}

export interface IFinancialPickerActions {
  actions: {
    reset: Function;
    getDealerBrandCodes: Function;
    prepareRepoFinancing: Function;
  };
}

const FinancialBlockPicker = (
  props: IFinancialPickerProps & IFinancialPickerPropsExternal & IFinancialPickerActions
) => {
  console.log(`FinancialBlockPicker BEGIN`);
  const dealerSystemConfig = props.dealerSystemConfig;
  console.log(
    `FinancialBlockPicker/useEffect_1: dealerSystemConfig = ${JSON.stringify(dealerSystemConfig)}`
  );
  const isRosbankOnly =
    props.dealerSystem === DealerSystems.ROSBANK_AVTO ||
    (dealerSystemConfig.rb_flag && !dealerSystemConfig.rf_flag);
  console.log(`FinancialBlockPicker/useEffect_1: isRosbankOnly = ${JSON.stringify(isRosbankOnly)}`);

  useEffect(() => {
    console.log(
      `FinancialBlockPicker/useEffect[] BEGIN: props.dealerSystem = ${JSON.stringify(
        props.dealerSystem
      )}`
    );
    if (isRosbankOnly) {
      console.log(`FinancialBlockPicker/useEffect[] BEFORE props.actions.getDealerBrandCodes()`);
      props.actions.getDealerBrandCodes();
      console.log(`FinancialBlockPicker/useEffect[] AFTER props.actions.getDealerBrandCodes()`);
    }
    if (props.dealerSystem === DealerSystems.RUSFINANCE) {
      props.actions.prepareRepoFinancing({ history: props.history });
    }
    console.log(`FinancialBlockPicker/useEffect[] END`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isOnlyOneSystem = false;
    if (dealerSystemConfig.rb_flag && !dealerSystemConfig.rf_flag) {
      isOnlyOneSystem = true;
    } else if (!dealerSystemConfig.rb_flag && dealerSystemConfig.rf_flag) {
      isOnlyOneSystem = true;
    }
    const isRusfinanceOnly = !dealerSystemConfig.rb_flag && dealerSystemConfig.rf_flag;
    console.log(`FinancialBlockPicker/useEffect_1: isRusfinanceOnly = ${isRusfinanceOnly}`);

    let targetUrl = null;
    if (isRusfinanceOnly) {
      switch (props.codeType) {
        // case CodeTypeUsed.NONE:
        //   targetUrl = routingConfig.financialBlockFTTableBySystem.path;
        //   break;
        case RepoAutoType.NEW:
          targetUrl = `${convertPathUsingParams(routingConfig.financialBlockFTTableBySystem.path, {
            system: DealerSystems.RUSFINANCE,
          })}`;
          break;
        case RepoAutoType.DEMO:
          targetUrl = `${convertPathUsingParams(routingConfig.financialBlockFTTableBySystem.path, {
            system: DealerSystems.RUSFINANCE,
          })}`;
          break;
        default:
          console.log(
            `FinancialBlockPicker/useEffect_1: ничего не делаем: codeType = ${props.codeType}`
          );
      }
    } else {
      console.log(`FinancialBlockPicker/useEffect_1: isRosbank = ${JSON.stringify(isRosbankOnly)}`);
      if (isRosbankOnly) {
        switch (props.codeType) {
          case RbAutoType.NEW_ONLY:
            targetUrl = `${convertPathUsingParams(
              routingConfig.financialBlockFTTableBySystem.path,
              {
                system: DealerSystems.ROSBANK_AVTO,
              }
            )}?code_type=new`;
            break;
          case RbAutoType.USED_ONLY:
            targetUrl = `${convertPathUsingParams(
              routingConfig.financialBlockFTTableBySystem.path,
              {
                system: DealerSystems.ROSBANK_AVTO,
              }
            )}?code_type=used`;
            break;
          default:
            console.log(
              `FinancialBlockPicker/useEffect_1: ничего не делаем: codeType = ${props.codeType}`
            );
        }
      }
    }
    (props.dealerSystem || isOnlyOneSystem) && targetUrl && props.history.push(targetUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.codeType, props.history.location.search]);

  const renderNavButton = (item: { name: string; url: string }) => {
    return (
      <div key={item.name} className={styles.badge} role="navigation">
        <Button
          wrapperClassName={styles.button}
          view="square"
          text={item.name}
          onClick={() => {
            props.history.push(item.url);
          }}
        />
      </div>
    );
  };

  if (props.isLoading) {
    return <Loader wrapperClassName={styles.main} />;
  }

  return (
    <div className={styles.main}>
      <Condition value={!!props.dealerSystemConfig.rb_flag && !!props.dealerSystemConfig.rf_flag}>
        <Condition value={!props.dealerSystem}>
          {fp.map(renderNavButton, [
            {
              name: 'РОСБАНК АВТО',
              url: `${convertPathUsingParams(
                routingConfig.financialBlockFinancialBlockCarTypePicker.path,
                {
                  system: DealerSystems.ROSBANK_AVTO,
                }
              )}`,
            },
            {
              name: 'РУСФИНАНС',
              url: `${convertPathUsingParams(
                routingConfig.financialBlockFinancialBlockCarTypePicker.path,
                {
                  system: DealerSystems.RUSFINANCE,
                }
              )}`,
            },
          ])}
        </Condition>
        <Condition value={props.dealerSystem === DealerSystems.ROSBANK_AVTO}>
          {fp.map(renderNavButton, [
            {
              name: 'Новые автомобили',
              url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
                system: DealerSystems.ROSBANK_AVTO,
                type: 'new',
              })}`,
            },
            {
              name: 'Автомобили с пробегом',
              url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
                system: DealerSystems.ROSBANK_AVTO,
                type: 'used',
              })}`,
            },
          ])}
        </Condition>
        <Condition value={props.dealerSystem === DealerSystems.RUSFINANCE}>
          {fp.map(renderNavButton, [
            {
              name: 'Новые автомобили',
              url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
                system: DealerSystems.RUSFINANCE,
                type: 'new',
              })}`,
            },
            {
              name: 'Демо',
              url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
                system: DealerSystems.RUSFINANCE,
                type: 'demo',
              })}`,
            },
          ])}
        </Condition>
      </Condition>
      <Condition value={!!props.dealerSystemConfig.rb_flag && !props.dealerSystemConfig.rf_flag}>
        {fp.map(renderNavButton, [
          {
            name: 'Новые автомобили',
            url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
              system: DealerSystems.ROSBANK_AVTO,
              type: 'new',
            })}`,
          },
          {
            name: 'Автомобили с пробегом',
            url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
              system: DealerSystems.ROSBANK_AVTO,
              type: 'used',
            })}`,
          },
        ])}
      </Condition>
      <Condition value={!props.dealerSystemConfig.rb_flag && !!props.dealerSystemConfig.rf_flag}>
        {fp.map(renderNavButton, [
          {
            name: 'Новые автомобили',
            url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
              system: DealerSystems.RUSFINANCE,
              type: 'new',
            })}`,
          },
          {
            name: 'Демо',
            url: `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
              system: DealerSystems.RUSFINANCE,
              type: 'demo',
            })}`,
          },
        ])}
      </Condition>
      <Condition value={props.codeType !== RbAutoType.NONE && !fp.isEmpty(props.apiErrorList)}>
        <MessageList type="error" messages={props.apiErrorList} />
      </Condition>
    </div>
  );
};

export default FinancialBlockPicker;
