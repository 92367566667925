import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IAuthState } from '../../store';

import Component, {
  IAuthPasswordExpiredProps,
  IAuthPasswordExpiredPropsExternal,
  IAuthPasswordExpiredActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: { history: TODO_ANY }
): IAuthPasswordExpiredProps & IAuthPasswordExpiredPropsExternal => ({
  passwordNew: selectors.selectPassword(state),
  passwordNewErrorList: selectors.selectErrorListByField(state)('password'),
  passwordNewRepeat: selectors.selectPasswordRepeat(state),
  passwordNewRepeatErrorList: selectors.selectErrorListByField(state)('passwordRepeat'),
  passwordOld: selectors.selectPasswordOld(state),
  passwordOldErrorList: selectors.selectErrorListByField(state)('passwordOld'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IAuthPasswordExpiredActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
