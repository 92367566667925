import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IUserState } from '../../store';

import Component, {
  IUserPhoneNumberChangeProps,
  IUserPhoneNumberChangePropsExternal,
  IUserPhoneNumberChangeActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IUserState },
  props: { history: TODO_ANY }
): IUserPhoneNumberChangeProps & IUserPhoneNumberChangePropsExternal => ({
  phoneNumber: selectors.selectFormValueByField(state)('phoneNumber'),
  phoneNumberErrorList: selectors.selectErrorListByField(state)('phoneNumber'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IUserPhoneNumberChangeActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
