import React from 'react';

import { Button, Link, AuthTemplate } from '@rfb/ui-kit';

import routingConfig from 'configs/routing';

import styles from './assets/styles/index.module.css';

const Error500 = () => {
  const onRepeatClick = (): void => {
    window.history.back();
  };

  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.title}>
        <h2>Возможно произошла ошибка,</h2>
        <h2>повторите попытку</h2>
      </div>

      <div className={styles.buttons}>
        <Button wrapperClassName={styles.buttonRepeat} text="Повторить" onClick={onRepeatClick} />
        <Link title="Вернуться на главную страницу" to={routingConfig.login.path} />
      </div>
    </div>
  );

  return <AuthTemplate content={renderContent()} />;
};

export default Error500;
