export const userDealers = [
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000091617',
    name: 'ООО "РЯЗАНЬАВТО"',
    inn: '6228001789',
    kpp: '623401001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000252989',
    name: 'АО "ТУЛААВТОСЕРВИС"',
    inn: '7107003230',
    kpp: '710701001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000252992',
    name: 'ООО "Гедон-Трейд"',
    inn: '6164230283',
    kpp: '616501001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000253051',
    name: 'ООО "А.С.-Авто"',
    inn: '3443064792',
    kpp: '344301001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000258380',
    name: 'ЗАО "Авингруп"',
    inn: '6165121897',
    kpp: '616501001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000258424',
    name: 'ООО "ОК-Транс"',
    inn: '6626011680',
    kpp: '667901001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000260029',
    name: 'ООО "Василеостровский Автоцентр"',
    inn: '7801707718',
    kpp: '780101001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000260049',
    name: 'ООО "Тан-Авто"',
    inn: '0277054599',
    kpp: '027301001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000261787',
    name: 'ООО АК "Юг-Авто"',
    inn: '2310079830',
    kpp: '231001001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000263598',
    name: 'АО "РОЛЬФ"',
    inn: '5047254063',
    kpp: '504701001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000270203',
    name: 'ООО "Альянс-Моторс"',
    inn: '2127026490',
    kpp: '213001001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000279694',
    name: 'ООО "ЭХО-Н"',
    inn: '6319055319',
    kpp: '631101001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000285124',
    name: 'ООО"Форпост"',
    inn: '5751029972',
    kpp: '575101001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000316660',
    name: 'ООО "ТАСКо-Моторс"',
    inn: '0268036924',
    kpp: '026801001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000318278',
    name: 'ООО "Автоцентр Сити"',
    inn: '7703260291',
    kpp: '770301001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000328924',
    name: 'ООО фирма "Интерпартнер"',
    inn: '1835035118',
    kpp: '184101001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000381255',
    name: 'АО "ПЛТ"',
    inn: '7816047126',
    kpp: '781601001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000733096',
    name: 'ООО "Автоцентр Глобус"',
    inn: '6829028601',
    kpp: '682901001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000781202',
    name: 'ООО"СИАЛАВТО"',
    inn: '2465079335',
    kpp: '246501001',
  },
  {
    user_name: 'Зоркина Зинаида Валентиновна',
    authority_begin_date: '2021-05-31',
    authority_end_date: '2026-05-17',
    phone_number: '79096737676',
    key_word: 'Авилон',
    diasoft_id: '10000790026',
    name: 'ООО "БН-Моторс"',
    inn: '3232029478',
    kpp: '325701001',
  },
];
