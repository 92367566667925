import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import Component, {
  FTButtonCellProps,
  FTButtonCellActions,
  FTButtonCellExternal,
} from './component';
import { name, actions, IFinancialState, selectors } from 'modules/financial-block/store';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: { history: TODO_ANY; requestDataId: number; brand_type: string; state?: string }
): FTButtonCellProps & FTButtonCellExternal => ({
  record: selectors.selectRequestDataById(state)(props.requestDataId),
  filter: selectors.selectFilter(state),
  requestDataId: props.requestDataId,
  history: props.history,
  brand_type: props.brand_type,
});
const mapDispatchToProps = (dispatch: any): FTButtonCellActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
