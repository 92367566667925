import { connect } from 'react-redux';

import { IDTORFInfoAccountResponse } from '../../../../../../dto/rf-info';

import { name, selectors } from '../../../../store';

import Component, { IAccountsSummaryProps } from './component';

const mapStateToProps = (state: { [name]: IDTORFInfoAccountResponse }): IAccountsSummaryProps => {
  const { rest_in, rest_out, turn_cred, turn_debt } = selectors.selectExtendedStatementData(state);
  return {
    rest_in,
    rest_out,
    turn_cred,
    turn_debt,
  };
};

export default connect(mapStateToProps)(Component);
