import React, { useEffect } from 'react';

import fp from 'lodash/fp';
import { TableOrderable as UIKitTableOrderable, Table as UIKitTable, Condition } from '@rfb/ui-kit';
import styles from './assets/styles/index.module.css';

export interface ITableOrderableProps {
  headers: { value: string; title: string }[];
  data: string[][];
  orderList: number[];
  isTrancheRepaymentCompleted: boolean;
}

export interface ITableOrderableActions {
  actions: {
    set: Function;
  };
  getPaymentListFromDB: Function;
}

const TableOrderable = (props: ITableOrderableProps & ITableOrderableActions) => {
  useEffect(() => {
    props.actions.set({ paymentList: props.getPaymentListFromDB() });
  }, []);

  const onOrderChange = (paymentOrderList: number[]): void => {
    return props.actions.set({ paymentOrderList });
  };

  if (fp.isEmpty(props.data)) return null;

  const table = () => (
    <UIKitTableOrderable
      headers={props.headers}
      rows={props.data}
      orderList={props.orderList}
      onOrderChange={onOrderChange}
    />
  );

  return (
    <div>
      <Condition value={!props.isTrancheRepaymentCompleted}>
        <div className={styles.main}>{table()}</div>
      </Condition>
      <Condition value={props.isTrancheRepaymentCompleted}>
        <div className={styles.disabled}>{table()}</div>
      </Condition>
    </div>
  );
};

export default TableOrderable;
