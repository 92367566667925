import { Condition } from '@rfb/ui-kit';
import { OperationStatusId } from 'dto/operation-data';
import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../store';
import styles from './assets/styles/index.module.css';
import { LinkButton } from './link-button';

interface ErrorCellProps {
  status: OperationStatusId;
  recordId: number;
  loadOperationError?: string;
}

export const ErrorCell = ({ status, recordId, loadOperationError }: ErrorCellProps) => {
  const dispatch = useDispatch();
  return (
    <div>
      <Condition value={status === OperationStatusId.PERFORMED_PARTIALLY}>
        <LinkButton
          className={styles.linkMargin}
          onClick={() =>
            dispatch(actions.uploadProtocolDownload({ protocol: 'request', id: recordId }))
          }
        >
          Открыть
        </LinkButton>
      </Condition>
      <Condition value={status === OperationStatusId.NOT_AGREED}>
        <div>{loadOperationError}</div>
      </Condition>
    </div>
  );
};
