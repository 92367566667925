import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Form, Input, MessageList } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';

import { loginRules } from '../../configs/validation';

import { IDTOPasswordResetRequest } from 'dto/security';

import styles from './assets/styles/index.module.css';

export interface IAuthPasswordRestoreProps {
  login: string;
  loginErrorList: string[];
  apiErrorList: string[];
  isSending: boolean;
}

export interface IAuthPasswordRestorePropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void,
  };
}

export interface IAuthPasswordRestoreActions {
  actions: {
    set: Function,
    setError: Function,
    reset: Function,
    restorePassword: Function,
  };
}

const PasswordRestore = (
  props: IAuthPasswordRestoreProps & IAuthPasswordRestorePropsExternal & IAuthPasswordRestoreActions
) => {
  useEffect(() => {
    return () => {
      props.actions.reset();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    login: configureValidator({
      name: 'login',
      rule: loginRules,
      setError: props.actions.setError,
    }),
  };

  const onLoginChange = (login: string): void => {
    validators.login(login).finally((): void => props.actions.set({ login }));
  };
  const onFormSubmit = (): void => {
    const data: IDTOPasswordResetRequest = { login: props.login };
    const rules = [validators.login(data.login)];
    runRulesChain(rules).then(() =>
      props.actions.restorePassword({ data, history: props.history })
    );
  };

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Восстановление пароля</h1>

      <Form onSubmit={onFormSubmit}>
        <Input
          wrapperClassName={styles.login}
          value={props.login}
          label="Логин"
          onChange={onLoginChange}
        />
        <MessageList type="error" messages={props.loginErrorList} />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Button
          wrapperClassName={styles.button}
          type="submit"
          text="Восстановить"
          isDisabled={props.isSending}
        />
      </Form>
    </div>
  );
};

export default PasswordRestore;
