import React from 'react';

import { History } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';

import { DealerSystems } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { getDealerSystemConfig } from 'helpers/dealer';
import FinancialBlockPicker from 'modules/financial-block/components/FinancialBlockPicker';
import styles from './assets/styles/index.module.css';

const FinancialBlockPickerPage = (props: TODO_ANY) => {
  const dealerSystemConfig = getDealerSystemConfig();
  let system: DealerSystems = props.match.params.system;
  if (dealerSystemConfig.rb_flag && !dealerSystemConfig.rf_flag) {
    system = DealerSystems.ROSBANK_AVTO;
  } else if (!dealerSystemConfig.rb_flag && dealerSystemConfig.rf_flag) {
    system = DealerSystems.RUSFINANCE;
  } else if (dealerSystemConfig.rb_flag && dealerSystemConfig.rf_flag) {
    system = props.match.params.system;
  }

  const backUrl = props.match.params.system
    ? routingConfig.financialBlockFinancialBlockPicker.path
    : routingConfig.main.path;

  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.top}>
        <h1 className={styles.title}>
          Запрос на финансирование
          {system && (
            <span style={{ fontSize: '40px' }}>
              {' '}
              В {`${system === DealerSystems.RUSFINANCE ? 'РУСФИНАНС' : 'РОСБАНК АВТО'}`}
            </span>
          )}
        </h1>
      </div>
      <History
        wrapperClassName={styles.wrapperHistory}
        type="back"
        to={backUrl}
        title={
          props.match.params.system ? 'Запрос на финансирование' : 'Вернуться на главную страницу'
        }
      />
      <div className={styles.content}>
        <FinancialBlockPicker history={props.history} dealerSystem={props.match.params.system} />
      </div>
    </div>
  );
  return <AppFullWidthTemplate content={renderContent()} />;
};

export default FinancialBlockPickerPage;
