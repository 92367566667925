var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
// @ts-ignore
import { MaskedRange } from 'imask';
import ReactCodeInput from 'react-code-input';
import { IMaskInput } from 'react-imask';
import { NumericFormat } from 'react-number-format';
import { Condition } from '../../services';
import styles from './assets/styles/index.module.css';
export var Input = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = useState(''), key = _f[0], setKey = _f[1];
    useEffect(function () {
        setKey(fp.uniqueId('ui-input'));
    }, []);
    var labelClassNames = cn((_a = {},
        _a[styles.label] = props.type === 'code' || !fp.isEmpty(props.value),
        _a[styles.labelHidden] = props.type !== 'code' && fp.isEmpty(props.value),
        _a[styles.labelStatic] = props.isStatic,
        _a));
    var inputClassNames = cn((_b = {},
        _b[styles.input] = true,
        _b[styles.static] = props.isStatic,
        _b[styles.error] = props.hasError,
        _b));
    var inputPhonePlaceholderClassNames = cn((_c = {},
        _c[inputClassNames] = true,
        _c[styles.inputPhonePlaceholder] = true,
        _c));
    // key содержит дополнительное значение для динамического обновления компонента извне (например, очистка поля)
    var renderInput = function () { return (React.createElement("input", { key: key + props.keyUnique, className: inputClassNames, type: props.type, name: props.name, value: props.value, placeholder: props.label, disabled: props.isStatic || props.disabled, onChange: function (_a) {
            var target = _a.target;
            return props.onChange(target.value);
        }, role: "input", style: { color: "" + props.valueTextColor } })); };
    var renderVinNumberInput = function () { return (React.createElement(IMaskInput
    //@ts-ignore
    , { 
        //@ts-ignore
        className: inputClassNames, value: props.value, placeholder: props.label, mask: /^[A-Za-zа-яёА-ЯЁ0-9]*$/, maxLength: 17, placeholderChar: props.placeholderChar, disabled: props.disabled, onAccept: function (value) {
            var finalValue = value === null || value === void 0 ? void 0 : value.toUpperCase();
            if (fp.isEqual(props.value, finalValue))
                return;
            props.onChange(finalValue);
        }, minLength: props.minLength, role: "input" })); };
    var renderInnNumberInput = function () { return (React.createElement(IMaskInput
    //@ts-ignore
    , { 
        //@ts-ignore
        className: inputClassNames, value: props.value, placeholder: props.label, mask: /^[0-9]*$/, maxLength: 12, placeholderChar: props.placeholderChar, disabled: props.disabled, onAccept: function (value) {
            var finalValue = value;
            if (fp.isEqual(props.value, finalValue))
                return;
            props.onChange(finalValue);
        }, minLength: props.minLength })); };
    var renderAccountNumberInput = function () { return (React.createElement(IMaskInput
    //@ts-ignore
    , { 
        //@ts-ignore
        className: inputClassNames, value: props.value, placeholder: props.label, 
        // mask={/^\d{20}$/}
        mask: /^[0-9]*$/, maxLength: 20, placeholderChar: props.placeholderChar, disabled: props.disabled, onAccept: function (value) {
            var finalValue = value;
            if (fp.isEqual(props.value, finalValue))
                return;
            props.onChange(finalValue);
        }, minLength: props.minLength, role: "input" })); };
    var renderPhoneInput = function () { return (React.createElement(React.Fragment, null,
        React.createElement(IMaskInput
        //@ts-ignore
        , { 
            //@ts-ignore
            className: inputClassNames, value: props.value, placeholder: props.label, mask: props.maskPhone, placeholderChar: props.placeholderChar, disabled: props.disabled, unmask: true, onAccept: function (value) {
                if (fp.isEqual(props.value, value))
                    return;
                props.onChange(value);
            } }),
        React.createElement(Condition, { value: !fp.isEmpty(props.value) },
            React.createElement(IMaskInput
            //@ts-ignore
            , { 
                //@ts-ignore
                className: inputPhonePlaceholderClassNames, value: props.value, mask: props.maskPhone, placeholderChar: '_', disabled: props.disabled, unmask: true, lazy: false })))); };
    var renderTimeInput = function () {
        var mask = 'hh:mm';
        var maskconfig = {
            hh: {
                mask: MaskedRange,
                from: 0,
                to: 23,
            },
            mm: {
                mask: MaskedRange,
                from: 0,
                to: 59,
            },
        };
        return (React.createElement(IMaskInput
        //@ts-ignore
        , { 
            //@ts-ignore
            className: inputClassNames, value: props.value, placeholder: props.label, mask: mask, blocks: maskconfig, placeholderChar: props.placeholderChar, disabled: props.disabled, onAccept: function (value) {
                if (fp.isEqual(props.value, value))
                    return;
                props.onChange(value);
            }, minLength: props.minLength }));
    };
    var renderTimeSecInput = function () {
        var mask = 'hh:mm:ss';
        var maskconfig = {
            hh: {
                mask: MaskedRange,
                from: 0,
                to: 23,
            },
            mm: {
                mask: MaskedRange,
                from: 0,
                to: 59,
            },
            ss: {
                mask: MaskedRange,
                from: 0,
                to: 59,
            },
        };
        return (React.createElement(IMaskInput
        //@ts-ignore
        , { 
            //@ts-ignore
            className: inputClassNames, value: props.value, placeholder: props.label, mask: mask, blocks: maskconfig, placeholderChar: props.placeholderChar, disabled: props.disabled, onAccept: function (value) {
                if (fp.isEqual(props.value, value))
                    return;
                props.onChange(value);
            }, minLength: props.minLength }));
    };
    var textAreaClassNames = cn((_d = {},
        _d[inputClassNames] = true,
        _d[styles.textarea] = true,
        _d));
    var renderTextArea = function () { return (React.createElement("textarea", { className: textAreaClassNames, placeholder: props.label, value: props.value, onChange: function (_a) {
            var target = _a.target;
            return props.onChange(target.value);
        }, maxLength: props.maxLength, disabled: props.disabled, rows: props.rows })); };
    var codeClassNames = cn((_e = {},
        _e[styles.codeinput] = true,
        _e));
    var codeInputStyle = {
        border: 'none',
        borderBottom: '2px solid #282423',
        width: '26px',
        height: '42px',
        margin: '0 10px',
        fontFamily: 'inherit',
        fontSize: '24px',
        paddingLeft: '12px',
        outline: 'none',
    };
    var renderCodeInput = function () { return (React.createElement(ReactCodeInput, { className: codeClassNames, name: props.name ? props.name : '', fields: props.codeFieldsCount ? props.codeFieldsCount : 4, inputMode: props.codeInputMode ? props.codeInputMode : 'numeric', inputStyle: codeInputStyle, isValid: !props.hasError, inputStyleInvalid: __assign(__assign({}, codeInputStyle), { borderColor: '#E9041E' }), onChange: function (value) { return props.onChange(value); }, value: props.value, disabled: props.disabled })); };
    var handlePaste = function (event) {
        var _a, _b;
        event.preventDefault();
        var paste = event.clipboardData.getData('text');
        paste = paste.replace(/,/g, '.').replace(/\s/g, ''); // Замена запятой на точку и удаление пробелов
        var moneySum = parseFloat(paste);
        if (!isNaN(moneySum)) {
            var rounded = moneySum.toFixed(2);
            if (document.queryCommandSupported('insertText')) {
                document.execCommand('insertText', false, rounded);
            }
            else {
                var input = event.target;
                var startPos = (_a = input.selectionStart) !== null && _a !== void 0 ? _a : 0; // Используем 0, если значение null
                var endPos = (_b = input.selectionEnd) !== null && _b !== void 0 ? _b : 0; // Используем 0, если значение null
                input.value = input.value.substring(0, startPos) + rounded + input.value.substring(endPos);
                input.setSelectionRange(startPos + rounded.length, startPos + rounded.length);
            }
        }
        else {
            console.error('Вставленное значение не является числом.');
        }
    };
    var renderMoneyInput = function () { return (React.createElement(NumericFormat, { disabled: props.disabled, value: props.value, placeholder: props.label, className: inputClassNames, allowNegative: false, decimalScale: 2, thousandSeparator: " ", decimalSeparator: ".", onPaste: handlePaste, fixedDecimalScale: true, onValueChange: function (values) {
            if (fp.isEqual(props.value, values.value))
                return;
            props.onChange(values.value);
        }, isAllowed: function (values) {
            var formattedValue = values.formattedValue, floatValue = values.floatValue;
            return formattedValue === '' || (floatValue !== undefined && floatValue >= 0);
        }, role: "input" })); };
    var isPhoneType = fp.isEqual(props.type, 'phone');
    var isCodeType = fp.isEqual(props.type, 'code');
    var isTextAreaType = fp.isEqual(props.type, 'textarea');
    var isTimeType = fp.isEqual(props.type, 'time');
    var isTimeSecType = fp.isEqual(props.type, 'timesec');
    var isMoneyType = fp.isEqual(props.type, 'money');
    var isVinNumber = fp.isEqual(props.type, 'vin-number');
    var isInnNumber = fp.isEqual(props.type, 'inn-number');
    var isAccountNumber = fp.isEqual(props.type, 'account-number');
    var renderInputByCurrentType = function () {
        if (isPhoneType)
            return renderPhoneInput();
        if (isTextAreaType)
            return renderTextArea();
        if (isTimeType)
            return renderTimeInput();
        if (isTimeSecType)
            return renderTimeSecInput();
        if (isCodeType)
            return renderCodeInput();
        if (isMoneyType)
            return renderMoneyInput();
        if (isVinNumber)
            return renderVinNumberInput();
        if (isInnNumber)
            return renderInnNumberInput();
        if (isAccountNumber)
            return renderAccountNumberInput();
        return renderInput();
    };
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: styles.main },
            React.createElement("div", { className: labelClassNames }, props.label),
            renderInputByCurrentType())));
};
Input.defaultProps = {
    type: 'text',
    maskPhone: '+{7} (000) 000-00-00',
    placeholderChar: '0',
    isStatic: false,
    disabled: false,
    hasError: false,
};
export default Input;
