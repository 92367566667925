import { createSlice } from '@reduxjs/toolkit';

import { getStateFromParams } from 'helpers/store';

export interface IConfirmState {
  code: string;
  phoneNumber: string;
  factorUrl: string;
  isSending: boolean;
  isLoading: boolean;
  isBlocked: boolean;
  errorList: { [key: string]: string[] };
}

const initialState: IConfirmState = {
  isBlocked: false,
  code: '',
  phoneNumber: '',
  factorUrl: '',
  isLoading: false,
  isSending: false,
  errorList: {},
};

const confirmSlice = createSlice({
  name: 'confirm',

  initialState,

  reducers: {
    set: (state, action) => ({ ...state, ...action.payload }),
    setError: (state, action) => ({
      ...state,
      errorList: { ...state.errorList, ...action.payload },
    }),
    reset: () => ({ ...initialState }),

    confirm: getStateFromParams,
    confirmSuccessful: getStateFromParams,
    confirmFailure: getStateFromParams,

    codeRequest: getStateFromParams,
    codeRequestSuccessful: getStateFromParams,
    codeRequestFailure: getStateFromParams,
  },
});

export const { name, actions, reducer } = confirmSlice;
