import { OperationStatusId } from "dto/operation-data";

export enum OperationDataStatusValue {
  SENT = 'Отправлена',
  UPLOAD_ERROR_TO_FOLDER_IN_DEALER = 'Ошибка загрузки в папку IN\\Dealer',
  UPLOAD_ERROR_TO_DOCUMENTUM = 'Ошибка загрузки в Documentum',
  PERFORMED = 'Исполнена',
  PERFORMED_PARTIALLY = 'Исполнена частично',
  NOT_EXECUTED = 'Не исполнена',
  STATUS_REQUEST_ERROR = 'Статус неизвестен',
  STATUS_UNKNOWN = '',
  REVOKED = 'Отозвана',
  IN_ACTION = 'В работе',
  NEW_OR_SENT = 'Новое',
  SENT_TO_RS = 'Загружено',
  NOT_AGREED = 'Не согласовано',
  ERROR = 'Ошибка',
  AGREEMENT_OR_SIGNING_IS_NEEDED = 'Согласовано',
  SEND_ERROR = 'Сбой при отправке',
  REFUSAL_OR_NOT_AGREED = 'Отказано',
}

export const operationDataStatusValue: { [key: string]: string } = {
  [OperationStatusId.SENT]: 'Отправлена',
  [OperationStatusId.UPLOAD_ERROR_TO_FOLDER_IN_DEALER]: 'Ошибка загрузки в папку IN\\Dealer',
  [OperationStatusId.UPLOAD_ERROR_TO_DOCUMENTUM]: 'Ошибка загрузки в Documentum',
  [OperationStatusId.EXECUTED]: 'Исполнена',
  [OperationStatusId.PERFORMED_PARTIALLY]: 'Исполнена частично',
  [OperationStatusId.NOT_EXECUTED]: 'Не исполнена',
  //  [OperationStatusId.STATUS_REQUEST_ERROR]: 'Ошибка запроса статуса операции',
  [OperationStatusId.STATUS_REQUEST_ERROR]: 'Статус неизвестен',
  [OperationStatusId.STATUS_UNKNOWN]: 'Статус неизвестен',
  [OperationStatusId.REVOKED]: 'Отозвана',
  [OperationStatusId.IN_ACTION]: 'В работе',
  [OperationStatusId.NEW_OR_SENT]: 'Новое',
  [OperationStatusId.SENT_TO_RS]: 'Загружено',
  [OperationStatusId.NOT_AGREED]: 'Не согласовано',
  [OperationStatusId.ERROR]: 'Ошибка',
  [OperationStatusId.AGREEMENT_OR_SIGNING_IS_NEEDED]: 'Согласовано',
  [OperationStatusId.SEND_ERROR]: 'Сбой при отправке',
  [OperationStatusId.REFUSAL_OR_NOT_AGREED]: 'Отказано',
};

export const operationDataStatusId: { [key: string]: OperationStatusId } = {
  [OperationDataStatusValue.SENT]: OperationStatusId.SENT,
  [OperationDataStatusValue.UPLOAD_ERROR_TO_FOLDER_IN_DEALER]:
    OperationStatusId.UPLOAD_ERROR_TO_FOLDER_IN_DEALER,
  [OperationDataStatusValue.UPLOAD_ERROR_TO_DOCUMENTUM]:
    OperationStatusId.UPLOAD_ERROR_TO_DOCUMENTUM,
  [OperationDataStatusValue.PERFORMED]: OperationStatusId.EXECUTED,
  [OperationDataStatusValue.PERFORMED_PARTIALLY]: OperationStatusId.PERFORMED_PARTIALLY,
  [OperationDataStatusValue.NOT_EXECUTED]: OperationStatusId.NOT_EXECUTED,
  [OperationDataStatusValue.STATUS_REQUEST_ERROR]: OperationStatusId.STATUS_REQUEST_ERROR,
  [OperationDataStatusValue.STATUS_UNKNOWN]: OperationStatusId.STATUS_UNKNOWN,
  [OperationDataStatusValue.REVOKED]: OperationStatusId.REVOKED,
  [OperationDataStatusValue.IN_ACTION]: OperationStatusId.IN_ACTION,
  [OperationDataStatusValue.NEW_OR_SENT]: OperationStatusId.NEW_OR_SENT,
  [OperationDataStatusValue.SENT_TO_RS]: OperationStatusId.SENT_TO_RS,
  [OperationDataStatusValue.NOT_AGREED]: OperationStatusId.NOT_AGREED,
  [OperationDataStatusValue.ERROR]: OperationStatusId.ERROR,
  [OperationDataStatusValue.AGREEMENT_OR_SIGNING_IS_NEEDED]: OperationStatusId.AGREEMENT_OR_SIGNING_IS_NEEDED,
  [OperationDataStatusValue.SEND_ERROR]: OperationStatusId.SEND_ERROR,
  [OperationDataStatusValue.REFUSAL_OR_NOT_AGREED]: OperationStatusId.REFUSAL_OR_NOT_AGREED,
};
