var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cn from 'classnames';
import React, { useRef, useState } from 'react';
import AddIcon from '../../icons/Add';
import Close from '../../icons/Close';
import styles from './assets/styles/index.module.css';
var Button = function (props) {
    var _a, _b, _c;
    var _d = useState(false), highlight = _d[0], setHighlight = _d[1];
    var buttonClassNames = cn((_a = {},
        _a[styles.main] = true,
        _a[styles.active] = !props.disabled,
        _a[styles.viewCircle] = true,
        _a[styles.disabled] = props.disabled,
        _a[styles.highLight] = highlight,
        _a));
    var iconClassNames = cn((_b = {},
        _b[styles.buttonIcon] = true,
        _b[styles.buttonIconActive] = !props.disabled,
        _b));
    var titleClassNames = cn((_c = {},
        _c[styles.buttonTitle] = true,
        _c[styles.buttonTitleDisabled] = props.disabled,
        _c));
    var onDropEvent = function (e, highlight) {
        e.preventDefault();
        e.stopPropagation();
        setHighlight(highlight);
    };
    return (React.createElement("button", { className: buttonClassNames, onClick: props.onClick, disabled: props.disabled, onDragEnter: function (e) {
            onDropEvent(e, true);
        }, onDragOver: function (e) {
            onDropEvent(e, true);
        }, onDragLeave: function (e) {
            onDropEvent(e, false);
        }, onDrop: function (e) {
            onDropEvent(e, false);
            props.onDrop(e);
        } },
        React.createElement("div", { className: styles.button },
            React.createElement("span", { className: iconClassNames },
                React.createElement(AddIcon, null)),
            React.createElement("span", { className: titleClassNames }, highlight ? 'Отпустите файл' : props.text))));
};
var FileName = function (props) {
    return (React.createElement("div", { className: styles.textLabel },
        props.fileName,
        React.createElement(Close, { className: styles.icon, onClick: props.onClick })));
};
export var FileInput = function (_a) {
    var _b;
    var _c = _a.defaultFileName, defaultFileName = _c === void 0 ? '' : _c, props = __rest(_a, ["defaultFileName"]);
    var innerRef = useRef(null);
    var _d = useState(!!defaultFileName), showDefaultName = _d[0], setShowDefaultName = _d[1];
    var handleFile = function (files) {
        if (props.onChange) {
            props.onChange(files != null ? (files.length === 0 ? files[0] : files) : undefined);
        }
    };
    var validateFileFormat = function (files) {
        if (!props.accept) {
            return true;
        }
        else {
            if (files) {
                var mimeTypes_1 = props.accept.replace(/\s/g, '').split(',');
                var valid = Array.from(files).every(function (file) {
                    return (mimeTypes_1.filter(function (accept) {
                        return new RegExp(accept).test(file.type) || file.name.endsWith(accept);
                    }).length > 0);
                });
                return valid;
            }
            return true;
        }
    };
    var handleClick = function () {
        var _a;
        (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (React.createElement("div", { className: cn(styles.fileInputWrapper, props.wrapperClassName) },
        React.createElement(Button, { text: props.label, onClick: handleClick, onDrop: function (e) {
                var dt = e.dataTransfer;
                var files = dt.files;
                if (validateFileFormat(files)) {
                    handleFile(files);
                }
                else {
                    handleFile(null);
                }
            } }),
        !!props.value && (React.createElement(FileName, { fileName: (_b = props.value) === null || _b === void 0 ? void 0 : _b.name, onClick: function () {
                setShowDefaultName(false);
                props.onChange(undefined);
            } })),
        !props.value && showDefaultName && (React.createElement(FileName, { fileName: defaultFileName, onClick: function () {
                setShowDefaultName(false);
                props.onChange(undefined);
            } })),
        React.createElement("input", { style: { display: 'none' }, type: "file", ref: innerRef, accept: props.accept, onChange: function (e) { return handleFile(e.target.files); }, multiple: true })));
};
export default FileInput;
