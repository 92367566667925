import React from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '../../icons/ArrowLeft';
import ArrowRightIcon from '../../icons/ArrowRight';
import styles from './assets/styles/index.module.css';
export var History = function (props) {
    var isBackType = fp.isEqual(props.type, 'back');
    var Icon = isBackType ? ArrowLeftIcon : ArrowRightIcon;
    return (React.createElement("div", { className: cn(props.wrapperClassName), "aria-label": isBackType ? 'history back' : 'history forward' },
        React.createElement(Link, { to: props.to },
            React.createElement(Icon, null),
            props.title && React.createElement("span", { className: styles.historyTitle }, props.title))));
};
export default History;
