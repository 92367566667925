import React, { useEffect } from 'react';

import fp from 'lodash/fp';

import { Button, Condition, Input, Form, MessageList } from '@rfb/ui-kit';

import { configureValidator, runRulesChain } from 'helpers/validation';

import { passwordRules, passwordRepeatRules } from '../../configs/validation';

import { IDTOPasswordChangeRequest } from 'dto/security';

import styles from './assets/styles/index.module.css';

export interface IAuthPasswordExpiredProps {
  passwordNew: string;
  passwordNewErrorList: string[];
  passwordNewRepeat: string;
  passwordNewRepeatErrorList: string[];
  passwordOld: string;
  passwordOldErrorList: string[];
  apiErrorList: string[];
}

export interface IAuthPasswordExpiredPropsExternal {
  // TODO Use a history interface
  history: {
    push: (path: string) => void,
  };
}

export interface IAuthPasswordExpiredActions {
  actions: {
    set: Function,
    setError: Function,
    reset: Function,
    changePassword: Function,
  };
}

const PasswordExpired = (
  props: IAuthPasswordExpiredProps & IAuthPasswordExpiredPropsExternal & IAuthPasswordExpiredActions
) => {
  useEffect(() => {
    return () => {
      props.actions.reset();
    };
    // ESLINT Необходимо выполнение только в момент размонтирования компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validators = {
    passwordOld: configureValidator({
      name: 'passwordOld',
      rule: passwordRules,
      setError: props.actions.setError,
    }),
    passwordNew: configureValidator({
      name: 'password',
      rule: passwordRules,
      setError: props.actions.setError,
    }),
    passwordNewRepeat: configureValidator({
      name: 'passwordRepeat',
      rule: passwordRepeatRules(props.passwordNew),
      setError: props.actions.setError,
    }),
  };

  const onPasswordOldChange = (passwordOld: string): void => {
    validators.passwordOld(passwordOld).finally((): void => props.actions.set({ passwordOld }));
  };
  const onPasswordNewChange = (password: string): void => {
    validators.passwordNew(password).finally((): void => props.actions.set({ password }));
  };
  const onPasswordNewRepeatChange = (passwordRepeat: string): void => {
    validators
      .passwordNewRepeat(passwordRepeat)
      .finally((): void => props.actions.set({ passwordRepeat }));
  };
  const onFormSubmit = (): void => {
    const data: IDTOPasswordChangeRequest = {
      old_password: props.passwordOld,
      new_password: props.passwordNew,
      new_password_retry: props.passwordNewRepeat,
    };
    const rules = [
      validators.passwordOld(data.old_password),
      validators.passwordNew(data.new_password),
      validators.passwordNewRepeat(data.new_password_retry),
    ];
    runRulesChain(rules).then(() => props.actions.changePassword({ data, history: props.history }));
  };

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <h2>Срок действия пароля истёк, </h2>
        <h2>пожалуйста, смените пароль</h2>
      </div>

      <Form onSubmit={onFormSubmit}>
        <Input
          type="password"
          label="Старый пароль"
          value={props.passwordOld}
          onChange={onPasswordOldChange}
        />
        <MessageList type="error" messages={props.passwordOldErrorList} />

        <h2 className={styles.explanation}>
          Пароль должен содержать не менее 8 символов трех групп: символы нижнего регистра,
          символы верхнего регистра, числа, специальные символы
        </h2>

        <Input
          type="password"
          label="Новый пароль"
          value={props.passwordNew}
          onChange={onPasswordNewChange}
        />
        <MessageList type="error" messages={props.passwordNewErrorList} />

        <Input
          type="password"
          label="Повторите пароль"
          value={props.passwordNewRepeat}
          onChange={onPasswordNewRepeatChange}
        />
        <MessageList type="error" messages={props.passwordNewRepeatErrorList} />

        <Condition value={!fp.isEmpty(props.apiErrorList)}>
          <MessageList type="error" messages={props.apiErrorList} />
        </Condition>

        <Button type="submit" text="Сохранить" />
      </Form>
    </div>
  );
};

export default PasswordExpired;
