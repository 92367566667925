var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import Swiper from 'react-id-swiper';
import cn from 'classnames';
import fp from 'lodash/fp';
import _ from 'lodash';
import { getRowManagement } from './helpers/row';
import Loader from '../../../Loader';
import Condition from '../../../../services/Condition';
import Header from './components/Header';
import Cell from './components/Cell';
import 'swiper/swiper-bundle.min.css';
import './assets/styles/override.css';
import styles from './assets/styles/index.module.css';
var rowManagement = getRowManagement({
    columnClassName: styles.column,
    cellClassName: styles.cellWrapper,
    cellHoverClassName: styles.cellWrapperHover,
});
export var Content = function (props) {
    var _a;
    var onCellHover = rowManagement.addHoverClass;
    var onCellBlur = rowManagement.removeHoverClass;
    var getColumnKey = function (column) {
        var header = column[0];
        return header.columnKey ? header.value + " " + header.columnKey : header.value;
    };
    var renderHeader = function (header) { return (React.createElement(Header, __assign({}, header, { sorting: props.sorting, onSortingChange: props.onSortingChange }))); };
    var renderCell = function (cell) {
        var _a;
        var _b, _c, _d, _e, _f, _g;
        var cellWrapperClassNames = cn((_a = {},
            _a[styles.cellWrapper] = true,
            _a[styles.cellWrapperDisabled] = (_b = cell.config) === null || _b === void 0 ? void 0 : _b.isDisabled,
            _a[styles.cellWrapperMarked] = (_c = cell.config) === null || _c === void 0 ? void 0 : _c.isMarked,
            _a[styles.cellWrapperBolded] = (_d = cell.config) === null || _d === void 0 ? void 0 : _d.isBolded,
            _a));
        return (React.createElement("div", { key: _.uniqueId('cel-'), className: cellWrapperClassNames, onMouseEnter: ((_e = cell.config) === null || _e === void 0 ? void 0 : _e.link) ? onCellHover : fp.noop, onMouseLeave: ((_f = cell.config) === null || _f === void 0 ? void 0 : _f.link) ? onCellBlur : fp.noop, role: "gridcell" },
            React.createElement(Cell, { value: cell.value, link: (_g = cell.config) === null || _g === void 0 ? void 0 : _g.link })));
    };
    var renderColumn = function (column) {
        var header = column[0], cellList = column.slice(1);
        return (React.createElement("div", { key: getColumnKey(column), className: cn(styles.column, header.columnClassName) },
            renderHeader(header),
            fp.map(renderCell, cellList)));
    };
    var getCellWithConfig = function (item) {
        return function (cell) { return ({
            config: item.config,
            value: cell,
        }); };
    };
    var cellWithConfigList = props.isLoading
        ? []
        : fp.map(function (item) {
            return fp.map(getCellWithConfig(item), item.data);
        }, props.rows);
    var _b = fp.pipe(_.zip, fp.partition(function (_a) {
        var header = _a[0];
        return header.isFixed;
    })).apply(void 0, __spreadArrays([props.headers], cellWithConfigList)), contentListFixed = _b[0], contentList = _b[1];
    var contentClassNames = cn((_a = {},
        _a[styles.content] = true,
        _a[styles.withLoader] = props.isLoading,
        _a));
    var swiperContainerClassNames = cn([
        styles.swiperContainerWrapper,
        'ui-kit-table-content-swiper',
    ]);
    /**
     * NOTICE:
     * При добавлении нового типа для заголовка date-range,
     * обнаружился баг, при котором возникает некорректное поведение свайпа -
     * он скроллится не к первому элементу, при этом обрезается часть контента
     * без возможности пролистать в начало
     * (скорее всего, это будет происходить с любым инпутом в заголовке)
     * С целью устранения, используется копирование компонента,
     * поэтому при изменении данных слайдер каждый раз сбрасывается
     * на начальное состояние
     *
     * Если потребуется внести изменения, при которых свайп в таблице должен сохранять
     * свою позицию, следует быть осторожным и помнить о вышеописанном
     * Внутреннюю информацию можно получить из ref'а компонента:
     * // @ts-ignore
     * swiperRef?.current?.swiper
     */
    var SwiperCopy = fp.cloneDeep(Swiper);
    return (React.createElement("div", { className: styles.main, role: "grid" },
        React.createElement("div", { className: contentClassNames },
            React.createElement("div", { className: styles.contentFixed }, fp.map(renderColumn, contentListFixed)),
            React.createElement("div", { className: styles.contentSwiped },
                React.createElement(SwiperCopy, { containerClass: swiperContainerClassNames, slidesPerView: "auto", resistanceRatio: 0, grabCursor: true, rebuildOnUpdate: true }, fp.map(renderColumn, contentList)))),
        React.createElement(Condition, { value: fp.isEqual(props.isLoading, true) },
            React.createElement(Loader, null))));
};
Content.defaultProps = {
    isLoading: false,
};
export default Content;
