import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import storage from 'utils/storage';

import { name, actions, selectors, IAuthState } from '../../store';

import Component, {
  IAuthLoginFirstProps,
  IAuthLoginFirstPropsExternal,
  IAuthLoginFirstActions,
} from './component';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: { history: TODO_ANY }
): IAuthLoginFirstProps & IAuthLoginFirstPropsExternal => ({
  email: storage.get('email'),
  dealerName: storage.get('dealerName'),
  inn: selectors.selectInn(state),
  innErrorList: selectors.selectErrorListByField(state)('inn'),
  kpp: selectors.selectKpp(state),
  kppErrorList: selectors.selectErrorListByField(state)('kpp'),
  apiErrorList: selectors.selectErrorListByField(state)('api'),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IAuthLoginFirstActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
