import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { RepoRequestDataDTO } from 'dto/financial-block';
import { IFinancialState, actions, name, selectors } from 'modules/financial-block/store';
import Component, {
  FTButtonCellRepoActions,
  FTButtonCellRepoExternal,
  FTButtonCellRepoProps,
} from './component';

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: { history: TODO_ANY; requestDataId: number }
): FTButtonCellRepoProps & FTButtonCellRepoExternal => {
  const requests: RepoRequestDataDTO['records'] = selectors.selectRepoRequestData(state);
  const result = {
    record: requests.find((value: any) => value.repo_request_data_id === props.requestDataId)!,
    filter: selectors.selectFilter(state),
    requestDataId: props.requestDataId,
    history: props.history,
    // brand_type: props.brand_type,
  };
  return result;
};

const mapDispatchToProps = (dispatch: any): FTButtonCellRepoActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
