import React, { useMemo } from 'react';

import { Condition, History } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import { useLocation, useParams } from 'react-router-dom';

import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';

import { convertPathUsingParams, DealerSystems } from '@rfb/common';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import AddCarForm from 'modules/financial-block/components/AddCarForm';
import AddCarFormRepo from 'modules/financial-block/components/AddCarFormRepo';
import AddUsedCarForm from 'modules/financial-block/components/AddUsedCarForm';
import { isUsedCars } from '../../../modules/financial-block/helpers';
import { RbAutoType, RepoAutoType } from '../../../modules/financial-block/store';
import styles from './assets/styles/index.module.css';

const Form = (props: TODO_ANY) => {
  const { search, pathname } = useLocation();
  const { requestDataId } = useParams<any>();

  const system = props.match.params.system;
  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const brand_type = urlParams.get('brand_type');
  const strCodeType = props.match.params.type ?? urlParams.get('code_type');
  const codeType: RbAutoType | RepoAutoType =
    strCodeType === 'used'
      ? RbAutoType.USED_ONLY
      : strCodeType === 'new'
      ? RbAutoType.NEW_ONLY
      : strCodeType === 'demo'
      ? RepoAutoType.DEMO
      : RbAutoType.NONE;

  function getChosenCarType() {
    if (props.history.location.pathname.includes('/new')) {
      return 'new';
    }
    if (props.history.location.pathname.includes('/used')) {
      return 'used';
    }
    if (props.history.location.pathname.includes('/demo')) {
      return 'demo';
    }
    return null;
  }

  const chosenCarType = getChosenCarType();
  const backUrl = chosenCarType
    ? `${convertPathUsingParams(routingConfig.financialBlockFTTable.path, {
        system,
        type: chosenCarType,
      })}${props.history.location.search}`
    : `${convertPathUsingParams(routingConfig.financialBlockFTTableBySystem.path, {
        system,
      })}${props.history.location.search}`;

  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.top}>
        <h1 className={styles.title}>
          Запрос на финансирование
          <span style={{ fontSize: '40px' }}>
            {' '}
            В {`${system === DealerSystems.RUSFINANCE ? 'РУСФИНАНС' : 'РОСБАНК АВТО'}`}
          </span>
        </h1>
        <h2 className={styles.subtitle}>
          {codeType === RbAutoType.NONE
            ? ''
            : `${
                codeType === RbAutoType.NEW_ONLY
                  ? 'Новые автомобили'
                  : system === DealerSystems.ROSBANK_AVTO
                  ? 'Автомобили с пробегом'
                  : 'Демо'
              }`}
        </h2>

        <History
          wrapperClassName={styles.wrapperHistory}
          type="back"
          to={backUrl}
          title={`Вернуться к списку ${
            system === DealerSystems.ROSBANK_AVTO
              ? codeType !== RbAutoType.NONE
                ? codeType === RbAutoType.NEW_ONLY
                  ? 'новых автомобилей'
                  : 'автомобилей с пробегом'
                : 'автомобилей'
              : codeType === RbAutoType.NEW_ONLY
              ? 'новых автомобилей'
              : 'Демо' // TODO Выпрямить
          }`}
        />
      </div>
      <div className={styles.content}>
        <Condition value={system === DealerSystems.ROSBANK_AVTO}>
          {!isUsedCars(props.history) ? (
            <AddCarForm
              brand_type={brand_type as string}
              form={pathname.includes('edit') ? 'edit' : 'create'}
              history={props.history}
              requestDataId={requestDataId}
              backUrl={backUrl}
            />
          ) : (
            <AddUsedCarForm
              brand_type={brand_type as string}
              form={pathname.includes('edit') ? 'edit' : 'create'}
              history={props.history}
              requestDataId={requestDataId}
              backUrl={backUrl}
            />
          )}
        </Condition>
        <Condition value={system === DealerSystems.RUSFINANCE}>
          <AddCarFormRepo
            brand_type={brand_type as string}
            form={pathname.includes('edit') ? 'edit' : 'create'}
            history={props.history}
            requestDataId={requestDataId}
            backUrl={backUrl}
          />
        </Condition>
      </div>
    </div>
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default Form;
