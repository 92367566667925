import React, { useEffect } from 'react';

import { ButtonPrint } from '@rfb/ui-kit';

import ClientLineStatusSelect from 'modules/client-line/components/StatusSelect';
import ClientLineTableShort from 'modules/client-line/components/TableShort';
import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';

import styles from './assets/styles/index.module.css';

const Main = () => {
  const renderContent = () => (
    <div className={styles.main}>
      <div className={styles.top}>
        <h1 className={styles.title}>Информация по договорам</h1>

        <div className={styles.filter}>
          <div className={styles.dropdown}>
            <ClientLineStatusSelect />
          </div>
          <ButtonPrint wrapperClassName={styles.print} />
        </div>
      </div>

      <div className={styles.content}>
        <ClientLineTableShort />
      </div>
    </div>
  );

  return <AppFullWidthTemplate content={renderContent()} />;
};

export default Main;
