import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { name, actions, selectors, IAuthState } from '../../store';

import Component, {
  IAuthServiceTermsProps,
  IAuthServiceTermsPropsExternal,
  IAuthServiceTermsActions,
} from './component';

const mapStateToProps = (
  state: { [name]: IAuthState },
  props: IAuthServiceTermsPropsExternal
): IAuthServiceTermsProps & IAuthServiceTermsPropsExternal => ({
  text: selectors.selectServiceTermsText(state),
  history: props.history,
});

const mapDispatchToProps = (dispatch: any): IAuthServiceTermsActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
