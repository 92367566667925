function isWeekend(date: Date): boolean {
  const day = date.getDay();
  return day === 0 || day === 6; // 0 - воскресенье, 6 - суббота
}

function getPreviousWorkday(date: Date): Date {
  const prevDay = new Date(date);
  let daysToSubtract = 1;

  // Если дата - понедельник, отнимаем 3 дня (переходим на пятницу)
  if (prevDay.getDay() === 1) {
    daysToSubtract = 3;
  } else if (prevDay.getDay() === 0) {
    // Если дата - воскресенье, отнимаем 2 дня (переходим на пятницу)
    daysToSubtract = 2;
  }

  prevDay.setDate(prevDay.getDate() - daysToSubtract);
  return prevDay;
}

function getNextWorkday(date: Date): Date {
  const nextDay = new Date(date);
  let daysToAdd = 1;

  if (nextDay.getDay() === 5) {
    daysToAdd = 3;
  } else if (nextDay.getDay() === 6) {
    daysToAdd = 2;
  }

  nextDay.setDate(nextDay.getDate() + daysToAdd);
  return nextDay;
}

function getLastWorkday(date: Date): Date {
  const lastWorkday = new Date(date);
  const day = lastWorkday.getDay();

  if (day === 0) {
    // Воскресенье
    lastWorkday.setDate(lastWorkday.getDate() - 2);
  } else if (day === 6) {
    // Суббота
    lastWorkday.setDate(lastWorkday.getDate() - 1);
  }

  return lastWorkday;
}

export function getYesterday(): Date {
  return getPreviousWorkday(new Date());
}

export function getToday(): Date {
  const today = new Date();
  return isWeekend(today) ? getLastWorkday(today) : today;
}

export function getTomorrow(): Date {
  return getNextWorkday(new Date());
}
