var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var ArrowPrev = function (props) { return (React.createElement("svg", __assign({}, props, { width: "11", height: "20", viewBox: "0 0 11 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M9.625 20L0 10L9.625 0L11 1.42857L2.75 10L11 18.5714L9.625 20Z", fill: "#C4C4C4" }))); };
export default ArrowPrev;
