import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { ClientLineStatus } from '../../configs/status';

import { name, actions, selectors, IClientLineState } from '../../store';

import Component, {
  IClientLineStatusSelectProps,
  IClientLineStatusSelectActions,
} from './component';

const mapStateToProps = (state: { [name]: IClientLineState }): IClientLineStatusSelectProps => ({
  optionList: [
    { label: 'Все', value: ClientLineStatus.ALL },
    { label: 'Открытые', value: ClientLineStatus.OPENED },
    // { label: 'Закрытые', value: ClientLineStatus.CLOSED },
  ],
  status: selectors.selectFilterByField(state)('status'),
});

const mapDispatchToProps = (dispatch: any): IClientLineStatusSelectActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
