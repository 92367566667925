var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var SortingNumberAsc = function (props) { return (React.createElement("svg", __assign({}, props, { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M0 4H9.5", stroke: "#807D7D", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M0 10H14", stroke: "#807D7D", strokeWidth: "1.5" }),
    React.createElement("path", { d: "M0 16H20", stroke: "#807D7D", strokeWidth: "1.5" }))); };
export default SortingNumberAsc;
