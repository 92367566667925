import React from 'react';
import { History } from '@rfb/ui-kit';
import routingConfig from 'configs/routing';
import AppTemplate from 'templates/AppTemplate';
import InformationFeedback from 'modules/information/components/Feedback';
import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import storage from '../../../utils/storage';
import { dealerSystemsKey } from '../../../modules/auth/store';

const Feedback = (props: TODO_ANY) => {
  const dealerSystemConfig = storage.get(dealerSystemsKey).data;
  const twoSystemShow: boolean =
    dealerSystemConfig.rb_flag === 1 && dealerSystemConfig.rf_flag === 1;
  const isButtonsPage = props.location.pathname.includes('two-system');

  const renderLeftSidebar = () => (
    <History
      type="back"
      to={
        twoSystemShow
          ? isButtonsPage
            ? routingConfig.information.path
            : routingConfig.twoSystemFeedback.path
          : routingConfig.information.path
      }
    />
  );

  const renderContent = () => <InformationFeedback history={props.history} />;

  return <AppTemplate content={renderContent()} sidebarLeft={renderLeftSidebar()} />;
};

export default Feedback;
