import { bindActionCreators } from '@reduxjs/toolkit';
import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { ITable } from '@rfb/ui-kit';
import fp from 'lodash/fp';
import { connect } from 'react-redux';
import storage from '../../../../utils/storage';
import { readOnlyRoleKey } from '../../../app/store/sagas';
import { getTransformedAttachments } from '../../helpers';
import { actions, IFinancialState, name, selectors } from '../../store';
import styles from './assets/styles/index.module.css';
import Component, { IFTTableActions, IFTTableExternal, IFTTableProps } from './component';
import { getFTButtonCell } from './ftbutton-cell';

type TFTTableHeader = ITable['headers'][number];

type TFTTableColumn = {
  header: TFTTableHeader;
  transform?: (value: any, row?: IFinancialState['requestData'][number]) => any;
};

const mapStateToProps = (
  state: { [name]: IFinancialState },
  props: {
    history: TODO_ANY;
    brand_type?: string;
    state?: string;
  }
): IFTTableProps & IFTTableExternal => {
  const filter = selectors.selectFilter(state);

  const requests: IFinancialState['requestData'] = selectors.selectRequestData(state);

  const columns: TFTTableColumn[] = [
    {
      header: {
        value: 'request_attachment',
        title: '',
        isFixed: true,
        type: 'attachment',
        columnClassName: styles.columnAttachment,
      },
      transform: getTransformedAttachments,
    },
    {
      header: {
        value: '',
        title: '',
        isFixed: true,
        columnClassName: styles.columnControls,
      },
      transform: getFTButtonCell,
    },
    {
      header: {
        value: 'brand',
        title: 'Марка',
        isFixed: true,
        columnClassName: styles.columnBrand,
      },
    },
    {
      header: {
        value: 'model',
        title: 'Модель',
        isFixed: true,
        columnClassName: styles.columnModel,
      },
    },
    {
      header: {
        value: 'request_vin',
        title: 'VIN',
        isFixed: true,
        columnClassName: styles.columnVin,
      },
    },
    {
      header: {
        value: 'car_passport_date',
        title: 'Дата выдачи ПТС/ЭПТС',
        isFixed: true,
        columnClassName: styles.columnRequestCarPassportDate,
      },
    },
    {
      header: {
        value: 'engine_volume',
        title: 'Рабочий объём двигателя (куб.см)',
        isFixed: true,
        columnClassName: styles.columnEngineDisplacement,
      },
    },
    {
      header: {
        value: 'engine_power',
        title: 'Мощность двигателя (л.с.)',
        isFixed: true,
        columnClassName: styles.columnEnginePower,
      },
    },
    {
      header: {
        value: 'electrical_engine_power',
        title: 'Мощность двигателя (квт*ч)',
        isFixed: true,
        columnClassName: styles.columnEnginePower,
      },
    },
    {
      header: {
        value: 'car_run',
        title: 'Пробег',
        isFixed: true,
        columnClassName: styles.columnMileage,
      },
    },
    {
      header: {
        value: 'request_amount',
        title: 'Стоимость, руб',
        columnClassName: styles.columnAmount,
      },
    },
  ];

  const rowDataList = fp.pipe(
    fp.map((row: IFinancialState['requestData'][number]) =>
      fp.map(
        (column: TFTTableColumn) =>
          fp.isFunction(column.transform)
            ? column.transform(fp.path(column.header.value, row), row)
            : fp.path(column.header.value, row),
        columns
      )
    ),
    fp.map((data) => ({ data }))
  )(requests);

  const rowConfigList = fp.pipe(fp.map((config) => ({ config })))(requests);

  return {
    headers: fp.map(fp.path('header'), columns),
    rows: fp.pipe(fp.zip(rowConfigList), fp.map(fp.mergeAll))(rowDataList),
    pageCount: selectors.selectPageCount(state),
    filter,
    isLoading: selectors.selectIsLoading(state),
    isFinancingRequestInProgress: selectors.selectIsFinancingRequestInProgress(state),
    totalRowCount: selectors.selectTotalCount(state),
    totalSum: selectors.selectTotalSum(state),
    apiErrorList: selectors.selectErrorListByField(state)('api'),
    isReadOnly: storage.get(readOnlyRoleKey),
    ...props,
  };
};

const mapDispatchToProps = (dispatch: TODO_ANY): IFTTableActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
