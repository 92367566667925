import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';
import Big from 'big.js';

import {
  calculateAccurateSum,
  calculateTrancheAmount,
  calculateTrancheRepaymentAmount,
} from '../helpers/amount';

import { name, getPaymentListFromDB, ITrancheState } from './index';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';
import { getTransformedAmount } from '@rfb/common';

export const selectState = fp.path(name);

const selectData = createSelector(selectState, fp.path('data'));

const selectPaymentList = getPaymentListFromDB;
const selectPaymentOrderList = createSelector(selectState, fp.path('paymentOrderList'));

const selectShowSelected = createSelector(selectState, fp.path('showSelected'));

const selectRepaymentAmountList = (): string[] =>
  fp.map(
    (item) => item.replace('₽', '').replace(',', '.').replace(/\s/g, ''),
    fp.flatten(
      fp.map(
        (tranche: ITrancheState['paymentList'][number]): [string] => [
          getTransformedAmount(parseFloat(calculateTrancheRepaymentAmount(tranche).toString())),
        ],
        selectPaymentList()
      )
    )
  );

export default {
  selectDataFilter: createSelector(selectState, fp.path('dataFilter')),
  selectFilter: createSelector(selectState, fp.path('filter')),
  selectFilterTemp: createSelector(selectState, fp.path('filterTemp')),

  selectPaymentList,
  selectPaymentOrderList,
  selectPaymentOrderedList: createSelector(
    selectPaymentList,
    selectPaymentOrderList,
    (list, order): TODO_ANY[] =>
      fp.isEmpty(order) ? list : fp.map((orderNumber: number) => fp.nth(orderNumber, list), order)
  ),

  selectRepaymentAmountOrderedList: createSelector(
    selectRepaymentAmountList,
    selectPaymentOrderList,
    (list, order): TODO_ANY[] =>
      fp.isEmpty(order) ? list : fp.map((orderNumber: number) => fp.nth(orderNumber, list), order)
  ),
  selectPaymentListCount: createSelector(selectPaymentList, fp.size),
  selectPaymentListAmount: createSelector(
    selectPaymentList,
    fp.pipe(fp.map(calculateTrancheAmount), fp.reduce(calculateAccurateSum, Big(0)))
  ),
  selectRepaymentListAmount: createSelector(
    selectPaymentList,
    fp.pipe(fp.map(calculateTrancheRepaymentAmount), fp.reduce(calculateAccurateSum, Big(0)))
  ),
  selectTrancheList: createSelector(
    selectData,
    selectPaymentList,
    selectShowSelected,
    (data, paymentList, showSelected) => (showSelected ? paymentList : data)
  ),
  selectShowSelected,
  selectErrorListByField: createSelector(
    selectState,
    (state) => (field: string) => fp.pathOr([], ['errorList', field], state)
  ),
  selectPageCount: createSelector(selectState, fp.path('pageCount')),
  selectIsSending: createSelector(selectState, fp.path('isSending')),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
  selectIsTrancheRepaymentCompleted: createSelector(
    selectState,
    fp.path('isTrancheRepaymentCompleted')
  ),
  selectRepaymentData: createSelector(selectState, fp.path('repaymentData')),
  selectRepaymentFileName: createSelector(selectState, fp.path('repaymentFileName')),
};
