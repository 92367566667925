import { createSelector } from '@reduxjs/toolkit';

import fp from 'lodash/fp';

import { name } from './index';

export const selectState = fp.path(name);

const selectAccounts = createSelector(selectState, fp.path('accounts'));
const selectAccountData = createSelector(selectState, fp.path('accountData'));
const selectCurrentId = createSelector(selectState, fp.path('filter.account'));
const selectCurrentAccountData = createSelector(selectState, fp.path('currentAccountData'));
const selectCurrentArrestsData = createSelector(selectState, fp.path('currentArrestsData'));
const selectExtendedStatementData = createSelector(selectState, fp.path('extendedStatementsData'));
const selectTransactions = createSelector(selectState, fp.path('accountData.transactions'));
const selectArrests = createSelector(selectState, fp.path('currentArrestsData.arrests'));
const selectFilter = createSelector(selectState, fp.path('filter'));
const selectDateStart = createSelector(selectState, fp.path('filter.start'));
const selectDateEnd = createSelector(selectState, fp.path('filter.end'));

export default {
  selectAccounts,
  selectAccountData,
  selectCurrentId,
  selectCurrentAccountData,
  selectCurrentArrestsData,
  selectExtendedStatementData,
  selectDateStart,
  selectDateEnd,
  selectExtendedStatementList: createSelector(selectExtendedStatementData, fp.path('statements')),
  selectExtendedStatementInfo: createSelector(selectExtendedStatementData, fp.omit(['statements'])),
  selectTransactions: createSelector(selectTransactions, (state) => (fieldList: string[]) =>
    fp.map((transaction: { [key: string]: string }) => fp.paths(fieldList, transaction), state)
  ),
  selectArrests: createSelector(selectArrests, (state) => (fieldList: string[]) =>
    fp.map((arrest: { [key: string]: string }) => fp.paths(fieldList, arrest), state)
  ),
  selectTransactionsPageCount: createSelector(selectState, fp.path('pageTransactionsCount')),
  selectArrestsPageCount: createSelector(selectState, fp.path('pageArrestsCount')),
  selectStatementsPageCount: createSelector(selectState, fp.path('pageStatementsCount')),
  selectFilter,
  selectFilterByField: createSelector(selectFilter, (state) => (field: string) =>
    fp.path(field, state)
  ),
  selectErrorListByField: createSelector(selectState, (state) => (field: string) =>
    fp.pathOr([], ['errorList', field], state)
  ),
  selectIsLoading: createSelector(selectState, fp.path('isLoading')),
};
