import { rest } from 'msw';
import {
  dealerSystem_403_1032_responseBody,
  dealerSystem_403_1033_responseBody,
  dealerSystem_BothResponse,
  dealerSystem_RbOnlyResponse,
  dealerSystem_200_1121_WithExceptionNullResponse,
  dealerSystem_RbOnlyWithExceptionNullResponse,
  dealerSystem_RfOnlyResponse,
  dealerSystem_ServerUnavailableResponse,
  dealerSystem_403_1030_responseBody,
  dealerSystem_BothResponseExceptionNull,
  dealerSystem_RfOnlyResponse_ForButtonsNumberError,
} from './profileDealerSystem';
import { isSmsConfirmed } from '../security_send-confirm-sms/securitySendConfirmSmsHandlers';
import { isDealerSelected } from '../profile_choose-dealer/profileChooseDealerHandlers';

export const profileDealerSystemHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/profile/dealer-system`, (req, res, ctx) => {
    console.log('msw-handlers: GET /profile/dealer-system');
    console.log('msw-handlers: GET /profile/dealer-system: isSmsConfirmed = ' + isSmsConfirmed);
    console.log('msw-handlers: GET /profile/dealer-system: isDealerSelected = ' + isDealerSelected);
    //    return isSmsConfirmed
    return true
      ? isDealerSelected
        ? //res(ctx.status(200), ctx.json(dealerSystem_ServerUnavailableResponse)) :
          //res(ctx.status(200), ctx.json(dealerSystem_RbOnlyResponse))
          //res(ctx.status(200), ctx.json(dealerSystem_RfOnlyResponse))
          res(ctx.status(200), ctx.json(dealerSystem_BothResponse))
        : //res(ctx.status(200), ctx.json(dealerSystem_RfOnlyResponse_ForButtonsNumberError))
          //        res(ctx.status(200), ctx.json(dealerSystem_BothResponse)) :
          //        res(ctx.status(200), ctx.json(dealerSystem_RbOnlyWithExceptionNullResponse)) :
          res(ctx.status(403), ctx.json(dealerSystem_403_1030_responseBody))
      : //res(ctx.status(403), ctx.json(dealerSystem_403_1033_responseBody)) :
        res(ctx.status(403), ctx.json(dealerSystem_403_1032_responseBody));

    //    return res(ctx.status(403), ctx.json(dealerSystem_403_1030_responseBody));
    //return res(ctx.status(403), ctx.json(dealerSystem_BothResponseExceptionNull));
    // return res(ctx.status(200), ctx.json(dealerSystem_RfOnlyResponse_ForButtonsNumberError));
    // return res(ctx.status(200), ctx.json(dealerSystem_RbOnlyWithExceptionNullResponse));
    // return res(ctx.status(200), ctx.json(dealerSystem_RfOnlyResponse));
    //return res(ctx.status(200), ctx.json(dealerSystem_RbOnlyResponse));
    // return res(ctx.status(200), ctx.json(dealerSystem_BothResponseExceptionNull));
  }),
];
