import React from 'react';

import AppFullWidthTemplate from 'templates/AppFullWidthTemplate';

import TrancheFilter from 'modules/tranche/components/Filter';
import TrancheTable from 'modules/tranche/components/Table';

import styles from './assets/styles/index.module.css';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const ListByClientLineId = (props: TODO_ANY) => {
  console.log(`pages/tranches/ListByClientId: props.match.params = `, props.match.params);
  const renderContent = () => (
    <div>
      <div className={styles.filter}>
        <TrancheFilter
          clientLineId={props.match.params.clientLineId}
          clientLineType={props.match.params.clientLineType}
          multiBrand={props.match.params.multiBrand}
        />
      </div>

      <div className={styles.table}>
        <TrancheTable
          clientLineId={props.match.params.clientLineId}
          clientLineType={props.match.params.clientLineType}
          multiBrand={props.match.params.multiBrand}
        />
      </div>
    </div>
  );
  return <AppFullWidthTemplate content={renderContent()} />;
};

export default ListByClientLineId;
