import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import fp from 'lodash/fp';
import styles from './assets/styles/index.module.css';
import { LensIcon, ArrowIcon } from './assets/icon';
export var SearchInput = function (props) {
    var _a, _b, _c;
    var _d = useState(''), key = _d[0], setKey = _d[1];
    var inputRef = useRef(null);
    useEffect(function () {
        setKey(fp.uniqueId('ui-search-input'));
    }, []);
    var labelClassNames = cn((_a = {},
        _a[styles.label] = !fp.isEmpty(props.value),
        _a[styles.labelHidden] = fp.isEmpty(props.value),
        _a[styles.labelStatic] = props.isStatic,
        _a));
    var inputClassNames = cn((_b = {},
        _b[styles.input] = true,
        _b[styles.static] = props.isStatic,
        _b));
    var mainClassNames = cn((_c = {},
        _c[styles.main] = true,
        _c[styles.error] = props.hasError,
        _c));
    // key содержит дополнительное значение для динамического обновления компонента извне (например, очистка поля)
    return (React.createElement("div", { className: cn(props.wrapperClassName) },
        React.createElement("div", { className: mainClassNames },
            React.createElement(LensIcon, { className: styles.lens }),
            React.createElement("div", { className: labelClassNames }, props.label),
            React.createElement("input", { ref: inputRef, key: key + props.keyUnique, className: inputClassNames, type: "text", name: props.name, defaultValue: props.value, placeholder: props.label, disabled: props.isStatic, maxLength: props.maxLength, onChange: function (_a) {
                    var target = _a.target;
                    return props.onChange(target.value);
                } }),
            React.createElement("button", { className: styles.arrow, onClick: function () { return props.onSearch(fp.get('current.value', inputRef)); } },
                React.createElement(ArrowIcon, null)))));
};
SearchInput.defaultProps = {
    type: 'text',
    isStatic: false,
    hasError: false,
};
export default SearchInput;
