import { connect } from 'react-redux';

import Component, { IAppMenuActions, IAppMenuProps } from './component';
import { actions, IAppState, selectors, name } from '../../store';
import { bindActionCreators } from '@reduxjs/toolkit';

const mapStateToProps = (state: { [name]: IAppState }): IAppMenuProps => {
  return {
    appMenuConfig: selectors.selectAppMenuConfig(state),
  };
};

const mapDispatchToProps = (dispatch: any): IAppMenuActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
