import { rest } from 'msw';
import {
  dealer_GET_Dubinin_ResponseBody,
  dealer_GET_ResponseBody,
  dealerLakyMotors_GET_ResponseBody
} from './dealer';

export const rfinfoInfoHandlers = [
  rest.get(`${process.env.REACT_APP_API_URL}/rf-info/info`, (req, res, ctx) => {
    console.log('msw-handlers: GET /rf-info/info');
//    return res(ctx.status(200), ctx.json(dealer_GET_ResponseBody));
//    return res(ctx.status(200), ctx.json(dealer_GET_Dubinin_ResponseBody));
    return res(ctx.status(200), ctx.json(dealerLakyMotors_GET_ResponseBody));
  }),
];
