import { ApiErrors, IApiConfig, Logger } from '@rfb/common';

import history from 'utils/history';
import storage from 'utils/storage';
import storageDB from 'utils/storage-db';

import apiEndpoints from 'configs/api/endpoints';

import routingConfig from 'configs/routing';

import { TODO_ANY } from '@rfb/common/types/TODO_ANY';

const config: IApiConfig = {
  apiUrl: process.env.REACT_APP_NEW_API_URL || 'http://vsmlapprfid1.rusfinance.ru/ibd-service/api',

  timeout: 1000 * 60 * 3,
  tokenExpired: {
    refreshUrl: apiEndpoints.auth.tokenRefresh,
    set: (token: string) => storage.set('token', token),
    redirect: () => {
      storage.clearAll();
      storageDB.clearAll();
      window.location.replace(routingConfig.login.path);
    },
  },
  errorHandlers: {
    // 400: {
    //   availableCodeList: [ApiErrors.RF_INFO_ERROR],
    //   handler: () =>  history.push(routingConfig.error500.path),
    // },
    403: {
      availableCodeList: [0, ApiErrors.USER_BLOCKED, ApiErrors.USER_BLOCKED_BY_ADMIN],
      handler: () => {
        storage.clearAll();
        storageDB.clearAll();
        window.location.replace(routingConfig.login.path);
      },
    },
    422: {
      availableCodeList: [0],
      handler: () => history.push(routingConfig.error422.path),
    },
    // 500: {
    //   availableCodeList: [0],
    //   handler: () =>  history.push(routingConfig.error500.path),
    // },
  },
  getToken: () => (storage as TODO_ANY).get('token'),
  logger: Logger.getInstance()
};

export default config;
