import React, { useEffect } from 'react';

import { TODO_ANY } from '@rfb/common/lib/types/TODO_ANY';
import { IMenuElement, IMenuSubMenu, MenuHorizontal } from '@rfb/ui-kit';
import { awaitingSigningStorageKey } from 'alerts/app-awaiting-signing';
import storage from 'utils/storage';

export interface IAppMenuProps {
  appMenuConfig: (IMenuElement | IMenuSubMenu)[];
}

export interface IAppMenuActions {
  actions: {
    getNotificationCount: Function;
    getCountAgreeRequest: Function;
  };
}

const Menu = (props: TODO_ANY) => {
  useEffect(() => {
    props.actions.getNotificationCount({
      onSuccess: () => {
        const awaitingSigningStorageKeyValue: any = storage.get(awaitingSigningStorageKey);
        if (!awaitingSigningStorageKeyValue) {
          // Во избежание повторных вызовов
          props.actions.getCountAgreeRequest();
        }
      },
    });
  }, []);

  return <MenuHorizontal config={props.appMenuConfig} />;
};

export default Menu;
