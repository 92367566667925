var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var ArrowClosed = function (props) { return (React.createElement("svg", __assign({}, props, { width: "20", height: "11", viewBox: "0 0 20 11", fill: "none", xmlns: "http://www.w3.org/2000/svg" }),
    React.createElement("path", { d: "M20 1.375L10 11L0 1.375L1.42857 0L10 8.25L18.5714 0L20 1.375Z", fill: "#282423" }))); };
export default ArrowClosed;
