import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { IClientLineState, actions, name, selectors } from '../../store';

import Component, {
  IClientLineDetailActions,
  IClientLineDetailProps,
  IClientLineDetailPropsExternal,
} from './component';

const mapStateToProps = (
  state: { [name]: IClientLineState },
  props: IClientLineDetailPropsExternal
): IClientLineDetailProps & IClientLineDetailPropsExternal => ({
  id: props.id,
  type: props.type,
  multiBrand: props.multiBrand,
  clientLine: selectors.selectCurrentClientLine(state),
  isLoading: selectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: any): IClientLineDetailActions => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Component);
