import { rest } from 'msw';
import { tokenResponse } from './tokenResponse';

export const authenticationCreateTokenHandlers = [
  rest.post(`${process.env.REACT_APP_API_URL}/authentication/create-token`, (req, res, ctx) => {
    console.log('msw-handlers: POST /authentication/create-token');
    return res(ctx.status(200), ctx.json(tokenResponse));
  }),
  rest.post(`${process.env.REACT_APP_API_URL}/authentication/destroy-token`, (req, res, ctx) => {
    console.log('msw-handlers: POST /authentication/destroy-token');
    return res(ctx.status(204));
  }),
];
